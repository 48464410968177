import { Grid } from "@mui/material"
import { PageTitle } from "../../layoutPages/components/PageTitle"
import { DefaultSetting } from "../../layoutPages/defaultSetting"
import { MyColorButton } from "../../layoutPages/components/MyColorButton"
import { ShortCut } from "../../system/ShortCut";

let thisPageData: Array<{ id: string, value: string, onClick: Function }> = [
    {
        id: "s", value: "理事會架構",
        onClick: () => { ShortCut.windowOpen("https://www.pkppf.hk/p/42489") }
    },
    {
        id: "photo", value: "活動花絮",
        onClick: () => { }
    },
    {
        id: "in", value: "入會申請",
        onClick: () => { ShortCut.windowOpen("https://www.pkppf.hk/p/42489") }

    },
    {
        id: "contact", value: "聯絡我們",
        onClick: () => { ShortCut.windowOpen("https://www.pkppf.hk/p/42490") }
    }
];

export function PageParentsMain() {
    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <br></br>
                <PageTitle title='培小舊生家長會' size='h3' sx={{ m: 5, color: DefaultSetting.mainColor }} />
            </Grid>
            <Grid item xs={11} sm={10}>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    {
                        thisPageData.map((item) => (
                            <Grid item xs={6} sm={3} >
                                <MyColorButton mainColor={DefaultSetting.mainColor} inverted={true}
                                    sx={{ fontSize: "1.2em", width: "100%", borderRadius: "10px", p: 1, m: 1 }}
                                    onClick={() => item.onClick()}
                                >
                                    {item.value}
                                </MyColorButton>
                            </Grid>
                        ))
                    }

                </Grid>
            </Grid>
        </Grid>
    )
}