import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import React from 'react';
import { txMenuData } from '../../../data/SubjectMenu/txMenuData';

export function PageTxDirection() {

    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={12} sm={10} >
                <PageTitle title='學校評估政策 | 成績表使用說明' size='h3' subtitle={{ title: 'ASSESSMENT FOR LEARNING', size: 'h5' }} />
                <CourseTab data={txMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/tx/direction.jpg" sx={{width:"80%", mx:"auto"}} noImgPopup/>


            </Grid>
        </Grid>
    )
}
