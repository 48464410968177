
export interface MenuButtonData {
        display: string,
        path: string
        level?: number,
        type?: MenuButtonLinkType
}

export enum MenuButtonLinkType {
        SubMenu = "subMenu",
        Navigate = "Navigate",
        WindowOpen = "WindowOpen"
}