import { Card, Grid, Typography, Button, CardActionArea, CardMedia } from '@mui/material';
import { MainCard } from '../../components/MainCard';
import { ParagraphProps, Paragraph, ParagraphOnlyText } from './Paragraph';

export function ParagraphWithImageTop(props: ParagraphProps) {
    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2} sx={{ mt: 2 }}>
            {
                props.haveImage!.images!.map((item, index) => (
                    <Grid item xs={11} sm>
                        <MainCard src={item.src} imgHeight={props.haveImage?.imageHeight} {...props.haveImage?.mainCardProps}/>
                    </Grid>
                ))
            }
            <Grid item xs={11} sm={12}>
                <ParagraphOnlyText {...props}/>
            </Grid>
        </Grid>
    );
}

