import { Button, CircularProgress, Divider, Grid, IconButton, Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { EventGalleryData } from "../../data/EventGalleryData";
import { homePageEventGallerySet } from "../../system/Slicer/HomePageSlicer";
import { selectComponentStore } from "../../system/PageStore";
import { MainCard } from "../components/MainCard";
import { DefaultSetting } from "../defaultSetting";
import { CardGallery, CardGalleryProps } from "./CardGallery";
import { TitleWithDate } from "./TitleWithDate";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import * as Lodash from 'lodash';
import SurfingIcon from '@mui/icons-material/Surfing';
import { relative } from "path";
import { LoadingBox } from "../components/LoadingBox";
import { ShortCut } from "../../system/ShortCut";
import { datePhraseForDay } from "../../system/DatePhrase";
import { useNavigate } from "react-router-dom";

interface EventGalleryProps {
    loading: boolean;
    data: Array<EventGalleryData> | null;
}

export function EventGallery(props: EventGalleryProps) {
    const isPc = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const componentStates = useSelector(selectComponentStore);

    let sortedData: Array<EventGalleryData> = [];
    if (props.data) props.data.forEach(i => sortedData.push(Object.assign({}, i)));
    sortedData.sort((a, b) => datePhraseForDay(b.date).getTime() - datePhraseForDay(a.date).getTime());
    if (sortedData.length > 12) sortedData = sortedData.slice(0, 12);

    const setIndex = (index: number): number => {
        let nextIndex = index;
        if (sortedData) {
            let data = Lodash.chunk(sortedData, isPc ? 3 : 2);
            if (index == data.length) nextIndex = 0;
            if (index <= -1) nextIndex = data.length - 1;
        }
        return nextIndex;
    };

    return (
        <Paper elevation={0} sx={{ m: 1, p: 1, borderRadius: DefaultSetting.borderRadius, backgroundColor: DefaultSetting.mainBackgroundColor }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                <Grid item xs={8} sm={8}>
                    <Stack direction="row">
                        <Typography variant="h5" align="left" sx={{ m: 1 }}>
                            <SurfingIcon sx={{ position: "relative", top: "3px", pr: 0.5, color: DefaultSetting.mainColor }} />
                            活動追蹤
                        </Typography>
                        <Button sx={{ pt: 2 }} onClick={() => { navigate("/oldEvents") }}>看更多</Button>
                    </Stack>
                </Grid>
                <Grid item xs={3} sm={3}>
                    <Stack direction="row-reverse" spacing={0}>
                        <IconButton
                            aroa-label="rightButton"
                            onClick={() => {
                                dispatch(homePageEventGallerySet(setIndex(componentStates.HomePageComponentReducer.homePageEventGalleryIdx + 1)))
                            }}
                        >
                            <ArrowRightIcon fontSize="large" sx={{ transform: "scale(1.5)", color: DefaultSetting.mainColor, m: -0, p: 0 }} />
                        </IconButton>
                        <IconButton
                            aroa-label="leftButton"
                            onClick={() => {
                                dispatch(homePageEventGallerySet(setIndex(componentStates.HomePageComponentReducer.homePageEventGalleryIdx - 1)))
                            }}
                            sx={{ m: -1, p: -1 }}
                        >
                            <ArrowLeftIcon fontSize="large" sx={{ transform: "scale(1.5)", color: DefaultSetting.mainColor, m: -0, p: 0 }} />
                        </IconButton>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Divider />
                    {(props.loading || !sortedData) ?
                        <LoadingBox />
                        :
                        <CardGallery
                            index={componentStates.HomePageComponentReducer.homePageEventGalleryIdx}
                            backgroundColor={DefaultSetting.mainBackgroundColor}
                            cardPerRow={3}
                            enableMouseEvents={true}
                            setIndex={homePageEventGallerySet}
                            cards={sortedData!.map(item => (
                                <MainCard
                                    src={item.src}
                                    onClick={() => ShortCut.windowOpen(item.open)}
                                    imgHeight={isPc ? "20vw" : "140px"}
                                    noImgPopup
                                    caption={
                                        <TitleWithDate align="center" title={item.caption?.toString()!} date={item.date} />
                                    }
                                    sx={{ m: 0.5, height: isPc ? "26vw" : "235px" }}
                                />
                            ))}
                        />
                    }
                </Grid>
            </Grid>
        </Paper>
    )
}