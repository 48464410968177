import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import React from 'react';
import { vaMenuData } from '../../../data/SubjectMenu/vaMenuData';
import { MainCards } from '../../../layoutPages/containers/MainCards';

export function PageVaConcern() {

    const isPc = useMediaQuery('(min-width:600px)');
    let dottedListData = ["利用視藝學習歷程檔案及進展性評估，加強學生對藝術知識的掌握、技巧探索，有系統記錄學習過程嘗試、成果與反思，提昇學生綜合應用所學的能力；", "加強校園藝術氛圍，創設更多分享平台，讓學生透過對日常藝術觀察和記錄，提升學生對美感觸覺，豐富美感經驗；", "跨學科合作，豐富學生多元學習經歷；", "善用社區資源及校外藝術參觀，有助學生認識及尊重多元文化，促進他們珍惜歷史和文化承傳。"]

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='視覺藝術 | 年度關注事項' size='h3' subtitle={{ title: 'CURRENT MAIN CONCERN', size: 'h5' }} />
                <CourseTab data={vaMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/va/concern/concern_top.jpg" noImgPopup imgHeight='650px'/>

                <Paragraph title="配合學校學與教發展，重點提升學生美感素養" titleSize='h5'
                    data={[
                        <DottedList data={dottedListData} />]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/va/concern/concern1.jpg" },],
                        position: "right",
                        imageHeight: "400px",
                    }}
                />

                <MainCard sx={{ mt:3 }} src="http://www.pkps.edu.hk/img/teachplan/va/concern/concern2.jpg"  imgHeight='500px' />

            </Grid>
        </Grid>
    )
}
