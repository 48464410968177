import { Box, BoxProps, Fade, IconButton, SxProps } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { selectComponentStore } from '../../system/PageStore';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from "react-swipeable-views-utils";
import { changePopUpStates } from '../../system/Slicer/CommonSlicer';
import { DefaultSetting } from '../defaultSetting';
import { GalleryImageData } from '../../data/GalleryImgDate';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export interface AutoPlaySliderProps extends BoxProps {
    data: Array<GalleryImageData>,
    event: (index: number, lastIndex?: number) => {},
    children?: React.ReactNode,
    width?: string,
    height?: string,
    sx?: SxProps,
}

export function AutoPlaySlider(props: AutoPlaySliderProps) {
    const dispatch = useDispatch();
    const componentStates = useSelector(selectComponentStore);

    return <Box component="div" onMouseLeave={props.onMouseLeave} onMouseEnter={props.onMouseEnter} sx={{ position: "relative", width: 1, height: props.height ?? 1, overflow: "hidden", borderRadius: DefaultSetting.borderRadius, ...props.sx }} >
        <AutoPlaySwipeableViews
            axis="x"
            disableLazyLoading
            enableMouseEvents
            index={componentStates.HomePageComponentReducer.homePageSliderIndex}
            onChangeIndex={props.event}
        >
            {props.data.map((item, index) => (
                <IconButton onClick={() => { dispatch(changePopUpStates({src:item.url})) }}>
                    <Box
                        component="img"
                        key={index}
                        src={item.url}
                        sx={{ width: "100%", height:"100%",objectFit: "cover", overflow:"hidden", borderRadius: "5px"}}
                    />
                </IconButton>
            ))}
        </AutoPlaySwipeableViews>
        {props.children}
    </Box>
}