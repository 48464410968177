import { Box, Card, CardMedia, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../layoutPages/components/PageTitle';

import { HaveImage, Paragraph } from '../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../layoutPages/defaultSetting';
import { DottedList } from '../../layoutPages/containers/DottedList';
import { MainCard } from '../../layoutPages/components/MainCard';
import StarIcon from '@mui/icons-material/Star';
import React from 'react';
import { txMenuData } from '../../data/SubjectMenu/txMenuData';
import { CourseTab } from '../CourseTarget/menuTab';
import { enrollmentMenuData } from '../../data/enrollmentMenuData';

export function PageTimeTable() {

    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={12} sm={10} >

                <br></br>

                <CourseTab data={enrollmentMenuData} />
                
                <PageTitle title='上課時間表 | 入學資訊' size='h3' subtitle={{ title: 'ASSESSMENT FOR LEARNING', size: 'h5' }} />

                {/* <MainCard src="http://www.pkps.edu.hk/img/teachplan/tx/ref.jpg" noImgPopup={true}/> */}

                <Card>
                    <CardMedia height="2000px" component="iframe" src='https://docs.google.com/viewer?url=http://www.pkps.edu.hk/newhtml/newimage/timetable4.pdf&embedded=true' />
                </Card>
            </Grid>
        </Grid>

    )
}
