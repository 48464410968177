import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { MainCardProps } from '../../../layoutPages/components/MainCard';
import { engMenuData } from '../../../data/SubjectMenu/engMenuData';

export function PageEngResources() {

    let point2Img: Array<MainCardProps> = [
        { src: "http://www.pkps.edu.hk/img/teachplan/eng/resources/point2a.jpg", onClick: () => { } },
        { src: "http://www.pkps.edu.hk/img/teachplan/eng/resources/point2b.jpg" },
        { src: "http://www.pkps.edu.hk/img/teachplan/eng/resources/point2b.jpg" },

    ];

    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='Online Learning Resources' size='h3' />
                <CourseTab data={engMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/eng/resources/top.jpg" noImgPopup={true} />
            </Grid>

            <Grid item xs={11} sm={7} justifyContent="start">
                <Typography variant="h5" align={"left"} >1. Phonics</Typography>
                <MainCard
                    sx={{ maxWidth: 480, mb: 4, mt: 2 }}
                    src="http://www.pkps.edu.hk/img/teachplan/eng/resources/point1.png" onClick={() => window.open("http://www.pkps.edu.hk/eng/phonics/", '_blank', 'noopener,noreferrer')} />

                <Typography variant="h5" align={"left"} >2. Reading Corner</Typography>

                <MainCards data={[
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/eng/resources/point2a.jpg",
                        onClick: () => window.open("http://www.pkps.edu.hk/newhtml/daily/p1-p3.htm", '_blank', 'noopener,noreferrer')
                    },
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/eng/resources/point2b.jpg",
                        onClick: () => window.open("http://www.pkps.edu.hk/TV/magicwordsbank/", '_blank', 'noopener,noreferrer')
                    },
                ]} />

                <Typography variant="h5" align={"left"} sx={{ mt: 4 }}>3. Self-Regulated Learning Corner(with link)</Typography>

                <MainCards data={[
                    {
                        caption: "Phonics Enhancement Program",
                        src: "http://www.pkps.edu.hk/img/teachplan/eng/resources/point3a.jpg",
                        onClick: () => window.open("https://www.starfall.com/h/", '_blank', 'noopener,noreferrer')
                    },
                    {
                        caption: "Vocabulary Enhancement Program",
                        src: "http://www.pkps.edu.hk/img/teachplan/eng/resources/point3b.jpg",
                        onClick: () => window.open("https://www.vocabulary.com/", '_blank', 'noopener,noreferrer')
                    },
                ]} />

                <MainCards data={[
                    {
                        caption: "Grammar Enhancement Program",
                        src: "http://www.pkps.edu.hk/img/teachplan/eng/resources/point3c.jpg",
                        onClick: () => window.open("http://www.roadtogrammar.com/", '_blank', 'noopener,noreferrer')
                    },
                    {
                        caption: "Grammar Enhancement Program",
                        src: "http://www.pkps.edu.hk/img/teachplan/eng/resources/point3d.jpg",
                        onClick: () => window.open("http://www.pkps.edu.hk/newhtml/news/0908/gp/index.htm", '_blank', 'noopener,noreferrer')
                    },
                ]} />

                <MainCards data={[
                    {
                        caption: "Electronic dictionary references",
                        src: "http://www.pkps.edu.hk/img/teachplan/eng/resources/point3e.jpg",
                        onClick: () => window.open("https://dictionary.cambridge.org/zht/", '_blank', 'noopener,noreferrer')
                    },
                    {
                        caption: "Electronic dictionary references",
                        src: "http://www.pkps.edu.hk/img/teachplan/eng/resources/point3f.jpg",
                        onClick: () => window.open("http://www.pkps.edu.hk/newhtml/news/0908/gp/index.htm", '_blank', 'noopener,noreferrer')
                    },
                ]} />

                <MainCards data={[
                    {
                        caption: "Electronic dictionary references",
                        src: "http://www.pkps.edu.hk/img/teachplan/eng/resources/point3g.jpg",
                        onClick: () => window.open("https://www.ldoceonline.com/dictionary", '_blank', 'noopener,noreferrer')
                    },
                    {
                        caption: "Electronic dictionary references",
                        src: "http://www.pkps.edu.hk/img/teachplan/eng/resources/point3h.jpg",
                        onClick: () => window.open("https://www.thesaurus.com/", '_blank', 'noopener,noreferrer')
                    },
                ]} />


            </Grid>






        </Grid>




    );
}