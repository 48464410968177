import TopMenu from "./layoutPages/containers/TopMenu";
import { Router } from "./Router";
import { Footer } from './layoutPages/containers/Footer';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { TopBanner } from "./layoutPages/components/TopBanner";
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Collapse, Fade, Grow, IconButton, Zoom } from '@mui/material';
import { TopMenuButton } from "./layoutPages/components/TopMenuButton";
import { MainDrawer } from './layoutPages/containers/MainDrawer';
import { MenuButtonData } from "./system/Classes/MenuButtonData";
import { menuData } from "./data/MenuData";

import T1 from '../images/Template/T1.jpg';
import { ImagePop } from './layoutPages/containers/ImagePop';
import { useSelector } from 'react-redux';
import { selectComponentStore } from './system/PageStore';
import { DefaultSetting } from "./layoutPages/defaultSetting";
import { Outlet } from "react-router-dom";
import ScrollToTop from "./system/ScrollTop";

export const pkpsTheme = createTheme({
  typography: {
    fontFamily: [
      'Noto Sans TC',
      'sans-serif',
    ].join(','),
  },
});

export default function App() {
  const componentStates = useSelector(selectComponentStore);
  const isPc = useMediaQuery('(min-width:600px)');
  let popUpImgPops = componentStates.CommonComponentReducer.popUpImageProps;

  return <ThemeProvider theme={pkpsTheme}>
    {popUpImgPops && <ImagePop 
      src={popUpImgPops.src}
      onclickClose={popUpImgPops.onclickClose}
      imageOnClick={popUpImgPops.imageOnClick}
    />}
    {isPc && <TopMenu />}
    {!isPc && <>
      <TopMenuButton />
      <MainDrawer data={menuData} />
    </>
    }
    <Fade in appear timeout={500}>
      <Box sx={{ backgroundColor: DefaultSetting.bodyBackgroundColor }}>
        <ScrollToTop />
        <Router />
        <Footer />
      </Box>
    </Fade>
    <Outlet />
  </ThemeProvider>
}