import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import StarsIcon from '@mui/icons-material/Stars';
import { Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { MaterialList, MaterialWithLink } from '../../../layoutPages/components/MaterialList';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { CourseTab } from '../menuTab';
import { chiMenuData } from '../../../data/SubjectMenu/chiMenuData';
import { engMenuData } from '../../../data/SubjectMenu/engMenuData';

export function PageEngBase() {

    const isPc = useMediaQuery('(min-width:600px)');

    const data: Array<MaterialWithLink> = [{
        name: "PPT:1.Process Writing",
        src: "http://www.pkps.edu.hk/09-10%20teachplan/final/pdf/1.%20Process%20Writing.ppt",
        icon: <TextSnippetIcon sx={{ color: "orange" }} />,
    },
    {
        name: "PPT:2. Co-teaching",
        src: "http://www.pkps.edu.hk/09-10%20teachplan/final/pdf/2.%20Co-teaching.ppt.1.ppt",
        icon: <TextSnippetIcon sx={{ color: "orange" }} />,
    },

    {
        name: "PPT:3. Conferencing",
        src: "http://www.pkps.edu.hk/09-10%20teachplan/final/pdf/3.%20Conferencing.ppt",
        icon: <TextSnippetIcon sx={{ color: "orange" }} />,
    },

    {
        name: "PPT:4. Portfolio Workshop",
        src: "http://www.pkps.edu.hk/09-10%20teachplan/final/pdf/4.%20Portfolio%20Workshop.ppt",
        icon: <TextSnippetIcon sx={{ color: "orange" }} />,
    },


    ];

    let dottedListDataPoint1 = [
        "Reading Success Program (RSP) (P.1-3) Phonics learning, Storytelling, Shared Reading, Guided Reading and Home Reading.",
        "Reading Across Curriculum (RaC) (P.4-6) Develop students’ thinking skills, enrich knowledge, enhance language proficiency and broaden life experience.",
        "Take Home Readers and Electronic Books (P.1-6) Develop students’ self-directed learning capabilities and habit by giving them more autonomy in learning.",
        "Intensive Reading Program (P.1-6) Help students develop reading skills with strategies which are necessary for understanding and analyzing language use in English texts."
    ];

    let dottedListDataPoint3 = [
        "Self enhancement program Self-learning Book (SLB); Supplementary Learning Resources (SLR) and Reading & Writing Booklet The program is designed for catering students’ learning diversity. Self-learning tools are provided for students’ access of quick assistance.",
        "Vocabulary Building - Word Bank (P.3-6) Students can learn how to use appropriate words and expressions in their writing and speaking, as well as to expand their vocabulary in groups so they can share their ideas accurately and confidently.",
    ];

    let dottedListDataPoint4 = [
        "Posters and Signs Around School To provide students with an enriched English environment.",
        "Weekly Radio Program Pui Kiu On Air and Lunchtime Story – DJs run lunch time radio show every week on English Day.",
        "English Morning Assembly English activities are held in the school assembly with the help of the NET teacher to increase students’ exposure to the language.",
        "Chatting with Speaking Ambassadors Students talk to English Speaking Ambassadors during lunch breaks to polish their oral skills.",
        "Playing games in Activity Room Students are encouraged to participate in different activities such as storytelling, action games and singing songs that make English fun!",
        "Newspaper Reading All the P.4-6 students, guided by our NET, read articles from Posties, our newspaper.",

    ];

    let dottedListDataPoint5 = [
        "Festive Celebration Halloween, Christmas and Easter are times for positive reinforcement of cultural awa",
        "Care for our community Be aware of the hot issues in our society and living environment. With the use of English, students contribute their effort to make our world a better place.",
        "Talent Shows A confidence-boosting opportunity for students to shine before others in English.",
    ]

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <PageTitle title='校本課程特色 School-Based Curriculum' size='h3' />
            <Grid item xs={11} sm={10}>
            <CourseTab data={engMenuData}/>
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/eng/base/top2.jpg" noImgPopup={true} />
            </Grid>

            <Grid item xs={11} sm={10} sx={{ p: 2 }}>
                <Typography title="1. Learn to Read & Read to Learn" />
                <DottedList data={dottedListDataPoint1} variant={<StarsIcon />} />

                <Grid container direction="row" justifyContent="center" alignItems="top" spacing={1} sx={{ mt: 2 }}>
                    <Grid item xs={6} sm={4}>
                        <MainCard src="http://www.pkps.edu.hk/img/teachplan/eng/base/basePoint1a.jpg" />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <MainCard src="http://www.pkps.edu.hk/img/teachplan/eng/base/basePoint1b.jpg" />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <MainCard src="http://www.pkps.edu.hk/img/teachplan/eng/base/basePoint1c.jpg" />
                    </Grid>

                </Grid>
            </Grid>

            <Grid item xs={11} sm={10} sx={{ p: 2 }}>
                <Paragraph title='2. From Reading to Writing'
                    data={["*Process Writing (P.3-5) In the process of writing, teachers teach students how to brainstorm and organize ideas, do self and peer editing, make drafts and re-draft with a final sharing. Teachers provide individual conferencing during the writing process to suit individual needs.",]
                    }
                />
            </Grid>

            <Grid item xs={10} sm={8} sx={{}}>
                <MaterialList data={data} />
            </Grid>

            <Grid item xs={10} sm={10} sx={{ p: 2 }}>
                <Typography title="3. All-rounded Language Skills Development" />
                <DottedList data={dottedListDataPoint3} variant={<StarsIcon />} />

                <Grid container direction="row" justifyContent="space-around" alignItems="top" spacing={1} sx={{ mt: 2 }}>
                    <Grid item xs={6} sm={5}>
                        <MainCard src="http://www.pkps.edu.hk/img/teachplan/eng/base/basePoint2a.jpg" />
                    </Grid>
                    <Grid item xs={6} sm={5}>
                        <MainCard src="http://www.pkps.edu.hk/img/teachplan/eng/base/basePoint2b.jpg" />
                    </Grid>

                </Grid>
            </Grid>

            <Grid item xs={10} sm={10} sx={{ p: 2 }}>
                <Typography title="4. Enriching English Learning Environment" />
                <DottedList data={dottedListDataPoint4} variant={<StarsIcon />} />
            </Grid>



            <Grid item xs={10} sm={10} sx={{ p: 2 }}>
                <Typography title="5. Fostering Love of Learning" />
                <DottedList data={dottedListDataPoint5} variant={<StarsIcon />} />
                <Grid container direction="row" justifyContent="space-around" alignItems="top"  spacing={1} sx={{ mt: 2 }}>
                    <Grid item xs={6} sm={5}>
                        <MainCard imgHeight='250px' src="http://www.pkps.edu.hk/img/teachplan/eng/base/basePoint5a.jpg" />
                    </Grid>

                    <Grid item xs={6} sm={5}>
                        <MainCard imgHeight='250px' src="http://www.pkps.edu.hk/img/teachplan/eng/base/basepoint5d.jpg" />
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}