import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import React from 'react';
import { vaMenuData } from '../../../data/SubjectMenu/vaMenuData';

export function PageVaObjectives() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = ["鼓勵學生積極參與藝術體驗活動，透過藝術評賞及創作過程，培養美感觸覺和文化意識", "發展創造力、明辨性思考能力、協作和解決問題的能力", "發展藝術技能、建構知識和培養正面的價值觀和積極的態度", "從參與藝術活動中獲得愉悅、享受和滿足，並培養對藝術的終身興趣"]


    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='視覺藝術 | 課程目標' size='h3' subtitle={{ title: 'CURRICULUM OBJECTIVES', size: 'h5' }} />
                <CourseTab data={vaMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/va/objectives/obj_top.jpg" noImgPopup={true} imgHeight="650px" />

                <Paragraph
                    data={[ <DottedList data={dottedListData} /> ]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/va/objectives/obj1.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/va/objectives/obj2.jpg" }],
                        position: "down",
                        imageHeight: "400px",
                    }}
                />

            </Grid>
        </Grid>
    )
}
