import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import React from 'react';
import { peMenuData } from '../../../data/SubjectMenu/peMenuData';

export function PagePeBase() {

    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='體育科 | 校本課程特色' size='h3' subtitle={{ title: 'SCHOOL-BASED CURRICULUM', size: 'h5' }} />
                <CourseTab data={peMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/pe/base/B1.jpg" noImgPopup={true} />

                <Paragraph title="注重體適能發展"
                    data={[
                        <DottedList data={["本科課程著重學生體適能發展，在各級體育課程中加入適量體適能訓練，鼓勵學生提升自己的身體素質；"]} />
                    ]}

                />

                <Paragraph title="多元化評估模式"
                    data={[
                        <DottedList data={
                            ["爲小四至小六的學生設計體育評估檔案，課程內容包括進展性及總結性評估，更加入自評及互評部分；", "爲小四學生加入了「綜藝課程」，評估學生不同技能的表現；"]} />
                    ]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/pe/base/B2.jpg" },],
                        position: "down",
                    }}
                />

                <br></br><br></br>

                <Paragraph title="引入電子科技輔助教學"
                    data={[
                        <DottedList data={["於課堂中運用電子科技協助學習，學習素材上載網上平台為學生提供延伸學習機會。"]} />
                    ]}

                />

                <Paragraph title="多元化體育活動"
                    data={[
                        <DottedList data={
                            ["組織多項運動代表隊訓練（籃球、田徑、足球、排球、游泳、乒乓球、羽毛球、舞蹈及跆拳），發掘有潜質的運動員；", "定期舉辦多項班際體育活動，藉此普及運動風氣，培養學生愛運動的健康習慣；", "培僑小學的陸運會是一個「人人參與」的運動會，每位同學均有機會踏足運動場嵾與競技，改變運動會只屬少數精英的局面；"]} />
                    ]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/pe/base/B3.jpg" },],
                        position: "right",
                    }}
                />

            </Grid>
        </Grid>
    )
}
