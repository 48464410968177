import { Button, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { MenuButtonData, MenuButtonLinkType } from "../../system/Classes/MenuButtonData";
import { ShortCut } from "../../system/ShortCut";
import { DefaultSetting } from "../defaultSetting";

export interface MenuButtonsProps {
    data: Array<MenuButtonData>;
    openSubMenu?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    closeSubMenu?: () => void;
}

export function MenuButtons(props: MenuButtonsProps) {
    const navigate = useNavigate();
    return (<>
        {
            props.data.map((prop) => (
                <Button
                    variant='text'
                    size="large"
                    sx={{
                        color: '#E5E5E5',
                        '&:hover': {
                            transform: "scale(1.1);",
                            color: "#FFFFFF",
                            backgroundColor:"#2b5eb2",
                            transition: "color .3s ease-in-out, .3s ease-in-out;",
                            animation: "rotateY(360deg) 2s",
                            textUnderlineOffset:"5px",
                            textDecoration: "underline 3px",
                            border:0,
                        },
                    }}
                    key={prop.display}
                    onClick={
                        prop.type ?
                            prop.type == MenuButtonLinkType.WindowOpen ? () => ShortCut.windowOpen(prop.path) :
                                prop.type == MenuButtonLinkType.SubMenu ? props.openSubMenu :
                                    () => navigate(prop.path)
                            :
                            () => navigate(prop.path)
                    }
                >
                    <Typography variant="body1" sx={{ fontSize: "1.15rem" }}>
                        {prop.display}
                    </Typography>
                </Button>
            ))
        }
    </>);
}