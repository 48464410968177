import { AutoPlaySlider } from "../AutoPlaySlider";
import { Box, Divider, Fade, Grid, Skeleton, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectComponentStore } from "../../../system/PageStore";
//
import { DefaultSetting, HomePageSectionOne, HomePageSectionTwo } from '../../defaultSetting';
import { TopBanner } from '../../components/TopBanner';
//
import { useGetGalleryDataQuery } from "../../../system/Features/getGalleryData";
import { closeHomePagePopUpImg, homePageSliderMove, switchSliderHover } from "../../../system/Slicer/HomePageSlicer";
import { ArrowControl } from "../../components/Slider/ArrowControl";
import { DottedControl } from "../../components/Slider/DottedControl";
//
import { NewsData } from '../../../data/NewsData';
import { NewsBox } from '../NewsBox';
//
import { HomePageMaterials } from "./HomePageMaterials";
import { HomePageButtonsGroup } from "./HomePageButtonsGroup";
import { HomePageUseFullLinkGroup } from "./HomePageUsefullLinkGroup";
//
import { EventGallery } from "../EventGallery";
import { useGetEventGalleryDataQuery } from "../../../system/Features/getEventGalleryData";
import { changePopUpStates } from "../../../system/Slicer/CommonSlicer";

export function HomePage() {
    const isPc = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch();
    const componentStates = useSelector(selectComponentStore);
    let sectionOneHeight = isPc ? HomePageSectionOne.height.pc : HomePageSectionOne.height.mb;

    const setSlider = (index: number, lastIndex?: number) => {
        if (galleryImgData.isSuccess) {
            let nextIndex = index;
            if (index == galleryImgData.data!.length) nextIndex = 0;
            if (index <= -1) nextIndex = galleryImgData.data!.length - 1;
            dispatch(homePageSliderMove(nextIndex))
        }
        return {}
    }

    //PoPupPage
    if (componentStates.HomePageComponentReducer.homePagePopUpImg) {
        dispatch(changePopUpStates({
            src: "http://www.pkps.edu.hk/img/website/homePage/pop9.jpg",
            onclickClose: false,
            imageOnClick: () => { window.open("https://docs.google.com/forms/d/e/1FAIpQLSdLHdg4EpF4ma-pxvHsZ8m0PaTZqpqPMrE0v_-Kok_IjJ2OKA/viewform", '_blank') },
        }));
        dispatch(closeHomePagePopUpImg(false));
    }

    let galleryImgData = useGetGalleryDataQuery("");
    let eventData = useGetEventGalleryDataQuery("");

    return (
        <>
            <TopBanner />
            <Box sx={{ p: 2 }}>
                <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
                    <Grid item xs={12} sm={8}>
                        {
                            galleryImgData.isSuccess && galleryImgData.data ?
                                <AutoPlaySlider
                                    onMouseLeave={() => { dispatch(switchSliderHover()) }}
                                    onMouseEnter={() => { dispatch(switchSliderHover()) }}
                                    height={sectionOneHeight}
                                    event={setSlider}
                                    data={galleryImgData.data!}
                                >
                                    {((componentStates.HomePageComponentReducer.isHoverSlider && isPc) || !isPc) &&
                                        <ArrowControl
                                            sx={{ backgroundColor: "black" }}
                                            leftClick={() => { setSlider(componentStates.HomePageComponentReducer.homePageSliderIndex - 1) }}
                                            rightClick={() => { setSlider(componentStates.HomePageComponentReducer.homePageSliderIndex + 1) }}
                                        />
                                    }
                                    {isPc &&
                                        <DottedControl
                                            boxSx={{ backgroundColor: DefaultSetting.transparentBG, position: "absolute", bottom: "3%" }}
                                            data={Array.from(galleryImgData.data!)}
                                            nowIndex={componentStates.HomePageComponentReducer.homePageSliderIndex}
                                            onclick={setSlider}
                                        />
                                    }

                                </AutoPlaySlider>
                                :
                                <Box component="div" sx={{ position: "relative", width: 1, overflow: "hidden", borderRadius: DefaultSetting.borderRadius }} >
                                    <Skeleton
                                        variant="rectangular"
                                        sx={{ backgroundColor: "#F0F0F0" }}
                                        animation="wave"
                                        height={HomePageSectionOne.height.pc}
                                    />
                                </Box>
                        }
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <NewsBox data={NewsData} sx={{ minHeight: HomePageSectionOne.height.pc }} />
                    </Grid>
                </Grid>
                <EventGallery loading={!eventData.isSuccess} data={eventData.isSuccess ? eventData.data : null} />
                <HomePageButtonsGroup />
                <HomePageMaterials />
                <HomePageUseFullLinkGroup />
            </Box>
        </>
    )
}