export class ShortCut {
    static windowOpen(link: string) {
        return window.open(link, '_blank', 'noopener,noreferrer');
    }

    static nextIndex = (index: number, max: number, lastIndex?: number): number => {
        let nextIndex = index;
        if (index >= max) nextIndex = 0;
        return nextIndex;
    }
}
