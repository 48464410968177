import { getEventGalleryDataApi } from './Features/getEventGalleryData';
//cspell:ignore reduxjs
import { configureStore } from '@reduxjs/toolkit'
import { HomePageComponentReducer } from './Slicer/HomePageSlicer';
import { getGalleryDataApi } from './Features/getGalleryData';
import { EngSubjectEngDisplayGalleryReducer } from './Slicer/EngSubjectEngDisplayGallerySlicer';
import { CommonComponentReducer } from './Slicer/CommonSlicer';

export const pageStore = configureStore({
  reducer: {
    [getGalleryDataApi.reducerPath]: getGalleryDataApi.reducer,
    [getEventGalleryDataApi.reducerPath]: getEventGalleryDataApi.reducer,
    CommonComponentReducer:CommonComponentReducer,
    HomePageComponentReducer:HomePageComponentReducer,
    EngSubjectEngDisplayGalleryReducer:EngSubjectEngDisplayGalleryReducer
  },
});

export type PageStore = ReturnType<typeof pageStore.getState>;
export const selectComponentStore = (state: PageStore) => state;