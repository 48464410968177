import { Box, Card, CardMedia, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { MaterialList } from '../../../layoutPages/components/MaterialList';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { pthMenuData } from '../../../data/SubjectMenu/pthMenuData';

export function PagePthConcern() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = [
        "通過舉辦各項普通話活動，營造普通話學習氛圍;",
        "積極推動學生參加校內外比賽或活動，為學生提供多元學習經歷。",
    ];

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='普通話 | 年度重點項目' size='h3' subtitle={{ title: 'CURRENT MAIN CONCERN', size: 'h5' }} />
                <CourseTab data={pthMenuData}/>
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/pth/concern/top.jpg" noImgPopup={true} />

                {/* <Typography variant="h4" align={"left"} sx={{ py: 2, color: "#F02602" }} >校本課程特色:</Typography> */}

                <Paragraph 
                    data={[<DottedList data={dottedListData} />]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/pth/concern/concern1.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/pth/concern/concern2.jpg" }],
                        position: "down",
                        imageHeight:"450px"
                    }}
                />

            </Grid>



        </Grid>
    )
}
