import { useMediaQuery, Box, Grid, Divider } from '@mui/material';

import T1 from '../images/Template/T1.jpg';
import T2 from '../images/Template/T2.jpg';
import T3 from '../images/Template/T3.jpg';
import T4 from '../images/Template/T4.jpg';
import T5 from '../images/Template/T5.jpg';
import T6 from '../images/Template/T6.jpg';

import G1 from "../images/Slider/G1.png";
import G2 from "../images/Slider/G2.png";
import G3 from "../images/Slider/G3.png";
import G4 from "../images/Slider/G4.png";
import G5 from "../images/Slider/G5.png";
import { MainCards } from '../layoutPages/containers/MainCards';
import { height } from '@mui/system';
import { Masonry } from '@mui/lab';
import { MasonryGallery } from '../layoutPages/containers/MasonryGallery';
import { MainCardProps } from '../layoutPages/components/MainCard';
import { PageTitle } from '../layoutPages/components/PageTitle';

export const TempCardData: Array<MainCardProps> = [
    { caption: "某標題", src: G1 },
    { caption: "排隊", src: G2 },
    { caption: "放學", src: G3 },
    { caption: "指導", src: G4 }
];

export const masonryItems: Array<string> = [
    T1, T2, T3, T6, G1, G2, G3, T4, T5, G4, G5
];

export function ImageGalleryTemplate() {
    const isPc = useMediaQuery('(min-width:600px)');
    return (
        <Box>
            <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
                <PageTitle title='Gallery' size='h3' subtitle={{ title: "Subcaption", size: 'h5' }} />

                <Grid item xs={11} sm={11}>
                    <MainCards height="350px" data={TempCardData} />
                </Grid>

                <Grid item xs={11} sm={12}>
                    <Divider> 再來一個活動的作品集 </Divider>
                </Grid>

                <Grid item xs={11} sm={11}>
                    <MasonryGallery imageSrcSet={masonryItems} />
                </Grid>
            </Grid>
        </Box >
    )
}