import { Box, Card, CardMedia, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { musicMenuData } from '../../../data/SubjectMenu/musicMenuData';
import StarsIcon from '@mui/icons-material/Stars';
import { useDispatch } from 'react-redux';

import { selectComponentStore } from '../../../system/PageStore';
import { ContentSlider } from '../../../layoutPages/containers/ContentSlider';
import { MaterialList } from '../../../layoutPages/components/MaterialList';
import WebIcon from '@mui/icons-material/Web';
//import { DefaultSetting } from '../../../layoutPages/defaultSetting';

export function PageMusicDevelopment() {

    const isPc = useMediaQuery('(min-width:600px)');
    

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='音樂科 | 教師專業發展' size='h3' subtitle={{ title: 'PROFESSIONAL DEVELOPMENT OF TEACHERS', size: 'h5' }} />
                <CourseTab data={musicMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/music/development/top.jpg" imgHeight='650px' noImgPopup sx={{ mb: 2 }} />

                <Paragraph
                    data={["音樂老師設計音樂課教學活動、四年級綜藝課程（音樂藝術）、校本粵劇課程、培訓音樂校隊、統籌舉辦樂器班和粵劇班、帶領音樂比賽或表演，參與坊間音樂活動，透過多元化的校內校外全方位音樂藝術活動，教學相長，擴闊藝術視野，提昇教學效能。"]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/music/development/development1.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/music/development/development2.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/music/development/development3.jpg" }],
                        position: "down",
                        imageHeight: "400px",
                    }}
                />

            </Grid>

        </Grid>
    )
}
