//cspell: ignore Testpage
import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "./layoutPages/containers/HomePage/HomePage";
import { NoPage } from "./layoutPages/NoPage";
import { Testpage } from "./layoutPages/TestPage";
import { TemplateB } from './template/TemplateB';
import { TemplateC } from './template/TemplateC';
import { VideoGalleryTemplate } from './template/VideoGalleryTemplate';
import { ImageGalleryTemplate } from './template/ImageGalleryTemplate';
import { TempTestPage } from './template/TempTestPage';
import { MaterialListTemplate } from './template/MaterialListTemplate';
import { PageEngBase } from "./Pages/CourseTarget/Eng/PageEngBase";
import { PageEngObjectives } from "./Pages/CourseTarget/Eng/PageEngObjectives";
import { PageEngConcern } from "./Pages/CourseTarget/Eng/PageEngConcern";
import { PageEngDisplay } from "./Pages/CourseTarget/Eng/PageEngDisplay";

import { PageEngDevelopment } from "./Pages/CourseTarget/Eng/PageEngDevelopment";
import { PageEngResources } from "./Pages/CourseTarget/Eng/PageEngResources";
import { PageChiObjectives } from "./Pages/CourseTarget/Chi/PageChiObjectives";
import { PageChiBase } from "./Pages/CourseTarget/Chi/PageChiBase";
import { PageChiConcern } from "./Pages/CourseTarget/Chi/PageChiConcern";
import { PageChiResources } from "./Pages/CourseTarget/Chi/PageChiResources";
import { PageChiDevelopment } from "./Pages/CourseTarget/Chi/PageChiDevelopment";
import { PageChiSupport } from "./Pages/CourseTarget/Chi/PageChiSupport";
import { PageMathsObjectives } from "./Pages/CourseTarget/Maths/PageMathsObjectives";
import { PageMathsBase } from "./Pages/CourseTarget/Gs/PageMathsBase";
import { PageMathsConcern } from "./Pages/CourseTarget/Maths/PageMathsConcern";
import { PageGsObjectives } from "./Pages/CourseTarget/Gs/PageGsObjectives";
import { PageGsBase } from "./Pages/CourseTarget/Gs/PageGsBase";
import { PageGsConcern } from "./Pages/CourseTarget/Gs/PageGsConcern";
import { PageGsDevelopment } from "./Pages/CourseTarget/Gs/PageGsDevelopment";
import { PageGsResources } from "./Pages/CourseTarget/Gs/PageGsResources";
import { PagePthObjectives } from "./Pages/CourseTarget/Pth/PagePthObjectives";
import { PagePthBase } from "./Pages/CourseTarget/Pth/PagePthBase";
import { PagePthConcern } from "./Pages/CourseTarget/Pth/PagePthConcern";
import { PagePthDisplay } from "./Pages/CourseTarget/Pth/PagePthDisplay";
import { PagePthDevelopment } from "./Pages/CourseTarget/Pth/PagePthDevelopment";
import { PageMusicObjectives } from "./Pages/CourseTarget/Music/PageMusicObjectives";
import { PageMusicBase } from "./Pages/CourseTarget/Music/PageMusicBase";
import { PageMusicConcern } from "./Pages/CourseTarget/Music/PageMusicConcern";
import { PageMusicDevelopment } from "./Pages/CourseTarget/Music/PageMusicDevelopment";
import { PageMusicDisplay } from "./Pages/CourseTarget/Music/PageMusicDisplay";
import { PagePeObjectives } from "./Pages/CourseTarget/Pe/PagePeObjectives";
import { PagePeBase } from "./Pages/CourseTarget/Pe/PagePeBase";
import { PagePeConcern } from "./Pages/CourseTarget/Pe/PagePeConcern";
import { PagePeDisplay } from "./Pages/CourseTarget/Pe/PagePeDisplay";
import { PagePeDevelopment } from "./Pages/CourseTarget/Pe/PagePeDevelopment";
import { PageChiDisplay } from "./Pages/CourseTarget/Chi/PageChiDisplay";
import { PageGsDisplay } from "./Pages/CourseTarget/Gs/PageGsDisplay";
import { PageMathsDevelopment } from "./Pages/CourseTarget/Maths/PageMathsDevelopment";
import { PageMathsResources } from "./Pages/CourseTarget/Maths/PageMathsResources";
import { PageMathsDisplay } from "./Pages/CourseTarget/Maths/PageMathsDisplay";
import { PageUpIndex } from "./Pages/CourseTarget/Up/PageUpIndex";
import { PageItBase } from "./Pages/CourseTarget/It/PageItBase";
import { PageItConcern } from "./Pages/CourseTarget/It/PageItConcern";
import { PageItDevelopment } from "./Pages/CourseTarget/It/PageItDevelopment";
import { PageItObjectives } from "./Pages/CourseTarget/It/PageItObjectives";
import { PageVaBase } from "./Pages/CourseTarget/Va/PageVaBase";
import { PageVaConcern } from "./Pages/CourseTarget/Va/PageVaConcern";
import { PageVaDevelopment } from "./Pages/CourseTarget/Va/PageVaDevelopment";
import { PageVaObjectives } from "./Pages/CourseTarget/Va/PageVaObjectives";
import { PageVaDisplay } from "./Pages/CourseTarget/Va/PageVaDisplay";
import { PageTxDirection } from "./Pages/CourseTarget/Tx/PageTxDirection";
import { PageTxDef } from "./Pages/CourseTarget/Tx/PageTxDef";
import { PageTxStrategy } from "./Pages/CourseTarget/Tx/PageTxStrategy";
import { PageTxItem } from "./Pages/CourseTarget/Tx/PageTxItem";
import { PageTxMode } from "./Pages/CourseTarget/Tx/PageTxMode";
import { PageTxRef } from "./Pages/CourseTarget/Tx/PageTxRef";
import { PageTxHomework } from "./Pages/CourseTarget/Tx/PageTxHomework";
import { PageParentCourse } from "./Pages/PageParentCourse";
import { PageVolunteer } from "./Pages/PageVolunteer";
import { PageTvStation } from "./Pages/TvStation";
import { Pagefeatures } from "./Pages/enrollment/features";
import { PageStructure } from "./Pages/enrollment/structure";
import { PageApplication } from "./Pages/enrollment/application";
import { PageCalendar } from "./Pages/enrollment/calendar";
import { PageTimeTable } from "./Pages/enrollment/timetable";
import { PageSupportPlan } from "./Pages/PageSupportPlan";
import { PageOldEvents } from "./Pages/PageOldEvents";
import { PagePrincipal } from "./Pages/Intro/principal";
import { PageTeam } from "./Pages/Intro/team";
import { PageConcept } from "./Pages/Intro/concept";
import { PageSong } from "./Pages/Intro/song";
import { PageSchool } from "./Pages/Intro/school";
import { TemplateA } from "./template/TemplateA";
import { PageParentsMain } from "./Pages/ParentsMeeting/PageParentsMain";

export function Router() {
    return <Routes>
        <Route path="" element={React.createElement(HomePage)} />
        <Route path="/" element={React.createElement(HomePage)} />
        <Route path="/home" element={React.createElement(HomePage)} />
        <Route path="/testtest" element={React.createElement(Testpage)} />
        <Route path="/testtest/templateA" element={React.createElement(TemplateA)} />
        <Route path="/testtest/templateB" element={React.createElement(TemplateB)} />
        <Route path="/testtest/templateC" element={React.createElement(TemplateC)} />
        <Route path="/testtest/VideoGalleryTemplate" element={React.createElement(VideoGalleryTemplate)} />
        <Route path="/testtest/ImageGalleryTemplate" element={React.createElement(ImageGalleryTemplate)} />
        <Route path="/testtest/MaterialList" element={React.createElement(MaterialListTemplate)} />
        <Route path="/testtest/TempTestPage" element={React.createElement(TempTestPage)} />
            //---------------------------------------------------------------------
        <Route path="/parentCourse" element={React.createElement(PageParentCourse)} />
        <Route path="/volunteer" element={React.createElement(PageVolunteer)} />
        <Route path="/supportPlan" element={React.createElement(PageSupportPlan)} />
        <Route path="/tvStation" element={React.createElement(PageTvStation)} />
        <Route path="/oldEvents" element={React.createElement(PageOldEvents)} />
            // ----------------------------------------------------------------
        <Route path="/intro/principal" element={React.createElement(PagePrincipal)} />
        <Route path="/intro/team" element={React.createElement(PageTeam)} />
        <Route path="/intro/concept" element={React.createElement(PageConcept)} />
        <Route path="/intro/song" element={React.createElement(PageSong)} />
        <Route path="/intro/school" element={React.createElement(PageSchool)} />
            // ----------------------------------------------------------------
        <Route path="/enrollment/features" element={React.createElement(Pagefeatures)} />
        <Route path="/enrollment/structure" element={React.createElement(PageStructure)} />
        <Route path="/enrollment/application" element={React.createElement(PageApplication)} />
        <Route path="/enrollment/calendar" element={React.createElement(PageCalendar)} />
        <Route path="/enrollment/timetable" element={React.createElement(PageTimeTable)} />
            // ----------------------------------------------------------------
        <Route path="/courseTarget/EngObjectives" element={React.createElement(PageEngObjectives)} />
        <Route path="/courseTarget/EngBase" element={React.createElement(PageEngBase)} />
        <Route path="/courseTarget/EngConcern" element={React.createElement(PageEngConcern)} />
        <Route path="/courseTarget/EngDisplay" element={React.createElement(PageEngDisplay)} />
        <Route path="/courseTarget/EngDevelop" element={React.createElement(PageEngDevelopment)} />
        <Route path="/courseTarget/EngResources" element={React.createElement(PageEngResources)} />
            // ----------------------------------------------------------------
        <Route path="/courseTarget/ChiObjectives" element={React.createElement(PageChiObjectives)} />
        <Route path="/courseTarget/ChiBase" element={React.createElement(PageChiBase)} />
        <Route path="/courseTarget/ChiConcern" element={React.createElement(PageChiConcern)} />
        <Route path="/courseTarget/ChiResources" element={React.createElement(PageChiResources)} />
        <Route path="/courseTarget/ChiDevelopment" element={React.createElement(PageChiDevelopment)} />
        <Route path="/courseTarget/ChiSupport" element={React.createElement(PageChiSupport)} />
        <Route path="/courseTarget/ChiDisplay" element={React.createElement(PageChiDisplay)} />
            // ----------------------------------------------------------------
        <Route path="/courseTarget/MathsObjectives" element={React.createElement(PageMathsObjectives)} />
        <Route path="/courseTarget/MathsBase" element={React.createElement(PageMathsBase)} />
        <Route path="/courseTarget/MathsConcern" element={React.createElement(PageMathsConcern)} />
        <Route path="/courseTarget/MathsDevelopment" element={React.createElement(PageMathsDevelopment)} />
        <Route path="/courseTarget/MathsResources" element={React.createElement(PageMathsResources)} />
        <Route path="/courseTarget/MathsDisplay" element={React.createElement(PageMathsDisplay)} />
            // ----------------------------------------------------------------
        <Route path="/courseTarget/GsObjectives" element={React.createElement(PageGsObjectives)} />
        <Route path="/courseTarget/GsBase" element={React.createElement(PageGsBase)} />
        <Route path="/courseTarget/GsConcern" element={React.createElement(PageGsConcern)} />
        <Route path="/courseTarget/GsDevelopment" element={React.createElement(PageGsDevelopment)} />
        <Route path="/courseTarget/GsResources" element={React.createElement(PageGsResources)} />
        <Route path="/courseTarget/GsDisplay" element={React.createElement(PageGsDisplay)} />
            // ----------------------------------------------------------------
        <Route path="/courseTarget/PthObjectives" element={React.createElement(PagePthObjectives)} />
        <Route path="/courseTarget/PthBase" element={React.createElement(PagePthBase)} />
        <Route path="/courseTarget/PthConcern" element={React.createElement(PagePthConcern)} />
        <Route path="/courseTarget/PthDisplay" element={React.createElement(PagePthDisplay)} />
        <Route path="/courseTarget/PthDevelopment" element={React.createElement(PagePthDevelopment)} />
            // ----------------------------------------------------------------
        <Route path="/courseTarget/MusicObjectives" element={React.createElement(PageMusicObjectives)} />
        <Route path="/courseTarget/MusicBase" element={React.createElement(PageMusicBase)} />
        <Route path="/courseTarget/MusicConcern" element={React.createElement(PageMusicConcern)} />
        <Route path="/courseTarget/MusicDevelopment" element={React.createElement(PageMusicDevelopment)} />
        <Route path="/courseTarget/MusicDisplay" element={React.createElement(PageMusicDisplay)} />
            // ----------------------------------------------------------------
        <Route path="/courseTarget/PeObjectives" element={React.createElement(PagePeObjectives)} />
        <Route path="/courseTarget/PeBase" element={React.createElement(PagePeBase)} />
        <Route path="/courseTarget/PeConcern" element={React.createElement(PagePeConcern)} />
        <Route path="/courseTarget/PeDevelopment" element={React.createElement(PagePeDevelopment)} />
        <Route path="/courseTarget/PeDisplay" element={React.createElement(PagePeDisplay)} />
            // ----------------------------------------------------------------
        <Route path="/courseTarget/ItObjectives" element={React.createElement(PageItObjectives)} />
        <Route path="/courseTarget/ItBase" element={React.createElement(PageItBase)} />
        <Route path="/courseTarget/ItConcern" element={React.createElement(PageItConcern)} />
        <Route path="/courseTarget/ItDevelopment" element={React.createElement(PageItDevelopment)} />
            // ----------------------------------------------------------------
        <Route path="/courseTarget/UpIndex" element={React.createElement(PageUpIndex)} />
            // ----------------------------------------------------------------
        <Route path="/courseTarget/VaObjectives" element={React.createElement(PageVaObjectives)} />
        <Route path="/courseTarget/VaBase" element={React.createElement(PageVaBase)} />
        <Route path="/courseTarget/VaConcern" element={React.createElement(PageVaConcern)} />
        <Route path="/courseTarget/VaDisplay" element={React.createElement(PageVaDisplay)} />
        <Route path="/courseTarget/VaDevelopment" element={React.createElement(PageVaDevelopment)} />
            // ----------------------------------------------------------------
        <Route path="/courseTarget/TxDef" element={React.createElement(PageTxDef)} />
        <Route path="/courseTarget/TxDirection" element={React.createElement(PageTxDirection)} />
        <Route path="/courseTarget/TxMode" element={React.createElement(PageTxMode)} />
        <Route path="/courseTarget/TxStrategy" element={React.createElement(PageTxStrategy)} />
        <Route path="/courseTarget/TxItem" element={React.createElement(PageTxItem)} />
        <Route path="/courseTarget/TxRef" element={React.createElement(PageTxRef)} />
        <Route path="/courseTarget/TxHomework" element={React.createElement(PageTxHomework)} />
        // ----------------------------------------------------------------
        <Route path="/parentsMeeting" element={React.createElement(PageParentsMain)} />
        <Route path="*" element={React.createElement(HomePage)} />
    </Routes>
}