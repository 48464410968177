import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import React from 'react';
import { txMenuData } from '../../../data/SubjectMenu/txMenuData';

export function PageTxDef() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = ["反映學生在知識、技能及態度方面的成就及進展:", "幫助學生在學習過程中瞭解自己的長處和 短處,從而不斷作出改進;", "讓教師瞭解學生的學習進展,制訂適當教學方向,提高學與教的成效;", "讓家長瞭解子女的學習表現以幫助學生改善學習。"]


    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={12} sm={10} >
                <PageTitle title='學校評估政策 | 評估的定義' size='h3' subtitle={{ title: 'ASSESSMENT FOR LEARNING', size: 'h5' }} />
                <CourseTab data={txMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/tx/def/top.jpg" noImgPopup={true} />

                <Paragraph
                    data={[ <DottedList data={dottedListData} /> ]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/tx/def/def1.jpg" },],
                        position: "right",
                        imageHeight: "400px",
                    }}
                />

            </Grid>
        </Grid>
    )
}
