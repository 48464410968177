//cspell:ignore reduxjs
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GalleryImageData } from '../../data/GalleryImgDate';

export const getGalleryDataApi = createApi({
    reducerPath: "galleryData",
    baseQuery: fetchBaseQuery(
        { baseUrl: 'http://www.pkps.edu.hk/data/homePageGalleryData.json', mode: "cors", cache:"no-store" },
        
    ),
    endpoints: (builder) => ({
        getGalleryData: builder.query<Array<GalleryImageData>, ''>({
            query: (name) => name,
        }),
    }),
})

export const { useGetGalleryDataQuery } = getGalleryDataApi

//no-cors