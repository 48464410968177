import { Button, Container, Grid } from '@mui/material';
import { writeDB } from '../system/writeDB';
import { SimpleTest } from './SimpleTest';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export function Testpage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return <Container maxWidth="sm" sx={{ p: 5, minHeight: "550px"}}>
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={4} sm={4}>
                <Button variant="contained" onClick={() => navigate("templateA")}>Template A</Button>
            </Grid>
            <Grid item xs={6} sm={4}>
                <Button variant="contained" onClick={() => navigate("templateB")}>Template B</Button>
            </Grid>
            <Grid item xs={6} sm={4}>
                <Button variant="contained" onClick={() => navigate("templateC")}>Template C</Button>
            </Grid>
            <Grid item xs={6} sm={4}>
                <Button variant="contained" onClick={() => navigate("VideoGalleryTemplate")}>Video Gallery</Button>
            </Grid>
            <Grid item xs={6} sm={4}>
                <Button variant="contained" onClick={() => navigate("ImageGalleryTemplate")}>Image Gallery</Button>
            </Grid>
            <Grid item xs={6} sm={4}>
                <Button variant="contained" onClick={() => navigate("MaterialList")}>Material List</Button>
            </Grid>
            <Grid item xs={6} sm={4}>
                <Button variant="contained" onClick={() => navigate("TempTestPage")}>Test Page</Button>
            </Grid>
        </Grid>
    </Container>
}