import { Button, Divider, Fade, Grid, Grow, Paper, Skeleton, Slide, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DefaultSetting } from "../../defaultSetting";
import LiveTvIcon from '@mui/icons-material/LiveTv';
import React, { useEffect, useRef } from "react";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import FlagIcon from '@mui/icons-material/Flag';
import { ShortCut } from "../../../system/ShortCut";
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import { HomePageIconButton, HomePageIconButtonProps } from "./HomePageIconButton";
import FestivalIcon from '@mui/icons-material/Festival';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { setButtonGroupRender, setButtonGroupSecondRow } from "../../../system/Slicer/HomePageSlicer";
import { useDispatch, useSelector } from "react-redux";
import { selectComponentStore } from "../../../system/PageStore";
import { useNavigate } from 'react-router-dom';
 
export function HomePageButtonsGroup() {
    const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const componentStates = useSelector(selectComponentStore);

    const scrollHandler = (_: any) => {
        if (inputRef.current.getBoundingClientRect().top <= DefaultSetting.renderDistance) dispatch(setButtonGroupRender((true)))
    };
    useEffect(() => {
        window.addEventListener("scroll", scrollHandler, true);
        return () => window.removeEventListener("scroll", scrollHandler, true);
    }, []);

    let dataRowOne: Array<HomePageIconButtonProps> = [
        {
            content: "行政長官卓越教學獎",
            inverted: true, icon: <EmojiEventsIcon />, myColor: "#73c5ff",
            onClick: () => { ShortCut.windowOpen("http://www.pkps.edu.hk/newhtml/educationAwards/") }
        },
        {
            content: "培小電視台",
            inverted: true, icon: <LiveTvIcon />,
            onClick: () => { navigate("/tvStation") }
        },
        {
            content: 'eClass內聯網',
            inverted: true, icon: <LaptopChromebookIcon />, myColor: "#f5f",
            onClick: () => { ShortCut.windowOpen("https://eclass.pkps.edu.hk") }
        },
        {
            content: "廿載同行-培僑小學的故事II",
            inverted: true, icon: <MenuBookIcon />, myColor: "#ffa282",
            onClick: () => { ShortCut.windowOpen("http://www.pkps.edu.hk/poster/20210515/") }
        },
    ];

    let dataRowTwo: Array<HomePageIconButtonProps> = [
        {
            content: "生活及情意教育",
            icon: <Diversity1Icon />, myColor: "#73c5ff",
            onClick: () => { ShortCut.windowOpen("http://www.pkps.edu.hk/newhtml/life/index.html"); }
        },
        {
            content: "培小升旗隊",
            icon: <FlagIcon />,
            onClick: () => { ShortCut.windowOpen("http://www.pkps.edu.hk/newhtml/login/gs/flag.htm"); }
        },
        {
            content: "學校設施(VR導賞)",
            icon: <FestivalIcon />, myColor: "#f5f",
            onClick: () => { ShortCut.windowOpen("http://www.pkps.edu.hk/openday/2021/360/index.htm"); }
        },
        {
            content: "校園生活照",
            icon: <PhotoLibraryIcon />, myColor: "#ffa282",
            onClick: () => { ShortCut.windowOpen("http://www.pkps.edu.hk/newhtml/sclact.html") }
        },
    ];

    return (
        <Paper ref={inputRef} className="HomePageButtonsGroup" elevation={0} sx={{ m: 1, p: 1, backgroundColor: DefaultSetting.mainBackgroundColor, borderRadius: DefaultSetting.borderRadius }}>
            {
                !componentStates.HomePageComponentReducer.buttonGroupRender &&
                <Skeleton height={"300px"} />
            }
            {
                componentStates.HomePageComponentReducer.buttonGroupRender &&
                <Box>
                    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={0}>
                        {
                            dataRowOne.map((data, idx) => (
                                <Grow in timeout={1000 * idx} addEndListener={() => {setTimeout(() => dispatch(setButtonGroupSecondRow(true)), 500)}}>
                                    <Box>
                                        <HomePageIconButton {...data} />
                                    </Box>
                                </Grow>
                            ))
                        }
                    </Grid>

                    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={0}>
                        {
                            dataRowTwo.map((data, idx) => (
                                <Grow in={componentStates.HomePageComponentReducer.buttonGroupSecondRowIn} timeout={1000 * idx}>
                                    <Box>
                                        <HomePageIconButton {...data} />
                                    </Box>
                                </Grow>
                            ))
                        }
                    </Grid>
                </Box>
            }
        </Paper>
    )
}