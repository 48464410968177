import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import React from 'react';
import { itMenuData } from '../../../data/SubjectMenu/itMenuData';

export function PageItDevelopment() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = ["2009 香港教育大學舉辦之校本經驗分享研討會", "2009 香港東亞運動會研討會", "2009 香港中文大學運動科學及體育教育課程", "2010 香港中文大學課外活動課程", "2013 飛盤工作坊", "2014 小型網球工作坊", "2015 閃避球工作坊", "2016 基礎活動學習社群", "校內硏究課"]


    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='資訊科技 | 教師專業發展' size='h3' subtitle={{ title: 'PROFESSIONAL DEVELOPMENT OF TEACHERS', size: 'h5' }} />
                <CourseTab data={itMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/it/development_top.jpg" noImgPopup={true} />

                <Paragraph
                    data={["本校重視校內教師在資訊科技領域的專業發展，每學年定期為教師安排與資訊科技有關的工作坊，而校內任教資訊科技科的同事，亦會以一年一主題的方式，在資訊科技教學範疇，進行探究，以了解現時資訊科技教學趨勢，並適時融入學校課程。"]}
                />

            </Grid>
        </Grid>
    )
}
