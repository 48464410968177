import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import StarsIcon from '@mui/icons-material/Stars';
import { mathMenuData } from '../../../data/SubjectMenu/mathMenuData';

export function PageMathsDevelopment() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = [
        "開展「數學智多星」計畫(小一至小三)，推動同學透過努力服務，完成指定的「服務任務」，幫助同學提升數學能力，建立學生學習數學的自信和成就感。",
        "優化朋輩式學習輔導活動:「小四數學戰隊」與「小二乘數王計畫」的實施。",
    ];

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <PageTitle title='數學科 | 教師專業發展' size='h3' subtitle={{ title: 'PROFESSIONAL DEVELOPMENT OF TEACHERS', size: 'h5' }} />
            <Grid item xs={11} sm={10}>
                <CourseTab data={mathMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/maths/development/top.jpg" noImgPopup={true} />
            </Grid>

            <Grid item xs={11} sm={10} sx={{ p: 2 }}>
                <Typography variant="h5" align={"left"} sx={{ py: 2, color: "#F02602" }} ><ChangeHistoryTwoToneIcon /> 重視發展數學科研工作，提升教師教學能量。教師團隊樂於分享，積極建立教師學習社群，懷敬業樂群的心。</Typography>
                <Paragraph
                    data={["2023年 蔡碧卿老師﹑黃佩怡老師獲教聯會邀請主講「優秀教師課堂分享系列」ZOOM講座:以跨學科培養數學素養。","2021年黃清洪副校﹑文寳珊老師﹑張儀老師及蔡碧卿老師獲邀主講「行政長官卓越教學獎薈萃分享會」"]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/development/development1.jpg" },
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/development/development2.jpg" }
                        ],
                        position: "down",
                    }}
                />

                <Paragraph
                    data={["2014年數學科主任曾獲行政長官卓越教學教師協會邀請主講<知行合一>教師工作坊。"]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/development/development3.jpg" },
                        ],
                        position: "left",
                        imageHeight: "350px"
                    }}
                />

                <Paragraph 
                    title={<DottedList data={["專家指導，擴闊專業視野:"]} variant={<StarsIcon fontSize='small' />} />}
                    data={["23-24年度8月誠邀致力推廣價值觀教育的馬正源老師親臨。他曾獲香港教育大學頒授傑出生命教育教案設計獎﹑香港大學頒授國際傑出電子教學獎金獎及教育評議會執委。他為我校主講:「如何在數學教學中滲入中華文化元素。」講座，有助推動科組專業發展及對價值觀教育有更深入的認識。"]} 
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/development/1.jpg" }
                        ],
                        position: "right",
                        imageHeight: "350px"
                    }}
                />

                <Paragraph 
                    data={["22-23年度6月 誠邀教育局資訊科技教育卓越中心借調老師陳佩筠老師及江溢晉老師，到校主講「 使用應用程式促進網上學與教的電子評估( Pear Deck)」工作坊，這有助推動本校的電子學習。"]} 
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/development/2.jpg" }
                        ],
                        position: "left",
                        imageHeight: "350px"
                    }}
                />

                <Paragraph
                    data={[<DottedList data={[<DottedList data={["重視發展數學科研工作，提升教師教學能量。教師團隊樂於分享，積極建立教師學習社群，懷敬業樂群的心。"]} variant={<StarsIcon fontSize='small' />} />]} />]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/development/development4.jpg" },
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/development/development5.jpg" }
                        ],
                        position: "down",
                        imageHeight: "350px"
                    }}
                />

                <Paragraph title={<DottedList data={["22年8月下旬，誠邀番禺會所華仁小學李志豪老師﹑簡嘉茜老師"]} variant={<StarsIcon fontSize='small' />} />}
                    data={["為我校分享「善用表現分析系統，推動「促進學習的評估」和「作為學習的評估」教師專業發展講座。"]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/development/development6.jpg" },
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/development/development7.jpg" }
                        ],
                        position: "down",
                        imageHeight: "450px"
                    }}
                />

                <Paragraph
                    data={["校外方面，我們分別獲教育局及優質教育基金邀請，成為「專業發展學校計劃」及「QTN-S主題網絡計劃」的統籌學校，透過不同形式的協作，例如共同備課、同儕觀課、教學經驗分享、教學資源共享等互動活動，提升參與教師的專業能量，優化教學效能，建立同儕協作文化，使學校成為學習型組織。"]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/development/development8a.jpg" },
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/development/development8b.jpg" },
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/development/development8c.jpg" }
                        ],
                        position: "down",
                        imageHeight:"400px"
                    }}
                />

                <Paragraph title={<DottedList data={["積極參與內地及外地交流活動，增廣見聞，掌握教育新命題。"]} variant={<StarsIcon fontSize='small' />} />}
                    data={["積極參與STEM深圳考察交流團2017"]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/development/development9.jpg" },
                        ],
                        position: "right",
                    }}
                />

                <Paragraph
                    data={["吳佳筠校長帶領數學科科主任參加芬蘭交流團2018，了解跨學科學習的理念。"]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/development/development10.jpg" },
                        ],
                        position: "right",
                    }}
                />




            </Grid>




        </Grid>
    )
}
