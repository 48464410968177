import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { DefaultSetting, TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { useDispatch, useSelector } from 'react-redux';
import { selectComponentStore } from '../../../system/PageStore';
import { pthMenuData } from '../../../data/SubjectMenu/pthMenuData';

//import { DefaultSetting } from '../../../layoutPages/defaultSetting';

export function PagePthDisplay() {
    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='普通話 | 學生成果展示' size='h3' subtitle={{ title: 'STUDENT DISPLAYS OF WORK', size: 'h5' }} />
                <CourseTab data={pthMenuData}/>
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/pth/display/top.jpg" noImgPopup={true} />

                <Typography variant="h5" align={"left"} sx={{ py: 2, color: DefaultSetting.secondColor }} >為提高學生說普通話的興趣，增加學生運用普通話的機會，我校每年都會進行普通話大使的選拔活動。選拔出的普通話大使會負責協助學校接待內地來賓及推動校內普通話活動，從中，他們得以練習普通話，提高用普通話表達的自信和能力。</Typography>

                <Paragraph title={"普通話活動"}
                    data={["1.	普通話大使一幫一"]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/pth/display/display1a.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/pth/display/display1b.jpg" }],
                        position: "down",
                        imageHeight: "400px",
                    }} />

                <Paragraph
                    data={["2.	早會粵普對照"]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/pth/display/display2.jpg" },],
                        position: "right",
                        imageHeight: "400px",
                    }} />

                <Paragraph
                    data={["3. 普通話廣播站"]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/pth/display/display3a.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/pth/display/display3b.jpg" }],
                        position: "down",
                        imageHeight: "550px",
                    }} />

                <Paragraph
                    data={["4. 繞口令大賽"]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/pth/display/display4a.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/pth/display/display4b.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/pth/display/display4c.jpg" }],
                        position: "down",
                        imageHeight: "400px",
                    }} />

                <Paragraph
                    data={["5. 普通話遊戲日"]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/pth/display/display5a.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/pth/display/display5b.jpg" }],
                        position: "down",
                        imageHeight: "450px",
                    }} />

                <Paragraph title={"普通話比賽"}
                    data={["1.	全港中小學普通話演講比賽"]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/pth/display/display6a.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/pth/display/display6b.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/pth/display/display6c.jpg" },
                        ],
                        position: "down",
                        imageHeight: "400px",
                    }} />

                <MainCards height='600px' data={[
                    { src: "http://www.pkps.edu.hk/img/teachplan/pth/display/display6d.jpg", imgHeight: "600px", noImgPopup:true },
                    { src: "http://www.pkps.edu.hk/img/teachplan/pth/display/display6e.jpg", imgHeight: "600px", noImgPopup:true },
                    { src: "http://www.pkps.edu.hk/img/teachplan/pth/display/display6f.jpg", imgHeight: "600px", noImgPopup:true }
                ]} 
                
                />

                <Paragraph
                    data={["2.	香港學校朗誦節(普通話)"]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/pth/display/display7a.jpg" },],
                        position: "right",
                        imageHeight: "400px",
                    }} />


            </Grid>

        </Grid>
    )
}
