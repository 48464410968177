import { Masonry } from '@mui/lab';
import { ImageListItem } from '@mui/material';
export interface MasonryProps {
    imageSrcSet: Array<string>,
    pcCols?: number,
    mbCols?: number,
    spacing?:number
}

export function MasonryGallery(props: MasonryProps) {
    return (
        <Masonry columns={{ xs: props.mbCols ?? 2, sm: props.pcCols ?? 3 }} spacing={props.spacing ?? 1}>
            {props.imageSrcSet.map((item) => (
                <ImageListItem key={item}>
                    <img
                        src={item}
                        loading="lazy"
                    />
                </ImageListItem>
            ))}
        </Masonry>
    )
}