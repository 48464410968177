import { Box, Button, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../layoutPages/components/PageTitle';
import { HaveImage, Paragraph } from '../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../layoutPages/defaultSetting';
import { DottedList } from '../../layoutPages/containers/DottedList';
import { MainCard } from '../../layoutPages/components/MainCard';
import { CourseTab } from '../CourseTarget/menuTab';
import { MaterialList } from '../../layoutPages/components/MaterialList';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { introMenuData } from '../../data/intoMenuData';


export function PageTeam() {

    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >

                <br></br>
                <CourseTab data={introMenuData} />

                <PageTitle title='辦學團體 | 學校簡介' size='h3' sx={{ m: 5 }} />

                <Grid container direction="row" justifyContent="start" alignItems="top" spacing={2} >
                    <Grid item xs={5} sm={1.5}>
                        <Button variant="outlined" size="large" onClick={() => window.open("http://www.pkms.edu.hk/", '_blank', 'noopener,noreferrer')}>培僑中學</Button>
                    </Grid>
                    <Grid item xs={5} sm={1.5}>
                        <Button color="error" size="large" variant="outlined" onClick={() => window.open("http://www.puikiucollege.edu.hk/", '_blank', 'noopener,noreferrer')}>培僑書院</Button>
                    </Grid>
                </Grid>
                <br></br>
                <Paragraph
                    instantAppear
                    title="培僑教育機構"
                    data={["本辦學團體具有六十多年的豐富辦學經驗，曾開辦中學、小學、幼稚園及夜校。我們秉持教育興邦的信念，致力使受教育者在德、智、體、群、美各方面得到全面發展，六十多年如一日默默耕耘，為香港社會培育了不少人才。本辦學團體於1986年註冊為非牟利團體，1991年註冊為慈善團體。現任董事長為前香港特別行政區全國人大代表、大紫荊勳賢吳康民先生。",
                        "目前由我們主辦的培僑中學為一所設備完善，師資優良的直接資助中學，擁有泳池、室內運動場及學生宿舍等設備。學生在思想品德、學業成績及體藝方面的優良表現，為社會人士所肯定。二零零零年，我們獲教育署批准，在港島小西灣開辦了一所津貼小學 ---- 培僑小學。在短短的時間內，「培小」清新的辦學理念已得到家長的支持和讚賞。由於我們的辦學成績卓著，政府又再批准我們於沙田大圍開辦直資小學及直資中學各一所，兩校將以一條龍模式辦學。新校舍設計風格獨特，設有特大圖書館、各類演講及表演場地，已於於二零零五年落成使用。"
                    ]}
                />
                    


            </Grid>
        </Grid>
    )
}
