import { Box, Button, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../layoutPages/components/PageTitle';
import { HaveImage, Paragraph } from '../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../layoutPages/defaultSetting';
import { DottedList } from '../../layoutPages/containers/DottedList';
import { MainCard } from '../../layoutPages/components/MainCard';
import { CourseTab } from '../CourseTarget/menuTab';
import { MaterialList } from '../../layoutPages/components/MaterialList';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { introMenuData } from '../../data/intoMenuData';
import { useDispatch, useSelector } from 'react-redux';
import { homePageSliderMove } from '../../system/Slicer/HomePageSlicer';
import { selectComponentStore } from '../../system/PageStore';
import { ContentSlider } from '../../layoutPages/containers/ContentSlider';


export function PageSchool() {

    const isPc = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch();
    const componentStates = useSelector(selectComponentStore);

    let contentData = [
        { url: 'http://www.pkps.edu.hk/img/website/intro/1.jpg' },
        { url: 'http://www.pkps.edu.hk/img/website/intro/2.jpg' },
        { url: 'http://www.pkps.edu.hk/img/website/intro/3.jpg' },
        { url: 'http://www.pkps.edu.hk/img/website/intro/4.jpg' },
        { url: 'http://www.pkps.edu.hk/img/website/intro/5.jpg' },
        { url: 'http://www.pkps.edu.hk/img/website/intro/6.jpg' },
        { url: 'http://www.pkps.edu.hk/img/website/intro/7.jpg' },
        { url: 'http://www.pkps.edu.hk/img/website/intro/8.jpg' },
        { url: 'http://www.pkps.edu.hk/img/website/intro/9.jpg' },

    ];

    const setSlider = (index: number, lastIndex?: number) => {
        let nextIndex = index;
        if (index == contentData.length) nextIndex = 0;
        if (index <= -1) nextIndex = contentData.length - 1;
        dispatch(homePageSliderMove(nextIndex))
        return {}
    }


    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >

                <br></br>
                <CourseTab data={introMenuData} />

                <PageTitle title='校舍介紹 | 學校簡介' size='h3' sx={{ m: 5 }} />

                <Grid container direction="row" justifyContent="start" alignItems="top" spacing={2} >
                    <Grid item xs={12} sm={12}>
                        <Button variant="outlined" color="error" size="large" onClick={() => window.open("http://www.pkps.edu.hk/openday/2021/360/index.htm", '_blank', 'noopener,noreferrer')}>學校設施:(VR導賞)</Button>
                    </Grid>

                </Grid>
                <br></br>
                <Paragraph
                    instantAppear
                    data={["培僑小學是政府津貼的全日制小學，座落於香港小西灣，於2000年9月開校。校舍佔地48,588平方呎( 4,514平方米)，為全港首批 2000 年設計的千禧型小學。全校課室、 禮堂及特別室均設有空調。除 30 個標準課室外，還有 8 個特別室、1 個中央 圖書館、3 個操場及1 個禮堂。全校設光纖網絡。"]}
                />

                <ContentSlider height="500px" sx={{ bottom: "-30px" }} data={contentData} event={setSlider} />
                    


            </Grid>
        </Grid>
    )
}

