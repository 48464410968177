import { Card, Grid, Typography, Button, CardActionArea, CardMedia } from '@mui/material';

import { ParagraphProps, ParagraphOnlyText } from './Paragraph';
import { MainCard } from '../../components/MainCard';

export function ParagraphWithImageLeft(props: ParagraphProps) {
    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={1} sx={{ mt: 2 }}>

            <Grid item xs={11} sm={6} sx={{pr : 2}}>
                <MainCard src={props.haveImage!.images![0].src} imgHeight={props.haveImage?.imageHeight} {...props.haveImage?.mainCardProps}/>
            </Grid>

            <Grid item xs={11} sm={6}>
                <ParagraphOnlyText {...props} />
            </Grid>
        </Grid>
    );
}