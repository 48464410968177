import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import React from 'react';
import { peMenuData } from '../../../data/SubjectMenu/peMenuData';
import { MainCards } from '../../../layoutPages/containers/MainCards';

export function PagePeConcern() {

    const isPc = useMediaQuery('(min-width:600px)');
    let dottedListData = ["東區東區校際游泳比賽", "東區校際田徑比賽", "東區校際足球比賽", "東區校際籃球比賽", "東區校際排球比賽", "東區校際羽毛球比賽", "東區校際乒乓球比賽", "舞蹈比賽", "跆拳道比賽"]

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='體育科 | 年度關注事項' size='h3' subtitle={{ title: 'CURRENT MAIN CONCERN', size: 'h5' }} />
                <CourseTab data={peMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/pe/concern/C1.jpg" noImgPopup={true} />

                <Paragraph title="校內:"
                    data={[
                        <DottedList data={
                            ["舉辦班際及個人比賽，推動學生參與體會活動", "透過網上平台，運用電子科技，豐富學生學習經歷"]} />
                    ]}

                />

                <Paragraph title="校外:"
                    data={["鼓勵學生參加由學界體育聯會舉辦的各種比賽，讓學生有實踐訓練上所學的知識，為校爭光。"]}

                />

                <DottedList data={["鼓勵學生參加由學界體育聯會舉辦的各種比賽，讓學生有實踐訓練上所學的知識，為校爭光。"]} />

                <MainCards height='350px' data={[
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/pe/concern/C2.jpg", imgHeight:"350px"
                    },
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/pe/concern/C3.jpg", imgHeight:"350px"
                    },
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/pe/concern/C4.jpg", imgHeight:"350px"
                    },
                ]} />

            </Grid>
        </Grid>
    )
}
