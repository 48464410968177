import { Box, Grid, Radio, Theme, useMediaQuery } from "@mui/material";
import { grey } from '@mui/material/colors';
import { SxProps } from "@mui/system";

interface DottedProps {
    data: Array<any>;
    onclick: Function;
    nowIndex: number;
    boxSx?: SxProps;
}

export function DottedControl(props: DottedProps) {
    return (
        <Box sx={{ backgroundColor: "rgba(0,60,200,0.1)", width:"100%",alignItems:"center",borderRadius: "8px", ...props.boxSx }}>
            <Grid container direction="row" justifyContent="center" alignItems="center">
                {
                    props.data.map((_, i) => (
                        <Radio sx={{ color: grey[100], width: "3.5vw" }} size="small" checked={props.nowIndex == i} onClick={() => { props.onclick(i) }} />
                    ))
                }
            </Grid>
        </Box>
    )
}