import { Box, CardMedia, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../layoutPages/components/PageTitle';
import { HaveImage, Paragraph } from '../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../layoutPages/defaultSetting';
import { DottedList } from '../../layoutPages/containers/DottedList';
import { MainCard } from '../../layoutPages/components/MainCard';
import { CourseTab } from '../CourseTarget/menuTab';
import { MaterialList } from '../../layoutPages/components/MaterialList';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { introMenuData } from '../../data/intoMenuData';


export function PageSong() {

    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >

                <br></br>
                <CourseTab data={introMenuData} />

                <PageTitle title='校歌、校徽及校服 | 學校簡介' size='h3' sx={{ m: 5 }} />

                <Typography variant="body1" sx={{ fontSize: 35, my: 2 }} > 校歌:</Typography>

                <CardMedia component="iframe" height={"600px"}  src='https://www.youtube.com/embed/WCc8d7OQwxc' />

                <MainCard src="http://www.pkps.edu.hk/newhtml/school/schsong.jpg" sx={{my:3,maxWidth: 800}} noImgPopup />

                <Typography variant="body1" sx={{ fontSize: 35, my: 2 }} > 校徽:</Typography>

                <MainCard src="http://www.pkps.edu.hk/img/website/intro/badge.jpg" sx={{maxWidth: 300 }} noImgPopup/>

                <Typography variant="body1" sx={{ fontSize: 35, my: 2 }} > 校服:</Typography>
                <MainCard src="http://www.pkps.edu.hk/images/unif1.jpg" sx={{mb:3, maxWidth: 800 }} noImgPopup/>
                <MainCard src="http://www.pkps.edu.hk/images/unif3.jpg" sx={{mb:3, maxWidth: 800 }} noImgPopup/>
            </Grid>
        </Grid>
    )
}
