import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { MainCardProps } from '../../../layoutPages/components/MainCard';
import { chiMenuData } from '../../../data/SubjectMenu/chiMenuData';

export function PageChiResources() {

    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='中國語文 | 網上學習資源' size='h3' subtitle={{ title: 'ONLINE LEARNING RESOURCES', size: 'h5' }} />
                <CourseTab data={chiMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/chi/resources/top.jpg" noImgPopup={true} />
            </Grid>

            <Grid item xs={11} sm={8}>
            <MainCards data={[
                    {
                        caption: "1.學好中國語文",
                        src: "http://www.pkps.edu.hk/img/teachplan/chi/resources/resources1.png",
                        onClick: () => window.open("https://chin-pri.pearson.com.hk/", '_blank', 'noopener,noreferrer')
                    },
                    {
                        caption: "2.智愛中文平台",
                        src: "http://www.pkps.edu.hk/img/teachplan/chi/resources/resources2.png",
                        onClick: () => window.open("https://chinese3.i-learner.com.hk/", '_blank', 'noopener,noreferrer')
                    },
                ]} />

<MainCards data={[
                    {
                        caption: "3.中文百達通",
                        src: "http://www.pkps.edu.hk/img/teachplan/chi/resources/resources3.png",
                        onClick: () => window.open("https://www.chinese100.hk/", '_blank', 'noopener,noreferrer')
                    },
                    {
                        caption: "4.中華經典名句（教育局）",
                        src: "http://www.pkps.edu.hk/img/teachplan/chi/resources/resources5.png",
                        onClick: () => window.open("https://www.edb.gov.hk/tc/curriculum-development/kla/chi-edu/chinese-culture/chi-culture-main.html", '_blank', 'noopener,noreferrer')
                    },
                ]} />
            
            <MainCard sx={{mt:3}} src="http://www.pkps.edu.hk/img/teachplan/chi/resources/resources4.png" caption="5.中國語文課程：文言經典建議篇章（教育局）" 
            onClick= {() => window.open("https://www.edb.gov.hk/tc/curriculum-development/kla/chi-edu/recommended-passages.html", '_blank', 'noopener,noreferrer')} />

            
<MainCards data={[
                    {
                        caption: "6.教育局教育多媒體（ETV）",
                        src: "http://www.pkps.edu.hk/img/teachplan/chi/resources/resources6.png",
                        onClick: () => window.open("https://emm.edcity.hk/", '_blank', 'noopener,noreferrer')
                    },
                    {
                        caption: "7.香港小學學習字詞表",
                        src: "http://www.pkps.edu.hk/img/teachplan/chi/resources/resources7.png",
                        onClick: () => window.open("https://www.edbchinese.hk/lexlist_ch/", '_blank', 'noopener,noreferrer')
                    },
                ]} />

<MainCards data={[
                    {
                        caption: "8.教育部重編國語辭典修訂本（台灣）",
                        src: "http://www.pkps.edu.hk/img/teachplan/chi/resources/resources8.png",
                        onClick: () => window.open("https://dict.revised.moe.edu.tw/?la=0&powerMode=0", '_blank', 'noopener,noreferrer')
                    },
                    {
                        caption: "9.中國文化研究院",
                        src: "http://www.pkps.edu.hk/img/teachplan/chi/resources/resources9.png",
                        onClick: () => window.open("https://chiculture.org.hk/", '_blank', 'noopener,noreferrer')
                    },
                ]} />

            </Grid>






        </Grid>




    );
}