import { SxProps, Grid, Button, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, Skeleton, Slider, Grow } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { JsxElement } from "typescript"
import { selectComponentStore } from '../../system/PageStore';
import { ShortCut } from '../../system/ShortCut';
import { setMaterialGroupRender } from '../../system/Slicer/HomePageSlicer';
import { DefaultSetting } from '../defaultSetting';

export interface MaterialWithLink {
    name: string,
    src: string,
    useNavigate?: boolean,
    icon?: React.ReactNode
}

export interface MaterialListProps {
    data: Array<MaterialWithLink>,
    sx?: SxProps;
}

export function MaterialList(props: MaterialListProps) {
    const navigate = useNavigate();

    return (
        <Box sx={{ backgroundColor: DefaultSetting.greyBg }}>

            <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2} sx={{ ...props.sx }}>
                {
                    props.data.map((item) => (
                        <Grid item xs={11.5} sm={11.5}>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemButton
                                        alignItems="center"
                                        divider
                                        sx={{ borderRadius: DefaultSetting.borderRadius, backgroundColor: DefaultSetting.darkGreyBg, ...DefaultSetting.hoverZoomAni }}
                                        onClick={() => { item.useNavigate ? navigate(item.src) : ShortCut.windowOpen(item.src) }}>
                                        {
                                            item.icon &&
                                            <ListItemIcon>
                                                {item.icon ?? "200px"}
                                            </ListItemIcon>
                                        }
                                        <ListItemText primary={item.name} />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
    );
} 