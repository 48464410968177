import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import StarsIcon from '@mui/icons-material/Stars';
import { mathMenuData } from '../../../data/SubjectMenu/mathMenuData';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { MaterialList } from '../../../layoutPages/components/MaterialList';
import BookIcon from '@mui/icons-material/Book';

export function PageMathsResources() {

    const isPc = useMediaQuery('(min-width:600px)');

    let data = [
        { name: "數學科常用單位換算表(小二)", src: "http://www.pkps.edu.hk/09-10%20teachplan/final/pdf/ms/2.doc", icon: <TextSnippetIcon sx={{ color: "blue" }} /> },
        { name: "數學科常用單位換算表(小三)", src: "http://www.pkps.edu.hk/09-10%20teachplan/final/pdf/ms/3.doc", icon: <TextSnippetIcon sx={{ color: "blue" }} /> },
        { name: "數學科常用單位換算表(小四)", src: "http://www.pkps.edu.hk/09-10%20teachplan/final/pdf/ms/4.doc", icon: <TextSnippetIcon sx={{ color: "blue" }} /> },
        { name: "數學科常用單位換算表(小五)", src: "http://www.pkps.edu.hk/09-10%20teachplan/final/pdf/ms/5.doc", icon: <TextSnippetIcon sx={{ color: "blue" }} /> },
        { name: "數學科常用單位換算表(小六)", src: "http://www.pkps.edu.hk/09-10%20teachplan/final/pdf/ms/6.doc", icon: <TextSnippetIcon sx={{ color: "blue" }} /> },

    ];


    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <PageTitle title='數學科 | 網上學習資源' size='h3' subtitle={{ title: 'ONLINE LEARNING RESOURCES', size: 'h5' }} />
            <Grid item xs={11} sm={10}>
                <CourseTab data={mathMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/maths/resources/top.jpg" imgHeight='600px' noImgPopup={true} />
            </Grid>



            <Grid item xs={11} sm={10} sx={{ p: 2 }}>

                <Paragraph title="A. 數學「常用單位換算表」及小錦囊" titleSize='h5'
                    data={[<MaterialList data={data} />]}
                />
                <br></br> <br></br>
                <Paragraph title="B. 自學影片(數學科)匯集" titleSize='h5'
                    data={[
                        <MainCard src="http://www.pkps.edu.hk/img/teachplan/maths/resources/res1.jpg"
                            noImgPopup={true} sx={{ width: "60%" }}
                            onClick={() => window.open("http://www.pkps.edu.hk/math_video/", '_blank', 'noopener,noreferrer')} />
                    ]}
                />
                <br></br> <br></br>
                <Paragraph title="C. 自主學習數學科圖書推介書目" titleSize='h5'
                    data={[<MaterialList data={[{ name: "培小圖書館藏:數學科自學圖書書目", src: "http://www.pkps.edu.hk/09-10%20teachplan/final/excel/ms/book.XLS", icon: <BookIcon sx={{ color: "purple" }} /> },]} />]}
                />
                <br></br> <br></br>
                <Paragraph title="D. 數學奇兵大搜查" titleSize='h5'
                    data={[
                        <MainCard src="http://www.pkps.edu.hk/img/teachplan/maths/resources/res2.jpg"
                            noImgPopup={true} sx={{ width: "60%" }}
                            onClick={() => window.open("http://www.pkps.edu.hk/09-10%20teachplan/final/MSBK.html", '_blank', 'noopener,noreferrer')} />
                    ]}
                />
                <br></br> <br></br>

                <Paragraph title="E. 現代教育資源庫（學生版）" titleSize='h5'
                    data={[
                        <MainCard src="http://www.pkps.edu.hk/img/teachplan/maths/resources/res3.jpg"
                            noImgPopup={true} sx={{ width: "60%" }}
                            onClick={() => window.open("https://www.mers.hk/login/gateway/pmath_st.php", '_blank', 'noopener,noreferrer')} />
                    ]}
                />

            </Grid>




        </Grid>
    )
}
