import { SxProps, Typography } from '@mui/material';
import { yyyymmdd } from '../../system/Features/YYYYMMDD';
import { DefaultSetting } from '../defaultSetting';
interface TitleWithDateProps {
    title: string;
    date: string;
    sx?: SxProps;
    align?: "left" | "center";
}

export function TitleWithDate(props: TitleWithDateProps) {
    return (<>
        <Typography variant='h6' align={props.align ?? 'left'} sx={{ color: DefaultSetting.secondColor, textTransform: "none", ...props.sx }}>
            {props.title}
        </Typography>
        <Typography variant='body2' align={props.align ?? 'left'} sx={{ color: "#AAAAAA" }}>
            {yyyymmdd(new Date(props.date))}
        </Typography>
    </>)
}