import { ImageData } from "../../../class/ImageData";
import { Card, Grid, Typography, Button, CardActionArea, CardMedia, SxProps, Skeleton, Fade, Grow } from '@mui/material';
import { ParagraphWithImageDown } from './ParagraphWithImageDown';
import { ParagraphWithImageTop } from './ParagraphWithImageTop';
import { ParagraphWithImageLeft } from './ParagraphWithImgLeft';
import { ParagraphWithImageRight } from './ParagraphWithImgRight';
import { Box } from "@mui/system";
import { MainCardProps } from "../../components/MainCard";
import { DefaultSetting } from "../../defaultSetting";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setScrollTop } from "../../../system/Slicer/CommonSlicer";

export interface HaveImage {
    images: Array<ImageData>;
    mainCardProps?: MainCardProps;
    imageHeight?: string;
    position?: "left" | "right" | "top" | "down";
}

export interface ParagraphProps {
    title?: string | React.ReactNode;
    titleSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2';
    data: Array<string | React.ReactNode>;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    haveImage?: HaveImage;
    spacing?: number;
    sx?: SxProps;
    instantAppear?: boolean;
}

export function Paragraph(props: ParagraphProps) {
    const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    let appear = props.instantAppear ?? inputRef?.current?.getBoundingClientRect()?.top <= DefaultSetting.renderDistance;
    useEffect(() => {
        appear = props.instantAppear ?? inputRef.current.getBoundingClientRect().top <= DefaultSetting.renderDistance;
    }, []);
    const dispatch = useDispatch();

    const scrollHandler = (_: any) => {
        dispatch(setScrollTop(document.body.getBoundingClientRect().top));
    };
    useEffect(() => {
        window.addEventListener("scroll", scrollHandler, true);
        return () => window.removeEventListener("scroll", scrollHandler, true);
    }, []);

    return (<Box ref={inputRef}>
        {appear ?
            <Grow in timeout={1000}>
                <Box>
                    {
                        !props.haveImage &&
                        <Grid item xs={11} sm={12}>
                            <ParagraphOnlyText {...props} />
                        </Grid>
                    }
                    {
                        props.haveImage?.position == "top" &&
                        <ParagraphWithImageTop {...props} />
                    }
                    {
                        props.haveImage?.position == "down" &&
                        <ParagraphWithImageDown {...props} />
                    }
                    {
                        props.haveImage?.position == "left" &&
                        <ParagraphWithImageLeft {...props} />
                    }
                    {
                        props.haveImage?.position == "right" &&
                        <ParagraphWithImageRight {...props} />
                    }
                </Box>
            </Grow>
            :
            <Skeleton height="250px" />
        }
    </Box>);
}

export function ParagraphOnlyText(props: ParagraphProps) {
    return (<>
        {
            typeof props.title === "string" ?
                <Typography variant={props.titleSize ?? "h4"} align={props.align ?? "left"} sx={{ pb: 1, color: DefaultSetting.secondColor }} >{props.title}</Typography>
                :
                props.title
        }
        {props.data.map((item, index) => (
            typeof item === "string" ?
                <Typography variant="body1" align={props.align ?? "left"} sx={{ fontSize: 20, pb: 2, ...props.sx }} >{item}</Typography>
                :
                item
        ))}
    </>
    );
}