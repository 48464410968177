//cspell: ignore SwipeableDrawer
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer, Paper, Backdrop, Menu, MenuItem } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { selectComponentStore } from '../../system/PageStore';
import { MenuButtonData, MenuButtonLinkType } from "../../system/Classes/MenuButtonData";
import { useNavigate } from 'react-router-dom';
import { subjectMenuClick, subjectMenuClose, switchMenuOpen } from "../../system/Slicer/HomePageSlicer";
import { blue, grey, red } from '@mui/material/colors';
import { subjectMenuData } from '../../data/MenuData';
import { ShortCut } from '../../system/ShortCut';
import { setSubjectMenuTabIdx } from '../../system/Slicer/CommonSlicer';

interface MainDrawerProps {
    data: Array<MenuButtonData>;
}

export function MainDrawer(props: MainDrawerProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const componentStates = useSelector(selectComponentStore);

    const handleOpenSubMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        dispatch(subjectMenuClick(event));
    }
    const handleCloseSubMenu = () => {
        dispatch(setSubjectMenuTabIdx(0));
        dispatch(subjectMenuClose());
    };
    const open: boolean = componentStates.HomePageComponentReducer.isDrawerOpened;

    return <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={() => { dispatch(switchMenuOpen()) }}
        onOpen={() => { }}
        PaperProps={{ sx: { backgroundColor: blue[700] } }}
    >
        <List sx={{ backgroundColor: blue[700], color: grey[200], p: 3 }}>
            {props.data.map((button) => (
                <ListItem key={button.display} disablePadding>
                    <ListItemButton onClick={(event) => {
                        if (button.type == MenuButtonLinkType.SubMenu) {
                            handleOpenSubMenu(event as unknown as React.MouseEvent<HTMLButtonElement>);
                        } else if (button.type == MenuButtonLinkType.WindowOpen) {
                            ShortCut.windowOpen(button.path);
                            dispatch(switchMenuOpen());
                        } else {
                            navigate(button.path);
                            dispatch(switchMenuOpen());
                        }

                    }}>
                        <ListItemText primary={button.display} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>


        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={componentStates.HomePageComponentReducer.subjectMenuOpen}
            onClick={handleCloseSubMenu}
        >
            <Menu
                id="subject-menu"
                anchorEl={componentStates.HomePageComponentReducer.subjectMenuAnchorEl}
                open={componentStates.HomePageComponentReducer.subjectMenuOpen}
                onClose={handleCloseSubMenu}
                onMouseLeave={handleCloseSubMenu}
            >
                {
                    subjectMenuData.map((data) => (
                        <MenuItem sx={{ pl: 3, pr: 3, pb: 2 }} onClick={() => { navigate(data.path); dispatch(switchMenuOpen()); handleCloseSubMenu(); }} >
                            {data.display}
                        </MenuItem>
                    ))
                }
            </Menu>
        </Backdrop>
    </SwipeableDrawer >
}