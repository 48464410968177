import { MenuButtonData } from '../../system/Classes/MenuButtonData';

export const vaMenuData: Array<MenuButtonData> = [
    {display:"課程目標", path:"/courseTarget/VaObjectives"},
    {display:"校本課程特色", path:"/courseTarget/VaBase"},
    {display:"年度重點項目", path:"/courseTarget/VaConcern"},
    {display:"學生成果展示", path:"/courseTarget/VaDisplay"},
];






