import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import StarsIcon from '@mui/icons-material/Stars';
import { mathMenuData } from '../../../data/SubjectMenu/mathMenuData';

export function PageMathsConcern() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = [
        "透過服務學習，與社區聯繫。讓學生有機會到小西灣老人之家探訪，與長者一起玩經典桌遊Rummikub。學生在過程中應用所學，同時建立關愛及同理心。",
        "透過「數學智多星計劃」及「數學科朋輩輔導活動」，持續推動同儕互助互學，共同提升數學的能力及建立學生的承擔感及責任感。",
    ];

    let dottedListData2 = [
        "優化數學科小一數學繪本教學，拓展校本繪本教學策略。",
        "選取合適的課題，透過課堂教學或課後延伸滲入中國數學歷史的知識，擴闊「家國情懷」之情。",
        "透過「國際數學日」(3月14日)，與圖書館合作。善用多元閱讀資源，舉辦主題式閱讀活動，以推動同學對中國數學家與西方數學歷史文化的認識，從中擴闊視野及享受閱讀的樂趣。"
    ]

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <PageTitle title='數學科 | 年度重點項目' size='h3' subtitle={{ title: 'CURRENT MAIN CONCERN', size: 'h5' }} />
            <Grid item xs={11} sm={10}>
                <CourseTab data={mathMenuData}/>
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/maths/concern/top.jpg" noImgPopup={true} />
            </Grid>

            <Grid item xs={11} sm={10} sx={{ p: 2 }}>

            <Typography variant="h4" align={"left"} sx={{ pt: 2, color: "#F02602" }} >1.配合學校關注事項，完善數學科「素養導向」課程；加強價值觀教育及豐富學生多元學習經歷。</Typography>
            <Paragraph title="" titleSize='h5'
                    data={[
                        <DottedList data={["落實已有的跨學科學習課程(如AR閱讀「方」與中文科跨科合作)，讓學生有更豐富的跨學科學習與應用。"]} variant={<StarsIcon fontSize='small' />} />
                    ]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/concern/1.png" },
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/concern/2.jpg" },
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/concern/3.jpg" },
                        ],
                        
                        position: "down",
                    }}
                />

            <Paragraph title="" titleSize='h5'
                    data={[
                        <DottedList data={["善用分享平台PADLET及舉行作品展示日，提供更多機會讓學生展示成果與反思，展現學生的數學素養。","探索與不同科組合作教學的可能性，發展跨學科課程，提昇學生綜合應用所學的能力。"]} variant={<StarsIcon fontSize='small' />} />
                    ]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/concern/4.jpg" },
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/concern/5.png" }
                        ],    
                        position: "down",
                        imageHeight:"450px",
                    }}
                    sx={{mb:3}}
                />

                <Paragraph title="#創設多元學習經歷，讓學生綜合應用所學，並建立正確價值觀與態度。" titleSize='h5'
                    data={[
                        <DottedList data={dottedListData} variant={<StarsIcon fontSize='small' />} />
                    ]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/concern/concern1.jpg" }
                        ],

                        position: "down",
                    }}
                />

                <Paragraph title="" titleSize='h5'
                    data={[
                        <DottedList data={["透過3月14 日「國際數學日」（Pi Day），以中國數學遊戲及圖書等閱讀材料，認識中國文化及感受學習數學的趣味。"]} variant={<StarsIcon fontSize='small' />} />
                    ]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/concern/6.jpg" },
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/concern/7.png" }
                        ],

                        position: "down",
                        imageHeight:"550px",
                    }}
                />

                <Paragraph title="" titleSize='h5'
                    data={[
                        <DottedList data={["各級選取合適的單元課題，透過課後延伸滲透中國數學歷史或文化相關的內容，欣賞中國古人的智慧。"]} variant={<StarsIcon fontSize='small' />} />
                    ]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/concern/8.jpg" },
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/concern/9.jpg" }
                        ],

                        position: "down",
                        imageHeight:"550px",
                    }}
                />


                
               <Typography variant="h4" align={"left"} sx={{ pt: 2, color: "#F02602" }} >2. 持續優化數學科校本閱讀課程及高階思維課程。</Typography>

            </Grid>




        </Grid>
    )
}
