import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { engMenuData } from '../../../data/SubjectMenu/engMenuData';

export function PageEngObjectives() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = [
        "1. High proficiency in English Language including the four skills (Reading, Writing, Listening & Speaking) for study and leisure in the English medium",
        "2. Holistic personal and intellectual development, and social skills;",
        "3. Cultural understanding; and",
        "4. Global competitiveness."
    ];

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <PageTitle title='課程目標 Curriculum Objectives' size='h3' />
            <Grid item xs={11} sm={10}>
                <CourseTab data={engMenuData}/>
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/eng/objectives/top2.jpg" noImgPopup/>
            </Grid>

            <Grid item xs={11} sm={10} sx={{ p: 2 }}>
                <Typography variant="h6" align={"left"} >
                    We aim at providing students with a wide range of learning experiences to equip them with:
                </Typography>
            </Grid>

            <Grid item xs={11} sm={10} sx={{ p: 2 }}>
                <Paragraph title =""
                            data={dottedListData}
                            haveImage={{
                                images:[
                                    { src: "http://www.pkps.edu.hk/img/teachplan/eng/objectives/point1.jpg" }
                                ],
                                position:"right"
                            }}
                        />
            </Grid>

        </Grid>
    );
}