import { MenuButtonData } from '../../system/Classes/MenuButtonData';

export const pthMenuData: Array<MenuButtonData> = [
    {display:"課程目標", path:"/courseTarget/PthObjectives"},
    {display:"校本課程特色", path:"/courseTarget/PthBase"},
    {display:"年度重點項目", path:"/courseTarget/PthConcern"},
    {display:"學生成果展示", path:"/courseTarget/PthDisplay"},
    {display:"教師專業發展", path:"/courseTarget/PthDevelopment"},
];






