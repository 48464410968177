import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { chiMenuData } from '../../../data/SubjectMenu/chiMenuData';

export function PageChiConcern() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = [
        "發展及優化多元評估課程；",
        "發展校本跨課程閱讀；",
        "善用電子軟件，提供更多機會給學生分享學習過程、成果或反思，展現學生「新世代素養」。",
    ];

    let dottedListPoint2 = [
        "教育局建議的小學階段「古詩文建議篇章」融入教學；",
        "豐富「讀經誦典」課程的教學內容，加強對學生的文化熏陶。",
    ];


    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10}>
                <PageTitle title='中國語文 | 年度重點項目' size='h3' subtitle={{ title: 'CURRENT MAIN CONCERN', size: 'h4' }} />
                <CourseTab data={chiMenuData} />
                <MainCard imgHeight="450px" src="http://www.pkps.edu.hk/img/teachplan/chi/concern/concern2.jpg" noImgPopup={true} />

                <Paragraph title ="推展中文科「素養導向」課程，提升學生語文素養。" titleSize='h5'
                            data={[
                                <DottedList variant="number" data={dottedListData} />
                            ]
                            }
                            haveImage={{
                                images:[{ src:"http://www.pkps.edu.hk/img/teachplan/chi/concern/concern1.jpg" }],
                                position:"right",
                                imageHeight: "400px",
                            }}
                        />

                <Paragraph title = "優化與國民身份認同相關的課程，培養更具國家觀念的下一代。" titleSize='h5'
                            data={[
                                <DottedList variant="number" data={dottedListPoint2} />
                            ]
                            }
                            haveImage={{
                                images:[{ src:"http://www.pkps.edu.hk/img/teachplan/chi/concern/bottom.jpg" }],
                                position:"down",
                                imageHeight: "550px",
                            }}
                        />
            </Grid>
        </Grid>
    )
}
