import { PlaylistAddOutlined } from "@mui/icons-material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageStore } from "../PageStore";

//Step 1.
export interface EngSubjectEngDisplayGalleryStatus {
    subjectEngP3DisplayGalleryIdx: number;
    subjectEngP4DisplayGalleryIdx: number;
    subjectEngP5DisplayGalleryIdx: number;
    subjectEngP6DisplayGalleryIdx: number;
}
//Step 2.
const initialState: EngSubjectEngDisplayGalleryStatus = {
    subjectEngP3DisplayGalleryIdx: 0,
    subjectEngP4DisplayGalleryIdx: 0,
    subjectEngP5DisplayGalleryIdx: 0,
    subjectEngP6DisplayGalleryIdx: 0
};


const EngSubjectEngDisplayGallerySlicer = createSlice({
    name: 'EngSubjectEngDisplayGallerySlicer',
    initialState,
    reducers: {
        //Step 3.
        setSubjectEngP3DisplayGalleryIdx: (state, action) => {
            state.subjectEngP3DisplayGalleryIdx = action.payload;
        },
        setSubjectEngP4DisplayGalleryIdx: (state, action) => {
            state.subjectEngP4DisplayGalleryIdx = action.payload;
        },
        setSubjectEngP5DisplayGalleryIdx: (state, action) => {
            state.subjectEngP5DisplayGalleryIdx = action.payload;
        },
        setSubjectEngP6DisplayGalleryIdx: (state, action) => {
            state.subjectEngP6DisplayGalleryIdx = action.payload;
        },
    }
});
//Step 4.
export const {
    setSubjectEngP3DisplayGalleryIdx,
    setSubjectEngP4DisplayGalleryIdx,
    setSubjectEngP5DisplayGalleryIdx,
    setSubjectEngP6DisplayGalleryIdx,
} = EngSubjectEngDisplayGallerySlicer.actions;
//----------------------
export const EngSubjectEngDisplayGalleryReducer = EngSubjectEngDisplayGallerySlicer.reducer; //No need to change