import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../layoutPages/components/PageTitle';
import { HaveImage, Paragraph } from '../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../layoutPages/defaultSetting';
import { DottedList } from '../../layoutPages/containers/DottedList';
import { MainCard } from '../../layoutPages/components/MainCard';
import { CourseTab } from '../CourseTarget/menuTab';
import { MaterialList } from '../../layoutPages/components/MaterialList';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { enrollmentMenuData } from '../../data/enrollmentMenuData';

export function PageApplication() {

    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >

                <br></br>
                <CourseTab data={enrollmentMenuData} />

                <PageTitle title='入學申請方法 | 入學資訊' size='h3' sx={{ m: 5 }} />

                <Paragraph
                    instantAppear
                    data={["有興趣報讀本校的家長，請帶同以下證明文件親臨本校(校址：柴灣小西灣富怡道12號)報名:", "1.學生的身份證明文件正本及副本，如出生證明書、單程證等；", "2.學生最近成績表副本；", "3.學生近照一張；", "4.回郵信封一個;", "如有任何查詢，可致電28977866或傳真至28977883"]}
                />

                <Paragraph
                    data={[<MaterialList data={[{
                        name: "下載入學報名表格",
                        src: "http://www.pkps.edu.hk/newhtml/%E5%9F%B9%E5%83%91%E5%B0%8F%E5%AD%B8%E5%85%A5%E5%AD%B8%E7%94%B3%E8%AB%8B%E8%A1%A8.pdf",
                        icon: <PictureAsPdfIcon sx={{ color: "red" }} />,
                    },]} />,
                    ]}
                />

                <Paragraph
                    
                    data={["學生手冊及學生家課冊"]}
                    haveImage={{
                        
                        images: [
                            { src: "http://www.pkps.edu.hk/image/intro/application.jpg",

                         },
                        ],
                        position: "down",
                        mainCardProps:{imgSx:{maxWidth: "50vw"}, noImgPopup:true } ,
                        
                    }}
                />

            </Grid>
        </Grid>
    )
}
