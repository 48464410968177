import { Box, Card, CardMedia, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { useDispatch, useSelector } from 'react-redux';
import { gsMenuData } from '../../../data/SubjectMenu/gsMenuData';
//import { DefaultSetting } from '../../../layoutPages/defaultSetting';

export function PageGsDisplay() {

    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='常識科 | 學生成果展示' size='h3' subtitle={{ title: 'STUDENT DISPLAYS OF WORK', size: 'h5' }} />
                <CourseTab data={gsMenuData}/>

                <Card>
                    <CardMedia height="2000px" component="iframe" src='http://www.pkps.edu.hk/20years/subject/gs2.html' />
                </Card>

            </Grid>

        </Grid>
    )
}
