import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import StarsIcon from '@mui/icons-material/Stars';
import { Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { MaterialList, MaterialWithLink } from '../../../layoutPages/components/MaterialList';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { CourseTab } from '../menuTab';
import { engMenuData } from '../../../data/SubjectMenu/engMenuData';

export function PageEngDevelopment() {

    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <PageTitle title='Professional Development of Teachers' size='h3' />
            <Grid item xs={11} sm={10}>
            <CourseTab data={engMenuData}/>
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/eng/development/engDevelopment1.jpg" imgHeight="480px" noImgPopup={true} />

                <Typography variant="h5" sx={{ color: "brown", py: 3 }}>
                    To ensure continuous professional development, professional sharing was undertaken throughout each academic year both inside the school and in the broader community.
                </Typography>

                <Paragraph title='1. Lesson demonstration'
                    data={["Our English teachers welcome guests to join our English lessons, so as to let more parties understand our curriculum better. We treasure every feedback from professionals which help our team to pursue for excellence."]
                    }

                />

                <Paragraph title='2. Reading workshops'
                    data={["Ongoing reading workshops are arranged by our NET Advisory Teacher and professional English experts to keep our teachers updated with the current teaching methods to enhance teaching effectiveness."]
                    }

                />

                <Paragraph title='3. IT workshops'
                    data={["Appropriate use of IT is encouraged to add variety and motivate students to learn English. Therefore, teachers will be provided with continuous IT training which allow us to grasp the latest technology in education."
                    ]}
                    

                />

                    <Paragraph title={"4. Project on Consolidation and Enrichment of Knowledge and Skills of Teachers in Supporting Students with Special Educational Needs'"} titleSize = "h4"
                    data={["By developing a differentiated school-based English curriculum, we hope that the learning performance of the students with SEN and ALAs can be further improved."]
                    }
                    haveImage={{
                        images:[{ src: "http://www.pkps.edu.hk/img/teachplan/eng/development/engDevelopment2.jpg"
                            },{ src: "http://www.pkps.edu.hk/img/teachplan/eng/development/engDevelopment3.jpg"
                        }
                        ],
                        position:"down",
                        
                    }}

                />

            </Grid>


        </Grid>
    )
}