import { Button, ButtonProps, Grid, Typography, useMediaQuery } from "@mui/material";
import TvIcon from '@mui/icons-material/Tv';
import React from "react";
import { DefaultSetting } from "../../defaultSetting";
import styled from "@emotion/styled";
import { purple } from "@mui/material/colors";
import { MyColorButton } from "../../components/MyColorButton";

export interface HomePageIconButtonProps {
    content: React.ReactNode | string,
    icon: React.ReactNode,
    onClick: (event: any) => void;
    inverted?: boolean
    myColor?: string,
}

export function HomePageIconButton(props: HomePageIconButtonProps) {
    const isPc = useMediaQuery('(min-width:600px)');
    const isMobile = useMediaQuery('(min-width:1124px)');
    let space: number = isPc ? 3 : 2;
    return (
        <Grid item xs={6} md={3} sm={3} sx={{ textAlign: "center" }}>
            <MyColorButton
                mainColor={props.myColor ?? DefaultSetting.secondColor}
                variant="outlined"
                inverted={props.inverted}
                startIcon={props.icon}
                size="large"
                onClick={props.onClick}
                sx={{ p: space, pt: space, mt: 1, mb: 1, width: isMobile ? "260px" : "40vw", height: "100px", borderRadius: "20px", ...DefaultSetting.hoverZoomAni }}
            >
                {
                    typeof props.content === "string" ?
                        <Typography variant="h6" sx={{ textTransform: "none" }}>{props.content}</Typography>
                        :
                        props.content
                }
            </MyColorButton>
        </Grid>
    )
}