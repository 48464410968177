import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import React from 'react';
import { itMenuData } from '../../../data/SubjectMenu/itMenuData';

export function PageItBase() {

    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='資訊科技 | 校本課程特色' size='h3' subtitle={{ title: 'SCHOOL-BASED CURRICULUM', size: 'h5' }} />
                <CourseTab data={itMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/it/base_top.jpg" noImgPopup={true} />

                <Paragraph title="Google Classroom"
                    data={[
                        <DottedList data={["本校採用 Google Classroom 作為主要電子教學平台，並以此平台連接學生第一及第二學習階段，讓學生在實體課堂外有一個具效能及穩定的教學平台，以進行課後延伸活動。教師將透過Google Classroom分享學習資源，分發學習任務，訂定學習進程，為學生提出及時回饋及照顧個別差異。"]} />
                    ]}
                    haveImage={{
                        images: [{
                            src: "http://www.pkps.edu.hk/img/teachplan/it/base1.jpg"
                        }
                        ],
                        position: "left",
                    }}
                />

                <Paragraph title="第一階段（小一至小三）"
                    data={["在此階段，我們希望誘發初小學生對使用資訊科技學習的好奇心和興趣，透過在各學科加入流動電子學習元素，安排學生學習平板電腦上的不同應用程式（APP），累積學生使用資訊科技工具、軟件的經驗。藉此使他們熟習一般的平板電腦操作，以及認識資訊科技在日常生活中的廣泛應用。"]}
                />

                <Paragraph title="第二階段（小四至小六）"
                    data={["在此階段，學生每星期將有一節資訊科技課，讓學生可於學校電腦室認識基本的操作電腦方法，並透過電腦網絡獲取資訊，發展溝通及處理資訊的技巧，促進學習，以及提升個人資訊修養。"]}
                />

                <Paragraph title="Dr. PC Family"
                    data={[
                        <DottedList data={["本校採用校本化「Dr. PC Family小學電腦『學與教』」的電子課本，並因應資訊科技發展及趨勢，適時更新課程，如加入國安教育元素，讓學生能善用資訊科技。"]} />
                    ]}
                    haveImage={{
                        images: [{
                            src: "http://www.pkps.edu.hk/img/teachplan/it/base2.jpg"
                        }
                        ],
                        position: "left",
                    }}
                />

                <Paragraph title="-	Scratch 編程"
                    data={[
                        <DottedList data={["透過不同程度的Scratch課題，令學生能編寫基本程式，建立編程的概念及興趣。"]} />
                    ]}
                    haveImage={{
                        images: [{
                            src: "http://www.pkps.edu.hk/img/teachplan/it/base3.jpg"
                        }
                        ],
                        position: "left",
                    }}
                />

                <Paragraph title="配合跨學科主題學習"
                    data={["在恆常課程以外，資訊科技科與校內各科組緊密合作，配合科組教學，在資訊科技堂中教授各種軟件的使用方式，以提升學生的自學及製作專題研習的能力。"]}
                />




            </Grid>
        </Grid>
    )
}
