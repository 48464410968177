import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import React from 'react';
import { vaMenuData } from '../../../data/SubjectMenu/vaMenuData';

export function PageVaBase() {

    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='視覺藝術 | 校本課程特色' size='h3' subtitle={{ title: 'SCHOOL-BASED CURRICULUM', size: 'h5' }} />
                <CourseTab data={vaMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/va/base/base_top.jpg" imgHeight='400px' noImgPopup={true} />

                <Paragraph title="從生活裡出發發現美"
                    data={["我們堅信藝術教育是教育中重要的一環，是培養學生美感觸覺的關鍵，亦是全人發展的五種基要學習經驗之一。藝術的智慧與能力並非單靠自成長即可獲得，學生必須透過有系統及配合主題的學習，才能掌握運用視覺形式所表現的美感、記號系統及視像結構，從而適切地表達個人的感情和思想，以及欣賞與評價不同的藝術表現，藉此發揮個人的藝術潛能、建立個人的價值觀，及對世界或不同文化的多元觀點。"]}
                    haveImage={{
                        images: [{
                            src: "http://www.pkps.edu.hk/img/teachplan/va/base/base1a.jpg"
                        }, {
                            src: "http://www.pkps.edu.hk/img/teachplan/va/base/base1b.jpg"
                        }, {
                            src: "http://www.pkps.edu.hk/img/teachplan/va/base/base1c.jpg"
                        }
                        ],
                        position: "down",
                        imageHeight:"350px"
                    }}
                />

                <br></br><br></br>

                <Paragraph
                    data={["「美感教育」不是技術的學習，而是希望讓我們開始對生活中的每一件事情敏感，察覺生活周遭自然物之美。要讓學生對美感有感，就必須讓學生面對它時有所感，面對不好和不美，可以產生希望更好、更美的想法，進而願意開啟發現、探索、體驗、嘗試、運用、整合的歷程。因此，「美感教育」可是一種素養教育，也是一種自信心的養成。"]}
                    haveImage={{
                        images: [{
                            src: "http://www.pkps.edu.hk/img/teachplan/va/base/base2a.jpg"
                        }, {
                            src: "http://www.pkps.edu.hk/img/teachplan/va/base/base2b.jpg"
                        }, {
                            src: "http://www.pkps.edu.hk/img/teachplan/va/base/base2c.jpg"
                        }
                        ],
                        position: "down",
                    }}
                />

                <br></br><br></br>

                <Paragraph
                    data={["我們的課程架構以學生的人本學習、教育意義與實踐的可行性為最基本考慮。我們的教師對藝術教育觀念經常作出檢討和反思，避免教學觀念及形式逐漸僵化。同時，我們逐步統整視藝科課程，主張以「主題」為焦點，結合視覺藝術評賞及與創作相關的學習範疇，融會施教。並探討多元化的藝術發展脈絡，拓展藝術創造，使他們的視覺藝術經驗更豐富，並貼近現代藝術發展的步伐。"]}
                    haveImage={{
                        images: [{
                            src: "http://www.pkps.edu.hk/img/teachplan/va/base/base3a.jpg"
                        }, {
                            src: "http://www.pkps.edu.hk/img/teachplan/va/base/base3b.jpg"
                        }, {
                            src: "http://www.pkps.edu.hk/img/teachplan/va/base/base3c.jpg"
                        }
                        ],
                        position: "down",
                    }}
                />


            </Grid>
        </Grid>
    )
}
