import { Grid, Paper, SxProps, Typography, Fade, Slide, useMediaQuery } from '@mui/material';
import { MainCard, MainCardProps } from '../components/MainCard';
import { yyyymmdd } from '../../system/Features/YYYYMMDD';
import SwipeableViews from 'react-swipeable-views';
import { useDispatch, useSelector } from 'react-redux';
import { selectComponentStore } from '../../system/PageStore';
import { homePageEventGallerySet } from '../../system/Slicer/HomePageSlicer';
import { TitleWithDate } from './TitleWithDate';
import * as Lodash from 'lodash';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { autoPlay } from 'react-swipeable-views-utils';
import { Box } from '@mui/system';
import { idText } from 'typescript';

export interface AutoPlaySetting {
    interval: number;
    onChange: Function;
}

export interface CardGalleryProps {
    cards: Array<React.ReactNode>;
    cardPerRow?: number;
    index: number;
    setIndex: ActionCreatorWithPayload<number, string>;
    enableMouseEvents?: boolean;
    backgroundColor?: string;
    //
    autoPlaySetting?: AutoPlaySetting;
}

export function CardGallery(props: CardGalleryProps) {
    const dispatch = useDispatch();
    const isPc = useMediaQuery('(min-width:600px)');
    let cardPerRow: number = isPc ? props.cardPerRow ?? 3 : 2;

    if (props.autoPlaySetting) {
        const timeoutLoop = () => {
            setTimeout(() => {
                props.autoPlaySetting!.onChange();
            }, props.autoPlaySetting!.interval);
        }
        timeoutLoop();
    }

    const setIndex = (index: number, lastIndex?: number) => {
        let nextIndex = index;
        if (index == props.cards.length) nextIndex = 0;
        if (index <= -1) nextIndex = props.cards.length - 1;
        dispatch(props.setIndex(nextIndex));
        return {}
    };
    let data = Lodash.chunk(props.cards, cardPerRow);
    return (
        <Paper elevation={0} sx={{ mt: 2, mb: 2, backgroundColor: props.backgroundColor ?? "#FFF" }}>
            <SwipeableViews axis="x" index={props.index} enableMouseEvents={props.enableMouseEvents} onChangeIndex={setIndex}>
                {data.map((items, idx) => (
                    <Box key={idx}>
                        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
                            {items.map((item) => (
                                <Grid item xs={6} sm>
                                    {item}
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                ))}
            </SwipeableViews>
        </Paper>
    );
}

