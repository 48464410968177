import { useMediaQuery, Box, Grid, Divider, Card, CardMedia } from '@mui/material';
import { PageTitle } from '../layoutPages/components/PageTitle';
import { MainCard, MainCardProps } from '../layoutPages/components/MainCard';


import G1 from "../images/Slider/G1.png";
import G2 from "../images/Slider/G2.png";
import G3 from "../images/Slider/G3.png";
import G4 from "../images/Slider/G4.png";
import G5 from "../images/Slider/G5.png";
import { MainCards } from '../layoutPages/containers/MainCards';
import { height } from '@mui/system';

export const TempCardData: Array<MainCardProps> = [
    { caption: "某標題", src: G1 },
    { caption: "排隊", src: G2 },
    { caption: "放學", src: G3 },
    { caption: "指導", src: G4 }
];



export function VideoGalleryTemplate() {
    const isPc = useMediaQuery('(min-width:600px)');
    return (
        <Box>
            <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
                <PageTitle title='Gallery' size='h3' subtitle={{ title: "Subtitle", size: 'h5' }} />

                <Grid item xs={6} sm={5}>
                    <Card>
                        <CardMedia height={"300px"} component="iframe" src='https://www.youtube.com/embed/y1Yc78ckfrM' />
                    </Card>
                </Grid>
                <Grid item xs={6} sm={5}>
                    <Card>
                        <CardMedia height={"300px"} component="iframe" src='https://www.youtube.com/embed/Ko0Q5TxmagM' />
                    </Card>
                </Grid>

                <Grid item xs={6} sm={5}>
                    <Card>
                        <CardMedia height={"300px"} component="iframe" src='https://www.youtube.com/embed/HW6x4m7toFo' />
                    </Card>
                </Grid>


                <Grid item xs={6} sm={5}>
                    <Card>
                        <CardMedia height={"300px"} component="iframe" src='https://www.youtube.com/embed/mG_8vXOY5fs' />
                    </Card>
                </Grid>
                <Grid item xs={11} sm={10}>
                    <Divider> 某一個活動的作品集 </Divider>
                </Grid>

                <Grid item xs={11} sm={10}>
                    <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
                        <Grid item xs={6} sm={4}>
                            <Card>
                                <CardMedia height={"220px"} component="iframe" src='https://www.youtube.com/embed/5clzKSLJxsI' />
                            </Card>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Card>
                                <CardMedia height={"220px"} component="iframe" src='https://www.youtube.com/embed/rZ6qF_gG5gc' />
                            </Card>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Card>
                                <CardMedia height={"220px"} component="iframe" src='https://www.youtube.com/embed/56XV_TZb6Ps' />
                            </Card>
                        </Grid>

                        <Grid item xs={6} sm={4}>
                            <Card>
                                <CardMedia height={"220px"} component="iframe" src='https://www.youtube.com/embed/rZ6qF_gG5gc' />
                            </Card>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Card>
                                <CardMedia height={"220px"} component="iframe" src='https://www.youtube.com/embed/56XV_TZb6Ps' />
                            </Card>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Card>
                                <CardMedia height={"220px"} component="iframe" src='https://www.youtube.com/embed/5clzKSLJxsI' />
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box >
    )
}