import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { pthMenuData } from '../../../data/SubjectMenu/pthMenuData';

export function PagePthObjectives() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = [
        "培養聆聽、說話、朗讀等普通話能力，以及自學能力",
        "增進與普通适科有關的語言知識，以及對中華文化的認識",
        "提高對普通話科的學習興趣，培養良好的學習態度和習慣",
    ];


    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='普通話 | 課程目標' size='h3' subtitle={{ title: 'CURRICULUM OBJECTIVES', size: 'h5' }} />
                <CourseTab data={pthMenuData}/>
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/pth/objectives/top.jpg" imgHeight='500px' noImgPopup={true} />

                <Paragraph
                    data={[
                        <DottedList data={dottedListData} />
                    ]
                    }
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/pth/objectives/obj1.jpg" }, { src: "http://www.pkps.edu.hk/img/teachplan/pth/objectives/obj2.jpg" }],
                        position: "down",
                    }}
                />
                <br></br><br></br>
                <Paragraph title="各範疇學習目標："
                    data={["聆聽:培養聆聽能力，以理解話語的內容。",
                    "說話:培養說話能力，以表達自己的思想感情，與人良好溝通。",
                    "閱讀:培養朗讀及自學能力；增進與普通話科有關的語言知識，以及對中華文化的認識。",
                    "手拼:培養運用漢語拼音或注音符號的能力，以提高自學能力。",]
                    }

                />
            </Grid>
        </Grid>
    )
}
