import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { useDispatch, useSelector } from 'react-redux';
import { pthMenuData } from '../../../data/SubjectMenu/pthMenuData';
//import { DefaultSetting } from '../../../layoutPages/defaultSetting';

export function PagePthDevelopment() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = [
        "鼓勵教師參加普通話培訓課程；",
        "鼓勵教師參加普通話水平測試及基準試；",
        "鼓勵教師帶領學生參加普通話朗誦比賽、戲劇表演比賽及講故事比賽等以此幫助教師提高自身科本專業水平。",
    ];

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='普通話 | 教師專業發展' size='h3' subtitle={{ title: 'PROFESSIONAL DEVELOPMENT OF TEACHERS', size: 'h5' }} />
                <CourseTab data={pthMenuData}/>
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/pth/development/top.jpg" imgHeight='500px' noImgPopup={true} />
                
                <Paragraph 
                    data={[<DottedList data={dottedListData} />]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/pth/development/development1.jpg" },
                        ],
                        position: "right",
                        imageHeight: "350px",
                    }}
                />
            </Grid>

        </Grid>
    )
}
