import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import React from 'react';
import { peMenuData } from '../../../data/SubjectMenu/peMenuData';
import { MainCards } from '../../../layoutPages/containers/MainCards';

export function PagePeDisplay() {

    const isPc = useMediaQuery('(min-width:600px)');
    let dottedListData = ["東區東區校際游泳比賽", "東區校際田徑比賽", "東區校際足球比賽", "東區校際籃球比賽", "東區校際排球比賽", "東區校際羽毛球比賽", "東區校際乒乓球比賽", "舞蹈比賽", "跆拳道比賽"]

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='體育科 | 學生成果展示' size='h3' subtitle={{ title: 'STUDENT DISPLAYS OF WORK', size: 'h5' }} />
                <CourseTab data={peMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/pe/display/D0.jpg" noImgPopup={true} />

                <MainCards height='350px' data={[
                    {
                        caption: "2018港島東區小學分會頒獎典禮",
                        src: "http://www.pkps.edu.hk/img/teachplan/pe/display/D1.jpg",
                        imgHeight: "400px"
                    },
                    {
                        caption: "2018東區小學校際排球比賽男子組冠軍、女子組季軍",
                        src: "http://www.pkps.edu.hk/img/teachplan/pe/display/D2.jpg",
                        imgHeight: "400px"
                    },
                    {
                        caption: "2018東區小學校際籃球比賽男子組殿軍",
                        src: "http://www.pkps.edu.hk/img/teachplan/pe/display/D3.JPG",
                        imgHeight: "400px"
                    },
                ]} />
                <MainCards height='350px' data={[
                    {
                        caption: "2019東區小學校際足球比賽季軍",
                        src: "http://www.pkps.edu.hk/img/teachplan/pe/display/D4.jpg",
                        imgHeight: "400px"
                    },
                    {
                        caption: "2019東區小學校際乒乓球比賽男子乙組季軍",
                        src: "http://www.pkps.edu.hk/img/teachplan/pe/display/D5.jpg",
                        imgHeight: "400px"
                    },
                    {
                        caption: "花式跳繩隊",
                        src: "http://www.pkps.edu.hk/img/teachplan/pe/display/D6.jpg",
                        imgHeight: "400px"
                    },
                ]} />

                <MainCards height='350px' data={[
                    {   
                        caption: "游泳隊",
                        src: "http://www.pkps.edu.hk/img/teachplan/pe/display/D7.jpg",
                        imgHeight: "400px"
                    },
                    {
                        caption: "羽毛球隊",
                        src: "http://www.pkps.edu.hk/img/teachplan/pe/display/D8.jpg",
                        imgHeight: "400px"
                    },
                    {
                        caption: "田徑隊",
                        src: "http://www.pkps.edu.hk/img/teachplan/pe/display/D9.jpg",
                        imgHeight: "400px"
                    },
                ]} />

            </Grid>
        </Grid>
    )
}
