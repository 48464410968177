import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import React from 'react';
import { txMenuData } from '../../../data/SubjectMenu/txMenuData';

export function PageTxStrategy() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = ["向教師學生家長闡釋有關政策,包括清晰的評估目的,準則和評估指引;", "恰當地安排進展性和總結性評估,各類評估頻次適中;", "設計內容廣泛的評估,而形式及難易度須配合學生的能力和需要, 照顧學生的學習差異;", "設計清晰具體、比重分明的評分準則,使教師能依據評分準則準確 評估學生的表現;", "利用各科的課業檢視分享會,鼓勵及表揚認眞批改課業,並能給予 學生適切回饋的老師;", "因應校內不同學習需要的學生作特別安排,如:拔尖補底班、協作 敎學班、識字特工隊等。"]


    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={12} sm={10} >
                <PageTitle title='學校評估政策 | 推行策略' size='h3' subtitle={{ title: 'ASSESSMENT FOR LEARNING', size: 'h5' }} />
                <CourseTab data={txMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/tx/strategy/top.jpg" noImgPopup={true}/>

                <Paragraph
                    data={[ <DottedList data={dottedListData} /> ]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/tx/strategy/strategy1.jpg" },],
                        position: "right",
                        
                    }}
                />

            </Grid>
        </Grid>
    )
}
