import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { MainCard, MainCardProps } from '../layoutPages/components/MainCard';
import { PageTitle } from '../layoutPages/components/PageTitle';
import { DottedList } from '../layoutPages/containers/DottedList';
import { MainCards } from '../layoutPages/containers/MainCards';
import { Paragraph } from '../layoutPages/containers/Paragraphs/Paragraph';
import { DefaultSetting } from '../layoutPages/defaultSetting';
import WebIcon from '@mui/icons-material/Web';
import { MaterialList } from '../layoutPages/components/MaterialList';

export const EventCardData: Array<MainCardProps> = [
    
    {
        caption: "種植貓罐小盆栽工作坊",
        src: "https://lh3.googleusercontent.com/pw/AMWts8DnQqD_GugN4FAeasAXbG5dPCfwNZWVFYaTQfFOusQK7rZWzuiOXwAGCcWxS3trU4VU_mV4m2lMmQFoDq0-mlKSThDtg1heyxpjiOiYAsf6f6GawCC2YncGQpQQeLwZ05P4fzxHLdx0sylfNxsJiuuV=w1190-h893-no?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/MpZkSa4krZBWYYeu6", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "培養孩子的專注力",
        src: "https://lh3.googleusercontent.com/pw/AMWts8Dthcr0zaQ-y53a20dda92kzEWq5kkSe2-IKLs2lhMES5W4b98YXRy-Lg0_ldyjc1VGdBFmv5kDvbBkkwAEBfy9wPOAQuXNRE1hMFQ43dp-qYgm0BjxaxcGe23VBXLG87vGqktLsLs0KSEvu2cblvVI=w1239-h929-no?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/7iP9S2Q6kJf1A65m6", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "桌遊［管教技巧］家長工作坊 (早)",
        src: "https://lh3.googleusercontent.com/pw/AMWts8DUttLhuzmi_SXS6WYdUarbjdAnXfjz3kybDOQMHccpb4TczZOqYA4e9Zb1IL_Qv1TwPMy0OHEr3Q-Opk40juhgHxm0jcabGI-nPRGFWSPwEVrxg7fM0w2bSFsIRTPSf6HqprwZb_U9ORHUNFRq48ez=w1094-h821-no?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/dHg1VPzySxjFGHUx8", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "校長小煮意(聖誕篇)",
        src: "https://lh3.googleusercontent.com/pw/AMWts8DCicjxXWTkGc0lEWh0gWNiniftv7OvTFh7Yvfq8K4EcPE24r6W0vvvqVpzz-o8YG68d7VlEDuPPAm0jEwh7ZeDA1t2Rp2C2ApiRmDdrN2ZBi9Qu2RmpDJSt7uPfsaqNyoR1su9GG4Dv5sb_0M01u-r=w1239-h929-no?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/JboAcPD54U2QKeF16", '_blank', 'noopener,noreferrer')
    },

    {
        caption: "日語基礎班",
        src: "https://lh3.googleusercontent.com/pw/AMWts8Ddtv2cBKe8zvPwDqLdakzFnTQlIGRLQ2tjE2a3RhrtdNHgeD82KfpFMg8nNBGbRlnaWSniFcrwGBp9RArzYCpQH7LCA7Tcs5YZWO5im0HFCfz5GFbEs6floso02kzf7wkDG0es3vrjPxIzXK4jvHQi=w1652-h929-no?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/2Gcys4tnSBiUX45W7", '_blank', 'noopener,noreferrer')
    },


    {
        caption: "大笑瑜伽親子工作坊",
        src: "https://lh3.googleusercontent.com/pw/AMWts8DSNFo9StkA8u3xH5Ts6YZk2pFsq3hgk8c_aCYKalwFj1F6ZamsqyBrcjoG3Pa97wnSQvbQdTCdUmW4iya2D9Z9awkKzjKD2yg5y-jVWS-wVJ0sXZZeNs92vWN9fzRhNgL4m9KCI1iz4Ki2kANavsD0=w1239-h929-no?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/dos7ZZdkb4YjDk4u7", '_blank', 'noopener,noreferrer')
    },

    {
        caption: "畫出好心情 紓壓禪繞畫",
        src: "https://lh3.googleusercontent.com/pw/AL9nZEWOXXRQdWj84Qm712o_GSTlBFkpMpEn5ef5KLtauhBio3KVEjotSMH_1HQkclfPyEbvn6pozq99IfweIFXZSz3tQg6OSUEXeLfMSIP_Un857YnGq2L4KkfbKe6biteOrgHk2DIEAAR2Y2nPZcMcvb5C=w1219-h914-no?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/71oRWM3oWo2czZyc8", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "鈎織班 - 手繩袋",
        src: "https://lh3.googleusercontent.com/pw/AL9nZEWQSkZxQvkjQ1nqhFimDWqenFbfuFpHeTUvaBUwNW-V7P-qVqZlWFSwkq4uDFrAalPUyxt1_um_NrwTzwWGEdq1bZ4pjEeMVDLta5RyyHLcswMtgNgJ6b7AjmAD8fmPxyfmn86FWSazVdNq9BJ7lKyS=w1219-h914-no?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/LegeuXBwmBFStY3v5", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "和善而堅定的管教",
        src: "https://lh3.googleusercontent.com/pw/AL9nZEWCxQ-jtBQcjiYWi74t_Gl52xz_oiAIKl1Wh9ZWnwBWTbGOQsGSUe5tAp6AtnngDY-FQ1JBkGL_-YPrjAAPZqwXfe7A5mUidGzG9ZM88ppymFo9sv843yRD_WU6mbOEbuanNQ3jx1DHoTVuu4iGW9q3=w1219-h914-no?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/rdTRCp7viHFouBd99", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "大手牽小手‧閱讀同步走",
        src: "https://lh3.googleusercontent.com/pw/AL9nZEWIMgLD_dAdeWEuuSAxIET0rk6gxH9BNKf-qNS6Lzghzn4dVgGhMOyW-j5CmMwbgkMP6tNpcF9DSsecqYwlEZ95shxtQ4y-hmdS7unckyMpkZx6gdFhRMW6iw9P13h48IxoTWsgAoGpGJTvJ_-eOEng=w1219-h914-no?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/VyRUZTEedYxAeRHP8", '_blank', 'noopener,noreferrer')
    },

];

export const EventCardData2324: Array<MainCardProps> = [
    {
        caption: "校長小煮意",
        src: "https://lh3.googleusercontent.com/pw/AP1GczOwaQb8YWpFzNFMhguy5gFNSvzyzjrk2679dWJezdjIuW-sDSncgwVmKqe_A_h8oO6UJIxf6D44aXcRzFE9S4oahUK_4ZInTx9PBtgZGIX01_kid7isw0Qya2aTl9QOPtxeTtbk6g6ex8rE5roT2MrO=w1159-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/P4xrikXqKvW5AH7Q7", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "從原生家庭啟發個人成長",
        src: "https://lh3.googleusercontent.com/pw/AP1GczOpgPgwX5uvmhHhWa5KtBHzLtBdHwI7wOAswYxFklhtzL8ch26zpez1tzqR-RusxfsT6Xr7KZThDSEgwSJiSS_CP5zSCNy6ao-qahM-racC_BtoUXIyjYviJeWY9QzvaAUPa5DLycJ_th4ODcys0AIT=w1159-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/7jMXNFbwxA9baZMW7", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "處理親子衝突",
        src: "https://lh3.googleusercontent.com/pw/AP1GczNyu9KU3Jd2bKKeHXhAi7SRilm1welDJa9QZR7PFxGC2riAqdtXdgFB1vkwPyZlyOfoHmnugS2e51GzZsJFB9Vwm3txmj0vTx0togORjpzM6CZylFEXPi-aLNW66qM9vkaCxMnfOkk3RYCjqFH4YbQr=w1159-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/6Nnpt6afDB8vVWg26", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "熱縮膠親子工作坊",
        src: "https://lh3.googleusercontent.com/pw/AP1GczNHu0OMHn3EO5Up7VXSaUevLsxZZMqt1DWjOv7hUAz9HSBbzCx8-SBUT8dQbJu2Rx2reJE8_o0Crzd5mEfek9Kb2uZh-waBJkHbAw7TNESd2bx-XjxaIVvJ8bJPGOGrqBjHR1T_sHbainOnYxPdxtGD=w1159-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/YFpr6ScpaPqMnYvc8", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "調教你的Mocktail親子工作坊",
        src: "https://lh3.googleusercontent.com/pw/AP1GczNkEZ4GMrV-EwU7CzVwm-Z0jWHMpntmsAM3XaasArLL4mZAIEATqMXhBT6lMAPdh2Wmp6NTt45BIUFPKHvDVckShZZaOL4Y0ZLGrsWkQrGCq1ksWrG4TV8KLZ6tW7eBFPUE4WMR_6bCIXeNj7J4pbAI=w1159-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/akoH4vuxVCYQec2ZA", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "跆拳道(成人班)",
        src: "https://lh3.googleusercontent.com/pw/AP1GczMKKFVTS4elCTsIutarSirJuFtGip9DEvx-WL1bVlGgtXDDteqjQ7YBeXCkLzXr7yNv31SAtqYlk-Tz54tkPgJ0Db69UV233W_s2zkriSYaItt6xnd5POSbCxytvgGmvznvN0pp7jPLFzZys_Xxbmio=w1024-h768-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/v5PkTA3ceUDbfMPD6", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "瑜伽x頌缽工作坊",
        src: "https://lh3.googleusercontent.com/pw/AP1GczNz2znuG49neKgc_HfRWw6UFiYGHHUqthpbJt_a24FWBK7C55aVwarQJ4kuSzhxtBxAawHCIxHpC7Wa5TmrLwFX7IbprSfzRqkHXuGGgeqC3lwmwnk9chw9wn2qvFqHUZceA1uIJnuVKh64oO6Gyen2=w1159-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/6ADcE9ft4fLi67ELA", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "親子排球樂",
        src: "https://lh3.googleusercontent.com/pw/AP1GczOVUvKtqY9Iyji8_rW_bjsRQvAAcVghSsaHp3fD7XXV4qzH_bbWfGCqnq42eaQHezxVYoGeoFaYLZvpvMoBVhNaesM37mMwXzbfU3bK8ZPgV2JsNfVvEnNUaLsELJQ9gMS6HaO_wxZTbTpQLIz3ygzR=w1159-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/U8QfVmJcM2KE6wRQ6", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "九型人格與親子教養工作坊",
        src: "https://lh3.googleusercontent.com/pw/AP1GczMzyt1JJEd3wPnH-zDGrXCEw_lZTAzEoe-nAwljZSxTcm3K4PzxLHxT4j4q-6ZMeTv_g-SHIK2kc2nYO5GjMLWpZLfLxrj3oqs8Jlyg7HFiuJP_7d25uafFeKfMIOlR91AH6l4KAXMWPCoRngCO9VnZ=w1159-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/hUfcj52jpmHHbJLX8", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "大笑瑜伽工作坊",
        src: "https://lh3.googleusercontent.com/pw/AP1GczNjt2QGVzHklVTe86djSUJsPErdm-8YTL0VdfKbW45bRn1zkNimW3Zb8c3MAOxNuCfU_8U5aGGHF8ihBrd7YqeSiTR3T8kUgwhACd51_azsyHSJpTVY8PS8SBrjSciuKzCOmzRhOzRgVCgIHPRbLbAN=w1159-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/z6LLk5wU8wZxH6d87", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "正面價值觀-守規、尊重、同理心",
        src: "https://lh3.googleusercontent.com/pw/AP1GczNf_DqV5HjojwNLLCIkNW0zYerfEo9p398NR60Qcqr5DUMDF65gBnyDD67zmLdhTLYxpfvYh43QJlqGAPWuoJFa_xqIgutFWWE5qlTdW0PsFaeNFlh87Cu3pXdENdf28SdLpRrCyZpUuKkTApHcSK40=w1159-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/SB9EtowbaJrXC3Vh6", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "扭波波工作坊",
        src: "https://lh3.googleusercontent.com/pw/AP1GczN4PNow-veGGxAkkkRNnb5uRr_VuZlEpgz85PHijFh8IqJYXZ23V_iq_E5ZfEZPbqkdIypeNaiWk81z8-8mg8lx0CtY8V2ax2lxv53xyh2MzTWFkApk3ttHoE7aUwHv8j44wPNhxX4zH05vzJ1ByV_6=w652-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/9LUFAi2Up6K8Uuof7", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "［腦力玩 - 腦力教］家長桌遊工作坊",
        src: "https://lh3.googleusercontent.com/pw/ABLVV841Ye1UDczNSj2rskoeNgrgKIUryGNIw9_hvxWAxpGrrzgQncDt6nbMXR9PiXcZsU95Sb3TP5rfn_ypgOyv2i_6vib7eOQ0FfbWhxd46RihbOxBt452wQAWcSYhrVNVVVw3MNk1U1-1LYFd6CIVPnXT=w1159-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/g2v9fMPVkc5iReBLA", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "STEAM親子烘焙課程",
        src: "https://lh3.googleusercontent.com/pw/ABLVV85BzkZVFQ5CvrVFcAevC-ndqafwfNpSHNJoBGrKqyk4gpwqN-c_pPNe3i8qpIai595D7mzLMQq8eIMhxU4UZamX5ZKKaegh6mqJdas0gzvya2zjqnVTo9d8_o3B1hHgEtME8c1jyGTlArWWmrrj3ztB=w1159-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/bgwDVJ4d6zkUZ3638", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "正向親子管教技巧",
        src: "https://lh3.googleusercontent.com/pw/ABLVV85x66NY5Gl0YO2lMcnv6psUwzWPesi8ddyOOOMkb0PQuzVVZa5vD037l8RXgUYL6GlFKLiyGBtGyQPaS6eeNeG8_nB98QkR9fFEzfvTa2WO7oikZOrmSXXXAu8WsUbr0UIItdHldUlVF1qRRdBqKiKb=w1159-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/2iWxpVtcWDCPApt68", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "面試禮儀與策略(升中)",
        src: "https://lh3.googleusercontent.com/pw/ABLVV84qK9cFRc5Ji20hT-UWOUgPAwVhMqJM8dQ7GtHDe4-oO3vZLBolZeR4F3mzJTHg4uSqc8Eo-J9zhj7aHlTCtfoQ0OyYog4wAZp9KMXBaijaNlL6bIR_j65XKez8Q2rn0HCSVCxmAFYMOzbM7H8AVEbD=w1159-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/Cm21oMFZN8JiKUUS6", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "培養孩子的專注力",
        src: "https://lh3.googleusercontent.com/pw/ABLVV85eG0g6eeco6XQzl7M-L0kE72TGr_QpcfcIs300VJe6zz0WFF998i-wYZpxm38nOkEj_fcfPd-lx8Lgp2gVNOQnBeZ1pwP0NjQqyeRvAgTHHhJYMMKXAS6ANt_sWLAtxdOKGype5KAfJsqG0W_SEahj=w1024-h768-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/4GiALvXUTnnkS8mLA", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "智Net父母：子女正確使用互聯網",
        src: "https://lh3.googleusercontent.com/pw/ABLVV84_1W00f2hH5Uj8nilM81bmqMEioVPtuwDvsniqBPfmDxl2F-UDEZ-h_MvqnanAsnw0Yc64Ohhq6RqKvZbtg9ioZNzojD-WC_lq8240cAsMGIFTDsraOY9nRRtUeQ0T1Rc9w8vThEHFNddgQNkL5wQT=w1159-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/tBXy4HrS3JaFpT179", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "鈎織班(初級班)",
        src: "https://lh3.googleusercontent.com/pw/ABLVV840r1-SjGPtP8kIIUMiYl5nfEY4UJRtL13h_-qUC-5kj1XXEP-_wSuzJBzbaYvURkpNJ5O_IckVTaSsNHQVYyhP617uKer57LtPmaNDA33x05XEtx-FKFyFQedceTDIO6HJXcwdlSIYtNvR8rO_gmvj=w1920-h864-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/scm3Mi8Mb4xx7eaQA", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "支援子女的升小銜接",
        src: "https://lh3.googleusercontent.com/pw/ADCreHfPvz3eOoUkZQKrrxU4H1jXwoGjykWQGpBmhjled8cpitomhUAgtWz6CBEr2kXlDpBltqHG7Qk-258W52bJP9yKvBJj9Mb6RDeTBDfCtHCTs_uChHj_K1-lIe7quWCfYxwpqBdFGW-Pv25u4czzBCLK=w1159-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/CoxaBgLB2hk3sy8a8", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "情緒勒索與精神健康講座",
        src: "https://lh3.googleusercontent.com/pw/ADCreHfDtBMI3N93ZxUBWLFLfCPLXmQUTLyoHB5LoFe6Jb0_ie2bcnae_6CG9FrsRII5mtKZkd-gJ7wXKWbT7Wgsmut1qUmVnXVRq_3e0YLFUAzkUrdxrjw6cMeK5TepJzM9AznMs_mO7UzWvIVQRC2upLOk=w1159-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/7mfMom9mSLp6Qw8H6", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "網絡安全小知識",
        src: "https://lh3.googleusercontent.com/pw/ADCreHcOOtVLSiItseT1x6GyHCIe66qzBM9c69yBgL6urUMPdYqfhCVY61kBBtdtKV_ElwVgOnxlBC_P-5mhW8mH88EEEsNZM_k3q7r9rX1Y3HQKA23tBq6_8A6I1EQsvL-h3Mt3KVBoa4yWWG0JfWK1ZMlc=w1024-h768-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/dHg1VPzySxjFGHUx8", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "親子齊齊玩(一年級)",
        src: "https://lh3.googleusercontent.com/pw/ADCreHdf3w12omBdakEoPIiKTGBLjjHc6l8aQmKFao3bFUX_XX87uVXQHUIXR5zHSL-qVFMEkYy63g3hj6QGsoAqES_zqzaESDgACsAt0F2fiIbrsclBt-nbPVFkDh9RUzvUTc2YEoeXznjMg3nDUUys3SdZ=w1159-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/DRpLsPqS2YbU9w5j8", '_blank', 'noopener,noreferrer')
    },

    {
        caption: "親子齊齊玩(二年級)",
        src: "https://lh3.googleusercontent.com/pw/ADCreHcD58mE519jBqG7KzP2QMi_AdClefJPawEa6BwntCO3Rwwudrsomb8DBsnpGqUmg9Iq7dUJ7ce7w4ybuadCGV3BZj2am1JG0Qimy0gXfH8PbVtDm1fZGr-FKr5bkVRv385yBxRnOMWjJgB-tK-qPNzc=w1159-h869-s-no-gm?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/5HZ8KbV4FrLfc83k8", '_blank', 'noopener,noreferrer')
    },


    

];

export function PageParentCourse() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = ["透過各種運動技巧和知識的學習，提升學生體育科素養，建立積極活躍的生活方式，養成積極參與體育活動的生活習慣；", "讓學生認識運動的好處，鼓勵他們在餘暇時恆常地參與體育運動，使他們有健康生活習慣及持續改善身體質素；", "培養學生各種共通能力，鼓勵學生對事情抱績極態度；", "培養學生的體育精神及團體合作精神，鼓勵學生持守正面的價值觀，實踐良好的生活態度和行為；", "培養和增強學生解難和處變的能力，並能持續性發展，使他們能面對人生中的各種變化和挑戰。"]


    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2} sx={{ backgroundColor: DefaultSetting.mainBackgroundColor }} >
            <Grid item xs={11} sm={10} >
                <PageTitle title='成功父母學堂' size='h3' />
                <MainCard src="http://www.pkps.edu.hk/img/website/parentCourse/top.jpg" sx={{ mt: 2 }} noImgPopup={true} />

                <PageTitle title='學堂面面觀' size='h3' sx={{ pt: 3 }} />

                <Paragraph title="辦學宗旨"
                    data={["我們致力使培僑小學成為一個能夠不斷反思、探索、更新及追求卓越的學習組織。我們深深明白不斷學習的重要，而父母的學習態度對孩子有著深遠的影響，所以我們設立「成功父母學堂」，邀請家長們走進學堂，與我們一起學習。此外，我們亦希望通過學堂，建立家長聯絡網，促進家長與家長之間的學習。"]
                    }
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/website/parentCourse/point1a.jpg" },
                        { src: "http://www.pkps.edu.hk/img/website/parentCourse/point1b.jpg" },
                        { src: "http://www.pkps.edu.hk/img/website/parentCourse/point1c.jpg" }],
                        position: "down",
                    }}
                />

                <Paragraph title="課程設計"
                    data={["在課程設計上，學堂課程分三個範圍：「專題講座」、「興趣班」和「訓練課程」，每個範疇的內容多姿多采，例如:中英數老師會開辦工作坊向家長介紹一連串學習的策略及溫習方法；幫助小一學習適應新學習階段的小一家長工作坊；親子閱讀工作坊幫助父母為孩子建立閱讀的習慣；升中全攻略幫助六年級同學和家長準備「中一的自行分配學位階段」的面試；也有教授家長與子女溝通的專題講座、情緒管理、如何在家輕鬆談性、社交技巧及資訊科技內聯網家長培訓工作坊等。此外，亦因應家長本身的興趣開辦不同的課程，例如:跆拳道班、日語班、鈎織班和甜品班等，藉此幫助家長培養興趣及提升能力。"]
                    }
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/website/parentCourse/point2a.jpg" },
                        { src: "http://www.pkps.edu.hk/img/website/parentCourse/point2b.jpg" },
                        ],
                        position: "down",
                        imageHeight: "350px"
                    }}
                />

                <Paragraph title="學分計算"
                    data={["為了鼓勵家長積極參加學堂課程，每年會為「成功父母學堂」舉行畢業典禮。每個課程均有指定學分，累積100分或以上的學員均可畢業，累積150分或以上者可獲頒榮譽證書；而在上年度已畢業的學員，如在本年度修滿120學分或以上，亦可於畢業禮上獲頒「成功父母學堂」優秀學員證書。"]
                    }
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/website/parentCourse/point3b.jpg" },
                            { src: "http://www.pkps.edu.hk/img/website/parentCourse/point3a.jpg" }],
                        position: "down",
                    }}
                />

                <Paragraph title="任教導師"
                    data={["學校視家長間的差異為資源，並利用「學堂」作為平台，促進彼此互相學習，子女在學校學習，家長也在學校學習。「成功父母學堂」使家長們各展所長，讓他們擔任「學堂」導師，如理髮班、瑜珈班、禪繞畫班和正向心理專題講座等，「學堂」是培僑小學落實學習社群理念的措施。"
                        , "此外，為了提高課程質素，學堂會邀請專家擔任導師，過去曾為學堂擔任專題講座導師有：教統局課程發展主任（資優教育）陳森泉先生、香港教育學院教育心理輔導及學習支援系李文玉清博士、教統局總發展主任葉祖賢先生、香港數學奧林匹克學校歐淑嫻副校長、大中華攝影學會會長楊志強博士、保良局朱敬文中學龍德義校長等，他們主要教授家長各種管教孩子的「招數」，如何協助孩子們管理情緒及有效地完成功課等等。學堂亦安排中英文科老師開辦工作坊，向家長們分享學習語文的方法。"]
                    }
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/website/parentCourse/point4a.jpg" },
                            { src: "http://www.pkps.edu.hk/img/website/parentCourse/point4b.jpg" },
                            { src: "http://www.pkps.edu.hk/img/website/parentCourse/point4c.jpg" }],
                        position: "down",
                        imageHeight: "350px"
                    }}
                />

                <PageTitle title='活動相簿' size='h3' sx={{ pt: 3 }} />

                <Paragraph title="2023-2024年度"
                    data={[
                        <Grid item xs={12} sm={12}>
                            <MainCards height="250px" data={EventCardData2324} />
                        </Grid>
                    ]}
                />

                <Paragraph title="2022-2023年度"
                    data={[
                        <Grid item xs={12} sm={12}>
                            <MainCards height="250px" data={EventCardData} />
                        </Grid>,
                        <MaterialList data={[{
                            name: "查看以往的活動相片",
                            src: "http://www.pkps.edu.hk/newhtml/ptaC01_photo.html",
                            icon: <WebIcon sx={{ color: "blue" }} />,
                        }]} />
                    ]}
                />
            </Grid>
        </Grid>
    )
}
