import { SxProps } from '@mui/material';

interface MainSetting {
    borderRadius: string;
    transparentBG: string;
}

interface ResponsiveSettings {
    pc: string;
    mb: string;
}

interface HomePageTheme extends MainSetting {
    height: ResponsiveSettings;
}

//Settings
export const DefaultSetting = {
    borderRadius: "10px",
    transparentBG: "rgba(0,100,205,0.3)",
    mainColor: "#2983cc",
    secondColor: "#2196f3",
    thirdColor: "#4dabf5",
    titleColor: "#000000",
    subtitleColor: "#989898",
    subContentColor: "#666666",
    //
    mainBackgroundColor: "#edf5fd",
    bodyBackgroundColor: "#F5F9FF",
    greyBg: "#FCFCFC",
    darkGreyBg: "#F4F4F8",
    //
    hoverZoomAni: {
        '&:hover': {
            boxShadow: "0 0 10px #D0D0D0",
            transform: "scale(1.01)",
        },
    },
    renderDistance:800,
}

export const TabDefaultSetting = {
    bgColor: "#F0F0F0",
    tabTextSize: "1.0em"
}


export const HomePageSectionOne: HomePageTheme = {
    ...DefaultSetting,
    height: {
        pc: "510px",
        mb: "280px"
    }
}
export const HomePageSectionTwo: HomePageTheme = {
    ...DefaultSetting,
    height: {
        pc: "200px",
        mb: "200px"
    }
}

