import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../layoutPages/components/PageTitle';
import { HaveImage, Paragraph } from '../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../layoutPages/defaultSetting';

import T1 from '../images/Template/T1.jpg';
import T2 from '../images/Template/T2.jpg';
import T3 from '../images/Template/T3.jpg';
import T4 from '../images/Template/T4.jpg';
import T5 from '../images/Template/T5.jpg';

export function TemplateA() {
    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Box>
            <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
                <PageTitle title='Template A' size='h3' subtitle={{ title: "Subtitle", size: 'h5' }} />
                <Grid item xs={10} sm={10}>
                    <Box>
                        <Tabs
                            value={1}
                            onChange={() => { }}
                            variant={isPc ? "fullWidth" : "scrollable"}
                            scrollButtons="auto"
                            sx={{ bgcolor: TabDefaultSetting.bgColor, borderRadius: "35px" }}
                        >
                            <Tab sx={{ fontSize: TabDefaultSetting.tabTextSize }} label="中文" />
                            <Tab sx={{ fontSize: TabDefaultSetting.tabTextSize }} label="英文" />
                            <Tab sx={{ fontSize: TabDefaultSetting.tabTextSize }} label="數學" />
                            <Tab sx={{ fontSize: TabDefaultSetting.tabTextSize }} label="常識" />
                            <Tab sx={{ fontSize: TabDefaultSetting.tabTextSize }} label="視藝" />
                            <Tab sx={{ fontSize: TabDefaultSetting.tabTextSize }} label="資訊科技" />
                        </Tabs>
                        <Paragraph title='數學智多'
                            data={["開展「數學智多星」計畫(小一至小三)，推動同學透過努力服務，完成指定的「服務任務」，幫助同學提升數學能力，建立學生學習數學的自信和成就感。",
                                "幫助同學提升數學能力，建立學生學習數學的自信和成就感。"]
                            }
                            haveImage={{
                                images:[
                                    { src: T4 },
                                ],
                                position:"top"
                            }}
                        />
                        <Divider sx={{p:2}}/>
                        <Paragraph title='數學智多星'
                            data={["開展「數學智多星」計畫(小一至小三)，推動同學透過努力服務，完成指定的「服務任務」，幫助同學提升數學能力，建立學生學習數學的自信和成就感。",
                                "幫助同學提升數學能力，建立學生學習數學的自信和成就感。"]
                            }
                            haveImage={{
                                images:[
                                    { src: T1 },
                                    { src: T2 },
                                    { src: T3 }
                                ],
                                position:"down"
                            }}
                        />
                        <Divider sx={{p:2}}/>
                        <Paragraph title='數學智多星'
                            data={["開展「數學智多星」計畫(小一至小三)，推動同學透過努力服務，完成指定的「服務任務」，幫助同學提升數學能力，建立學生學習數學的自信和成就感。",
                                "幫助同學提升數學能力，建立學生學習數學的自信和成就感。",
                                "幫助同學提升數學能力，建立學生學習數學的自信和成就感。",
                                "幫助同學提升數學能力，建立學生學習數學的自信和成就感。"]
                            }
                            haveImage={{
                                images:[{ src: T5 },],
                                position:"right"
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}