import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import React from 'react';
import { itMenuData } from '../../../data/SubjectMenu/itMenuData';
import { MainCards } from '../../../layoutPages/containers/MainCards';

export function PageItConcern() {

    const isPc = useMediaQuery('(min-width:600px)');
    let dottedListData = ["學生懂得運用免費軟件，提升製作專題研習的能力", "培養學生正確的資訊科技素養",]

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='資訊科技 | 年度關注事項' size='h3' subtitle={{ title: 'CURRENT MAIN CONCERN', size: 'h5' }} />
                <CourseTab data={itMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/it/concern_top.jpg" noImgPopup />

                <Paragraph 
                    data={[
                        <DottedList data={dottedListData} /> ]}

                />

            </Grid>
        </Grid>
    )
}
