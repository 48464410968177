import { Box, Divider, Grid, Link, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import WebIcon from '@mui/icons-material/Web';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { MainCardProps } from '../../../layoutPages/components/MainCard';
import { MaterialList, MaterialWithLink } from '../../../layoutPages/components/MaterialList';
import { gsMenuData } from '../../../data/SubjectMenu/gsMenuData';

export function PageGsResources() {

    const isPc = useMediaQuery('(min-width:600px)');

    const cultureData: Array<MaterialWithLink> = [
        { name: "認識國旗、國徽及區旗、區徽", src: "https://www.edb.gov.hk/tc/curriculum-development/4-key-tasks/moral-civic/newwebsite/flagraising.html", icon: <WebIcon sx={{ color: "blue" }} /> },
        { name: "基本法", src: "https://www.basiclaw.gov.hk/tc/index/", icon: <WebIcon sx={{ color: "blue" }} /> },
        { name: "中國文化研究院", src: "https://chiculture.org.hk/tc", icon: <WebIcon sx={{ color: "blue" }} /> },
        { name: "勵進教育中心(趣看家國)", src: "https://endeavour.org.hk/index.php/china-nowadays/country", icon: <WebIcon sx={{ color: "blue" }} /> },
        { name: "香港地方志中心(香港本土歷史)", src: "https://hkchronicles.org.hk/", icon: <WebIcon sx={{ color: "blue" }} /> },
        { name: "公民教育資源中心", src: "https://www.cpce.gov.hk/resource_centre/tc/index.htm", icon: <WebIcon sx={{ color: "blue" }} /> },
        { name: "網上中華五千年", src: "http://rthk9.rthk.hk/chiculture/fivethousandyears/subpage2.htm", icon: <WebIcon sx={{ color: "blue" }} /> },
        { name: "香港教育大學：看動畫。悟生命。學品德", src: "https://crse.eduhk.mers.hk/", icon: <WebIcon sx={{ color: "blue" }} /> },

    ]

    const otherData: Array<MaterialWithLink> = [
        { name: "香港自遊樂在18區 【齊來18區】", src: "https://www.gohk.gov.hk/tc/index.php", icon: <WebIcon sx={{ color: "blue" }} /> },
        { name: "衞生署中央健康教育組", src: "https://www.chp.gov.hk/tc/index.html", icon: <WebIcon sx={{ color: "blue" }} /> },
        { name: "食物環境衞生署", src: "https://www.fehd.gov.hk/tc_chi/index.html", icon: <WebIcon sx={{ color: "blue" }} /> },
        { name: "漁農自然護理署", src: "https://www.afcd.gov.hk/tc_chi/index.html", icon: <WebIcon sx={{ color: "blue" }} /> },
        { name: "世界自然(香港)基金會", src: "https://www.wwf.org.hk/", icon: <WebIcon sx={{ color: "blue" }} /> },
        { name: "環境保護署", src: "https://www.epd.gov.hk/epd/tc_chi/top.html", icon: <WebIcon sx={{ color: "blue" }} /> },
        { name: "國家地理雜誌兒童網站: 內容包括動物知識英文短片及遊戲", src: "https://kids.nationalgeographic.com/", icon: <WebIcon sx={{ color: "blue" }} /> },

    ]

    const selfLearnData: Array<MaterialWithLink> = [{
        name: "教育出版社",
        src: "https://ebookweb.ephhk.com/login.php",
        icon: <WebIcon sx={{ color: "blue" }} />,
    },
    {
        name: "國情自學平台",
        src: "https://learning.chiculture.org.hk/campaign-ns-education",
        icon: <WebIcon sx={{ color: "blue" }} />,
    },
    {
        name: "小學常識科ETV",
        src: "https://emm.edcity.hk/category/%E5%AD%B8%E7%BF%92%E9%A0%98%E5%9F%9F_%E8%B7%A8%E5%AD%B8%E7%BF%92%E9%A0%98%E5%9F%9F+KLAs_Cross+KLAs%3e%E5%B0%8F%E5%AD%B8%E5%B8%B8%E8%AD%98%E7%A7%91+General+Studies+for+Primary+Schools/172368902",
        icon: <WebIcon sx={{ color: "blue" }} />,
    },
    {
        name: "香港教育城",
        src: "https://www.edcity.hk/home/",
        icon: <WebIcon sx={{ color: "blue" }} />,
    },
    {
        name: "小學常識科家長指南",
        src: "https://www.edb.gov.hk/attachment/tc/curriculum-development/kla/general-studies-for-primary/gs_edb_parent-guildline_8pp.pdf",
        icon: <WebIcon sx={{ color: "blue" }} />,
    },
    ];

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='常識科 | 網上學習資源' size='h3' subtitle={{ title: 'ONLINE LEARNING RESOURCES', size: 'h5' }} />
                <CourseTab data={gsMenuData}/>
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/gs/resources/top.jpg" noImgPopup={true} />

                <Typography variant="h4" align={"left"} sx={{ py: 2, color: "#F02602" }} >網上學習資源  :</Typography>

                <Typography variant="h5" align={"left"} sx={{ py: 2 }} >自學天地：</Typography>
                <MaterialList data={cultureData} />


                <Paragraph title={<Typography variant="h5" align={"left"} sx={{ py: 2 }} >國情自學平台 :</Typography>}
                    data={[<MaterialList data={cultureData} sx={{ml:1}} />]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/gs/resources/resources1.jpg" }],
                        position: "right",
                        imageHeight: "600px",
                    }}
                />

                <Typography variant="h5" align={"left"} sx={{ py: 2 }} >其他學習資源網站：</Typography>
                <MaterialList data={otherData} />



            </Grid>


        </Grid>


    );
}