import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { engMenuData } from '../../../data/SubjectMenu/engMenuData';

export function PageEngConcern() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = [
        "Enhance students’ language competence by cultivating their reading abilities.",
        "Continue to foster students’ self-directed learning capabilities by further implementing the “Assessment as learning” strategies in learning.",
        "Nurture Positive Values and Attitudes in the new era.",

    ];

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <PageTitle title='Current Main Concern' size='h3' />
            <Grid item xs={11} sm={10}>
                <CourseTab data={engMenuData}/>
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/eng/concern/top.jpg" noImgPopup={true} />

            </Grid>

            <Grid item xs={11} sm={10} sx={{ p: 2 }}>
                <DottedList data={dottedListData} variant="number" />
            </Grid>
            



        </Grid>
    )
}
