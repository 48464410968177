import { Box, BoxProps, Card, Fade, SxProps } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { selectComponentStore } from '../../system/PageStore';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from "react-swipeable-views-utils";
import { MainCard } from '../components/MainCard';
import { DefaultSetting } from '../defaultSetting';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export interface ContentSliderProps extends BoxProps{
    children?: React.ReactNode,
    event: (index: number, lastIndex?: number) => {},
    width?: string,
    height: string,
    sx?: SxProps,
    borderRadius?: string,
    noImgPopUp?:boolean,
    interval?:number,
    data: Array<{
        name?: string;
        url: string;
        order?: number;
    }>
}

export function ContentSlider(props: ContentSliderProps) {
    const componentStates = useSelector(selectComponentStore);
    return <Box component="div" onMouseLeave={props.onMouseLeave} onMouseEnter={props.onMouseEnter} sx={{position: "relative", width:1, height:props.height ?? 1,overflow:"hidden", borderRadius:props.borderRadius ?? DefaultSetting.borderRadius}} >
        <AutoPlaySwipeableViews axis="x" interval={props.interval ?? 3000} index={componentStates.HomePageComponentReducer.homePageSliderIndex} enableMouseEvents onChangeIndex={props.event}>
            {props.data &&
                props.data.map((item, index) => (
                    <MainCard caption={item.name} noImgPopup={props.noImgPopUp ?? false} imgHeight={props.height} src={item.url}/>
                ))}
        </AutoPlaySwipeableViews>
        {props.children}
    </Box>
}