import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import EditIcon from '@mui/icons-material/Edit';
import SchoolIcon from '@mui/icons-material/School';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ArticleIcon from '@mui/icons-material/Article';
import PaidIcon from '@mui/icons-material/Paid';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Groups2Icon from '@mui/icons-material/Groups2';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';


import React from 'react';

export interface NewsData {
    onClick: Function,
    content: string,
    date: Date,
    icon: JSX.Element,
}

export const NewsData: Array<NewsData> = [
    {
        onClick: () => window.open("http://www.pkps.edu.hk/pdf/2023-2024/p1-notice.pdf", '_blank', 'noopener,noreferrer'),
        content: "培僑小學後補小一學位申請須知",
        date: new Date("2024-06-05"),
        icon: React.createElement(NewReleasesIcon)
    },

    {
        onClick: () => window.open("http://www.pkps.edu.hk/pdf/2023-2024/reg.pdf", '_blank', 'noopener,noreferrer'),
        content: "2024-2025年度小一入學統一派位註冊備忘",
        date: new Date("2024-06-05"),
        icon: React.createElement(SchoolIcon)
    },
    {
        onClick: () => window.open("https://forms.gle/TpK3PwWYFoybhcYk8", '_blank', 'noopener,noreferrer'),
        content: "開放日網上報名(2024)",
        date: new Date("2024-06-24"),
        icon: React.createElement(SchoolIcon)
    },

];