import { Backdrop, Box, IconButton, Zoom } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch, useSelector } from 'react-redux';
import { selectComponentStore } from '../../system/PageStore';
import { changePopUpStates, closePopUp } from '../../system/Slicer/CommonSlicer';

export interface ImagePopProps {
    src: string;
    onclickClose?: boolean;
    imageOnClick?: () => void;
}

export function ImagePop(props: ImagePopProps) {
    const dispatch = useDispatch();
    const componentStates = useSelector(selectComponentStore);
    const close = () => {
        dispatch(closePopUp());
    }

    return (
        <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={componentStates.CommonComponentReducer.popUpZoomIn}
            onClick={props.onclickClose ? close : () => { }}
        >
            <Zoom timeout={700} in={componentStates.CommonComponentReducer.popUpZoomIn} style={{ transitionDelay: "300ms" }}>
                <IconButton aria-label="cross" onClick={close} component="label" sx={{ position: "absolute", right: "3vw", top: "2vh", color: "#FFFFFF" }}>
                    <CancelIcon fontSize='large' />
                </IconButton>
            </Zoom>
            <Zoom timeout={300} in={componentStates.CommonComponentReducer.popUpZoomIn}>
                <Box
                    component="img"
                    src={props.src}
                    sx={{ borderRadius: "5px", width: "95vw" }}
                    onClick={props.imageOnClick ? props.imageOnClick : () => { }}
                />
            </Zoom>
        </Backdrop>
    );
}