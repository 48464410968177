import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { useDispatch, useSelector } from 'react-redux';
import { homePageSliderMove } from '../../../system/Slicer/HomePageSlicer';
import { selectComponentStore } from '../../../system/PageStore';
import { ContentSlider } from '../../../layoutPages/containers/ContentSlider';
import { chiMenuData } from '../../../data/SubjectMenu/chiMenuData';
//import { DefaultSetting } from '../../../layoutPages/defaultSetting';

export function PageChiBase() {

    const isPc = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch();
    const componentStates = useSelector(selectComponentStore);

    let contentData = [
        { url: "http://www.pkps.edu.hk/img/teachplan/chi/base/top1.jpg" },
        { url: "http://www.pkps.edu.hk/img/teachplan/chi/base/top2.jpg" },
    ];

    const setSlider = (index: number, lastIndex?: number) => {
        let nextIndex = index;
        if (index == contentData.length) nextIndex = 0;
        if (index <= -1) nextIndex = contentData.length - 1;
        dispatch(homePageSliderMove(nextIndex))
        return {}
    }



    let dottedListData = [
        "提高讀寫聽說能力、思維能力、審美能力和自學能力",
        "培養語文學習的興趣、良好的語文學習態度和習慣",
        "培養審美情趣，陶冶性情",
        "培養品德，加強對社群的責任感",
        "體認中華文化，培養對國家、民族的感情"
    ];


    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='中國語文 | 校本課程特色' size='h3' subtitle={{ title: 'SCHOOL-BASED CURRICULUM', size: 'h5' }} />
                <CourseTab data={chiMenuData} />
                <ContentSlider height="500px" sx={{ bottom: "-30px" }} data={contentData} event={setSlider} />

                <Paragraph title="擴展語文的厚度、跨度和溫度，培養學生的語文素養"
                    data={["一、增加學習語文的厚度。學生不僅要學習語文知識，還要累積和運用語言、認識文化。在讀文教學中，我們以「語言建構和運用」為核心目標，以「讀」為主要教學策略，令學生在讀中「學」、「感」、「悟」。",
                        "二、擴大學習語文的跨度。學生除了要學習語文知識外，還要學習各種自學策略、閱讀策略和思維方法。我們通過教學，令學生能把良好的學習習慣、學習方法等遷移到各科的學習中，甚至應用於生活中，建構具跨度的語文課程。",
                        "三、提升學習語文的溫度。課堂上，教師全情投入教學，從而激發學生認真學習，積極參與課堂活動，達致師生共學、互學，營造充滿熱情、具語文味的課堂氛圍。課程中，我們加入大量正向價值觀、品德培養、文化傳承等元素，令語文課程更具溫度。"]} />

                <MainCards data={[
                    {
                        caption: "1.	誦讀經典美文，認識中華文化",
                        src: "http://www.pkps.edu.hk/img/teachplan/chi/base/base1.jpg",
                        noImgPopup: true
                    },
                    {
                        caption: "2. 掌握識字策略，開啟閱讀大門",
                        src: "http://www.pkps.edu.hk/img/teachplan/chi/base/base2.jpg",
                        noImgPopup: true
                    },
                ]} />
                <MainCards data={[
                    {
                        caption: "3.	學習中國語言，感受文字魅力",
                        src: "http://www.pkps.edu.hk/img/teachplan/chi/base/base3.jpg",
                        noImgPopup: true
                    },
                    {
                        caption: "4. 運用閱讀策略，享受閱讀樂趣",
                        src: "http://www.pkps.edu.hk/img/teachplan/chi/base/base4.jpg",
                        noImgPopup: true
                    },
                ]} />
                <MainCards data={[
                    {
                        caption: "5.	了解中華文化，傳承優良傳統",
                        src: "http://www.pkps.edu.hk/img/teachplan/chi/base/base5.jpg",
                        noImgPopup: true
                    },

                ]} />
                <MainCards data={[
                    {
                        caption: "6. 唱兒歌、學拼音，輕鬆學好普通話",
                        src: "http://www.pkps.edu.hk/img/teachplan/chi/base/base6.jpg",
                        noImgPopup: true,
                        imgHeight: "400px"
                    },
                    {
                        caption: "7. 讀帶說、說帶寫，說話寫話多樂趣",
                        src: "http://www.pkps.edu.hk/img/teachplan/chi/base/base7.jpg",
                        noImgPopup: true,
                        imgHeight: "400px"
                    },
                ]} />
            </Grid>

        </Grid>
    )
}
