import { Box, Card, CardMedia, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { MaterialList } from '../../../layoutPages/components/MaterialList';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { gsMenuData } from '../../../data/SubjectMenu/gsMenuData';

export function PageGsConcern() {

    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='常識 | 年度重點項目' size='h3' subtitle={{ title: 'CURRENT MAIN CONCERN', size: 'h5' }} />
                <CourseTab data={gsMenuData}/>
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/gs/concern/top.jpg" noImgPopup={true} />

                <Typography variant="h4" align={"left"} sx={{ py: 2, color: "#F02602" }} >校本課程特色:</Typography>

                <Paragraph title={"STEAM素養"}
                    data={["本校參與「優質教育基金主題網絡先導計劃」，透過機械人活動促進高小STEAM教育。透過手腦並用的活動，發展學生綜合和應用知識與技能的能力。"]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/gs/concern/concern1.jpg" }],
                        position: "right",
                        imageHeight: "400px",
                    }}
                />

                <MainCards  data={[
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/gs/concern/concern2.jpg",
                    },
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/gs/concern/concern3.jpg",
                    },
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/gs/concern/concern4.jpg",
                    },
                ]} />

                <Grid container direction="row" justifyContent="left" alignItems="top" spacing={1} sx={{ mt: 2 }}>


                    <Grid item xs={11} sm={12}>
                        <Typography variant="body1" sx={{ fontSize: 25, my: 2 }} > 比賽花絮</Typography>
                    </Grid>

                    <Grid item xs sm={6}>
                        <Card>
                            <CardMedia height={"300px"} component="iframe" src='https://www.youtube.com/embed/PXuztAQUFq8' />
                        </Card>
                    </Grid>
                    <Grid item xs sm={6}>
                        <Card>
                            <CardMedia height={"300px"} component="iframe" src='https://www.youtube.com/embed/exVZV2hFsXo' />
                        </Card>
                    </Grid>


                </Grid><br></br><br></br>

                <Paragraph title={"提昇國民身份認同"}
                    data={["透過多元的教學策略和自學平台，加深學生對中華文化、國情、國安教育的認識，培養更具國家觀念的新一代。"]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/gs/concern/concern5a.jpg" }],
                        position: "right",
                        imageHeight: "300px",
                    }}
                />

                <MainCards height='500px' data={[
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/gs/concern/concern6.jpg",
                    },
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/gs/concern/concern7.jpg",
                    },
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/gs/concern/concern8.jpg",
                        imgHeight: "310px"
                    },
                ]} />

                <Grid container direction="row" justifyContent="left" alignItems="top" spacing={1} sx={{ mt: 2 }}>


                    <Grid item xs={11} sm={12}>
                        <Typography variant="body1" sx={{ fontSize: 25, my: 2 }} > 筷子
                        </Typography>
                    </Grid>

                    <Grid item xs sm={6}>
                        <Card>
                            <CardMedia height={"300px"} component="iframe" src='https://www.youtube.com/embed/L4UNBges7Pg ' />
                        </Card>
                    </Grid>
                    <Grid item xs sm={6}>
                        <Card>
                            <CardMedia height={"300px"} component="iframe" src='https://www.youtube.com/embed/ouyuWH4zQe8 ' />
                        </Card>
                    </Grid>


                </Grid><br></br><br></br>

                <Typography variant="body1" sx={{ fontSize: 25, my: 2 }} > 水資源
                        </Typography>
                <MaterialList data={[
                                {
                                    name: "陳正翹",
                                    src: "http://www.pkps.edu.hk/img/teachplan/gs/concern/pdf1.pdf",
                                    icon: <PictureAsPdfIcon sx={{ color: "red" }} />
                                }
                            ]}/>

            </Grid>



        </Grid>
    )
}
