import { Button } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { selectComponentStore } from "../../system/PageStore";
import { switchMenuOpen } from "../../system/Slicer/HomePageSlicer";

export function TopMenuButton() {
  const dispatch = useDispatch();
  const componentStates = useSelector(selectComponentStore);

  return <Button
    variant="contained"
    size="small"
    sx={{
      color: 'white',
      backgroundColor: ()=>{return componentStates.HomePageComponentReducer.isDrawerOpened? "" : "black"},
      opacity: ()=>{return componentStates.HomePageComponentReducer.isDrawerOpened? 1 : 0.5},
      //
      position: "fixed",
      top: "8vw",
      boxShadow: 10,
      right: "1vw",
      minHeight:"50px",
      zIndex: 99,
    }}
    onClick={() => { dispatch(switchMenuOpen()); }}
    aria-label="menu">
    {componentStates.HomePageComponentReducer.isDrawerOpened && <CloseIcon />}
    {!componentStates.HomePageComponentReducer.isDrawerOpened && <MenuIcon />}
  </Button>
}