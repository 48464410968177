import { Divider, Grid, Grow, Paper, Skeleton, Slide, Zoom } from "@mui/material";
import { MaterialList, MaterialWithLink } from "../../components/MaterialList";
import { DefaultSetting } from "../../defaultSetting";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import SchoolIcon from '@mui/icons-material/School';
import Groups2Icon from '@mui/icons-material/Groups2';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TvIcon from '@mui/icons-material/Tv';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import EditIcon from '@mui/icons-material/Edit';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import { setMaterialGroupRender, setMaterialGroupSecondCol } from "../../../system/Slicer/HomePageSlicer";
import { selectComponentStore } from "../../../system/PageStore";
import { Box } from "@mui/system";

export function HomePageMaterials() {
    const navigate = useNavigate();
    const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const dispatch = useDispatch();
    const componentStates = useSelector(selectComponentStore);

    const scrollHandler = (_: any) => {
        if (inputRef.current.getBoundingClientRect().top <= DefaultSetting.renderDistance) dispatch(setMaterialGroupRender((true)))
    };
    useEffect(() => {
        window.addEventListener("scroll", scrollHandler, true);
        return () => window.removeEventListener("scroll", scrollHandler, true);
    }, []);


    let dataLeft: Array<MaterialWithLink> = [
        {
            name: "校務報告",
            src: "http://www.pkps.edu.hk/newhtml/login/gs/schp1.htm",
            icon: <PictureAsPdfIcon sx={{ color: "red" }} />
        },
        {
            name: "媒體報道",
            src: "http://www.pkps.edu.hk/newhtml/medium.html",
            icon: <TvIcon sx={{ color: "#ff5667" }} />
        },
        {
            name: "1275旅幼童軍",
            src: "http://www.pkps.edu.hk/newhtml/tj.html",
            icon: <ArchitectureIcon sx={{ color: "#ff6d77" }} />
        },
        {
            name: "培小家長義工",
            src: "/volunteer",
            useNavigate: true,
            icon: <Diversity3Icon sx={{ color: "#ffa070" }} />
        },
        {
            name: "家長教師會",
            src: "http://www.pkps.edu.hk/newhtml/sch6.html",
            icon: <FamilyRestroomIcon sx={{ color: "#ffb834" }} />
        },
        {
            name: "Web Sams",
            src: "https://pkps.sams.edu.hk/jsp/login.jsp",
            icon: <LaptopChromebookIcon sx={{ color: "#ffb459" }} />
        },
        {
            name: "培小校友會",
            src: "http://www.pkps.edu.hk/alumni/index.html",
            icon: <Diversity3Icon sx={{ color: "#fc6" }} />
        },
    ];

    let dataRight: Array<MaterialWithLink> = [
        {
            name: "政策及計劃報告",
            src: "http://www.pkps.edu.hk/poster/20111115/index.htm",
            icon: <SummarizeIcon sx={{ color: "#0039cb" }} />
        },

        {
            name: "學校周期計劃",
            src: "http://www.pkps.edu.hk/pdf/2021-2022/%E5%85%A8%E6%A0%A1%E5%91%A8%E6%9C%9F%E8%A8%88%E5%8A%8321-24.pdf",
            icon: <EditIcon sx={{ color: "blue" }} />
        },
        {
            name: "學校周年計劃",
            src: "http://www.pkps.edu.hk/pdf/2023-2024/2023-2024.pdf",
            icon: <SummarizeIcon sx={{ color: "#4b52f0" }} />
        },


        {
            name: "成功父母學堂",
            src: "/parentCourse",
            useNavigate: true,
            icon: <Groups2Icon sx={{ color: "#0AF" }} />
        },
        {
            name: "學校發展與問責數據平台",
            src: "https://203.198.166.249/school/",
            icon: <PictureAsPdfIcon sx={{ color: "#0CF" }} />
        },
        {
            name: "校本課後學習及支援計劃",
            src: "/supportPlan",
            useNavigate: true,
            icon: <SummarizeIcon sx={{ color: "#1Df" }} />
        },

        {
            name: "i-learner 校園龍虎榜",
            src: "http://www.pkps.edu.hk/php_upload/classRanking/",
            icon: <EmojiEventsIcon sx={{ color: "#1EF" }} />
        },
    ];

    return (

        <Paper ref={inputRef} elevation={0} sx={{ m: 1, p: 2, borderRadius: DefaultSetting.borderRadius }}>
            {
                !componentStates.HomePageComponentReducer.materListGroup &&
                <Skeleton height={"500px"} />
            }
            {componentStates.HomePageComponentReducer.materListGroup &&
                <>
                    <Divider sx={{ p: 2 }}>其他</Divider>
                    <Grid container direction="row" justifyContent="center" alignItems="top" spacing={1}>
                        <Grid item xs={10} sm={6}>
                            <Slide in direction={"right"} timeout={500} addEndListener={() => { setTimeout(() => dispatch(setMaterialGroupSecondCol(true)), 500) }}>
                                <Box>
                                    <MaterialList data={dataLeft} />
                                </Box>
                            </Slide>
                        </Grid>
                        <Grid item xs={10} sm={6}>
                            <Slide in={componentStates.HomePageComponentReducer.materListSecondColumIn} direction={"left"} timeout={500}>
                                <Box>
                                    <MaterialList data={dataRight} />
                                </Box>
                            </Slide>
                        </Grid>
                    </Grid>
                </>
            }
        </Paper>
    )
}