import { Box, Card, CardMedia, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { DefaultSetting, TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { useDispatch, useSelector } from 'react-redux';
import { selectComponentStore } from '../../../system/PageStore';
import WebIcon from '@mui/icons-material/Web';
import { vaMenuData } from '../../../data/SubjectMenu/vaMenuData';
import { MaterialList } from '../../../layoutPages/components/MaterialList';

//import { DefaultSetting } from '../../../layoutPages/defaultSetting';

export function PageVaDisplay() {
    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='視覺藝術| 學生成果展示' size='h3' subtitle={{ title: 'STUDENT DISPLAYS OF WORK', size: 'h5' }} />
                <CourseTab data={vaMenuData} />

                <MaterialList  data={[{
                    name: "學習成果展示",
                    src: "http://www.pkps.edu.hk/arthtml/Art/",
                    icon: <WebIcon sx={{ color: "blue" }} />,
                }]} />

                <Card>
                    <CardMedia height="2000px" component="iframe" src='http://www.pkps.edu.hk/20years/subject/art2.html' />
                </Card>

            </Grid>

        </Grid>
    )
}
