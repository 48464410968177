import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { mathMenuData } from '../../../data/SubjectMenu/mathMenuData';
import { MainCards } from '../../../layoutPages/containers/MainCards';

export function PageMathsObjectives() {

    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <PageTitle title='數學科 | 課程目標' size='h3' subtitle={{ title: 'CURRICULUM OBJECTIVES', size: 'h5' }} />
            <Grid item xs={11} sm={10}>
                <CourseTab data={mathMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/maths/objectives/top.jpg" imgHeight='650px' noImgPopup={true} />
            </Grid>

            <Grid item xs={11} sm={10} sx={{ p: 2 }}>
                <Typography variant="h5" align={"left"} >
                    為了使學生能夠在這個科技與資訊發達的社會從容地應付日後在升學、工作或日常生活方面對數學的需求，並對終身學習有充足的準備，課程在於培養學生以下的能力及態度：
                </Typography>
            </Grid>

            <Grid item xs={11} sm={10} sx={{ p: 2 }}>
                <Paragraph title=""
                    data={[
                        "a. 明辨性思考、創意、構思、探究及數學推理能力，以及利用數學來建立及解決日常生活問題、數學問題、及其他有關學科的問題的能力；",
                        "b. 與別人溝通及能以數學語言清楚和邏輯地表達意見的能力；",
                        "c. 運用數字、符號及其他數學物件的能力；",
                        "d. 建立數字感、符號感、空間感、度量感、鑑賞結構和規律的能力；",
                        "e. 對數學學習採取正面的態度，以及從美學和文化的角度欣賞數學的能力。"
                    ]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/objectives/obj1.jpg" }
                        ],
                        position: "right",

                    }}
                />

                <MainCards  data={[
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/maths/objectives/obj2.jpg",
                    },
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/maths/objectives/obj3.jpg",
                    },
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/maths/objectives/obj4.jpg",
                    },
                ]} />


            </Grid>

        </Grid>
    );
}