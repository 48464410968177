import { Box, Divider, Grid, Tab, Tabs, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, Card, CardMedia, Icon } from '@mui/material';
import { PageTitle } from '../layoutPages/components/PageTitle';
import { TabDefaultSetting, DefaultSetting } from '../layoutPages/defaultSetting';
import StarIcon from '@mui/icons-material/Star';

import T1 from '../images/Template/T1.jpg';
import T2 from '../images/Template/T2.jpg';
import T3 from '../images/Template/T3.jpg';
import T4 from '../images/Template/T4.jpg';
import T5 from '../images/Template/T5.jpg';
import T6 from '../images/Template/T6.jpg';
import { ContentSlider } from '../layoutPages/containers/ContentSlider';
import { selectComponentStore } from "../system/PageStore";
import { useDispatch, useSelector } from 'react-redux';
import { homePageSliderMove } from "../system/Slicer/HomePageSlicer";
import { DottedControl } from '../layoutPages/components/Slider/DottedControl';
import { DottedList } from '../layoutPages/containers/DottedList';
import { MainCard } from '../layoutPages/components/MainCard';
import { color } from '@mui/system';

export function TemplateC() {
    const isPc = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch();
    const componentStates = useSelector(selectComponentStore);

    let contentData = [
        { name: "1A 鍾浩偉", url: T1 },
        { name: "1B 偉鍾浩", url: T2 },
        { name: "1C 浩偉鍾", url: T3 },
        { name: "1D 浩偉鍾", url: T4 },
        { name: "1E 浩偉鍾", url: T5 },
        { name: "1A 浩偉鍾", url: T6 },
    ];

    let dottedListData = [
        "生活課業包括一年級的親子立體模型製作，二年級的月曆與生活計劃和三年級的平面圖形設計。",
        "我們於高小發展了數學科學習歷程檔案。數學科學習歷程檔案是教學及學習的結合，以情境化、脈絡化的學習模式，為學生提供不同的學習經歷，推動學生積極地進行自主學習和自我反思。",
        "推動學生積極地進行自主學習和自我反思。"
    ];

    const setSlider = (index: number, lastIndex?: number) => {
        let nextIndex = index;
        if (index == contentData.length) nextIndex = 0;
        if (index <= -1) nextIndex = contentData.length - 1;
        dispatch(homePageSliderMove(nextIndex))
        return {}
    }


    return (
        <Box>
            <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
                <PageTitle title='Template C' size='h3' subtitle={{ title: "Subtitle", size: 'h5' }} />
                <Grid item xs={10} sm={10}>
                    <ToggleButtonGroup
                        color="primary"
                        value={"1"}
                        fullWidth
                        exclusive
                        onChange={() => { }}
                        aria-label="Platform"
                    >
                        <ToggleButton value="1">一年級</ToggleButton>
                        <ToggleButton value="2">二年級</ToggleButton>
                        <ToggleButton value="3">三年級</ToggleButton>
                        <ToggleButton value="4">四年級</ToggleButton>
                        <ToggleButton value="5">五年級</ToggleButton>
                        <ToggleButton value="6">六年級</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={10} sm={10}>
                    <ContentSlider height="380px" sx={{ bottom: "-30px" }} data={contentData} event={setSlider} />
                </Grid>

                <Grid item xs={10} sm={10} sx={{ height: "80px" }}>
                    <DottedControl
                        boxSx={{ backgroundColor: DefaultSetting.transparentBG }}
                        data={contentData}
                        nowIndex={componentStates.HomePageComponentReducer.homePageSliderIndex}
                        onclick={setSlider}
                    />
                </Grid>
                <Grid item xs={10} sm={10} sx={{ p: 3 }}>
                    <Typography variant="h6" align={"center"} >
                        生活處處是數學，數學源自生活，也應用於生活。
                        培僑小學在低年級設計了生活課業，讓學生經歷不一樣的數學學習歷程，藉此提升學生學習數學的興趣與參與性，培養學生的協作、創作與溝通等能力，擴闊思考。
                    </Typography>
                </Grid>

                <Grid item xs={10} sm={5} sx={{ p: 2 }}>
                    <DottedList data={dottedListData} />
                </Grid>
                <Grid item xs={10} sm={5} sx={{ p: 2 }} textAlign="right">
                    <MainCard src={T1} />
                </Grid>
                <Grid item xs={10} sm={5} sx={{ p: 2 }}>
                    <DottedList data={dottedListData} variant="number" />
                </Grid>
                <Grid item xs={10} sm={5} sx={{ p: 2 }}>
                    <DottedList data={dottedListData} variant={<StarIcon fontSize='small' sx={{color:"red"}} />}/>
                </Grid>
            </Grid>
        </Box>
    );
}