import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import StarIcon from '@mui/icons-material/Star';
import React from 'react';
import { txMenuData } from '../../../data/SubjectMenu/txMenuData';

export function PageTxMode() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = ["紙筆評估:課業、預習、課後延伸、作文、單元綜評、考試卷等;", "技能評估:說話、唱歌、演奏牧童笛、運動技能、生活技能、專題研習、實作評量、學習日誌、科學實驗、口頭報告等;", "態度評估:學習態度、閱讀習慣、個人行為、社交態度、聯課活動表現等。"]
    let dottedListData2 = ["就學生課堂表現給予口頭回饋;", "批改課業時附有印章、貼紙或評語;", "老師在試後進行試卷檢討，分析學生的弱項，設計針對性的跟進教學活動。"];

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={12} sm={10} >
                <PageTitle title='學校評估政策 | 評估摸式' size='h3' subtitle={{ title: 'ASSESSMENT FOR LEARNING', size: 'h5' }} />
                <CourseTab data={txMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/tx/mode/top.jpg" noImgPopup={true}/>

                <Paragraph
                    data={[<DottedList data={dottedListData} variant={<StarIcon fontSize='small' />} />]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/tx/mode/mode1.jpg" },],
                        position: "right",
                        imageHeight: "400px",
                    }}
                />

                <Paragraph
                    data={["教師評估 家長評估 同儕互評 自我評估"]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/tx/mode/mode2.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/tx/mode/mode3.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/tx/mode/mode4.jpg" },],
                        position: "down",
                        imageHeight: "400px",
                    }}
                />

                <Paragraph
                    data={[<DottedList data={dottedListData2} variant={<StarIcon fontSize='small' />} />]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/tx/mode/mode5.jpg" },],
                        position: "right",
                        mainCardProps: {noImgPopup:true}
                    }}
                />

            </Grid>
        </Grid>
    )
}
