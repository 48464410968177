import { Grid, SxProps, Typography } from '@mui/material';
import { DefaultSetting } from '../defaultSetting';

interface PageTitleProps {
    title: string;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    size: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "body1" | "body2";
    subtitle?: PageTitleProps;
    sx?: SxProps;
}

export function PageTitle(props: PageTitleProps) {
    return (<Grid item xs={11} sm={12} sx={{ ...props.sx }}>
        <Typography sx={{ mt: 6, textTransform: "none" }} variant={props.size} align={props.align ?? "center"}>
            {props.title}
        </Typography>
        {
            props.subtitle &&
            <Typography sx={{ m: 2, color: DefaultSetting.subtitleColor, textTransform: "none" }} variant={props.subtitle.size} align={props.align ?? "center"}>
                {props.subtitle.title}
            </Typography>
        }

    </Grid>)
}
