import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { useDispatch, useSelector } from 'react-redux';
import { homePageSliderMove } from '../../../system/Slicer/HomePageSlicer';
import { selectComponentStore } from '../../../system/PageStore';

export function PageUpIndex() {

    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={11} >
                <PageTitle title='生活及情意教育 | 首頁' size='h3' subtitle={{ title: 'SCHOOL-BASED CURRICULUM', size: 'h5' }} />
                <MainCards height='400px' alignItems='stretch' data={[
                    {
                        caption: "國民教育",
                        src: "http://www.pkps.edu.hk/img/teachplan/up/top1.jpg",
                        onClick: () => window.open("http://www.pkps.edu.hk/newhtml/life/demo/edu/objectives.html", '_blank', 'noopener,noreferrer')
                    },
                    {
                        caption: "正向教育",
                        src: "http://www.pkps.edu.hk/img/teachplan/up/top2.jpg",
                        onClick: () => window.open("http://www.pkps.edu.hk/newhtml/life/demo/positive/objectives.html", '_blank', 'noopener,noreferrer')
                    },
                ]} />
            </Grid>

        </Grid>
    )
}
