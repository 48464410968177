import { Card, CardActionArea, CardContent, CardMedia, Typography, SxProps } from '@mui/material';
import { useDispatch } from 'react-redux';
import { changePopUpStates } from '../../system/Slicer/CommonSlicer';
import { DefaultSetting } from '../defaultSetting';

export interface MainCardProps {
    key?: string,
    caption?: React.ReactNode | string,
    src?: string,
    imgHeight?: string;
    sx?: SxProps,
    imgSx?: SxProps,
    noHoverAni?: boolean
    noImgPopup?: boolean;
    onClick?: () => void;
}

export function MainCard(props: MainCardProps) {
    const dispatch = useDispatch();

    let onClick = () => { };
    if (props.onClick) {
        onClick = props.onClick;
    } else if (!props.noImgPopup) { //Default popUp image
        onClick = () => {
            if (props.src && !props.onClick) dispatch(changePopUpStates({ src: props.src }));
        }
    }
    let hover: SxProps = (props.noHoverAni || (props.noImgPopup && !props.onClick)) ? {} : DefaultSetting.hoverZoomAni;

    return (
        <Card
            key={props.key ?? ""}
            sx={{
                boxShadow: "0px 0.3px 1px #C0C0C0",
                ...hover,
                ...props.sx
            }}>
            <CardActionArea onClick={onClick}>
                <CardMedia
                    component="img"
                    src={props.src}
                    referrerPolicy="no-referrer"
                    sx={{ height: props.imgHeight ?? "auto", ...props.imgSx }}
                />
                {props.caption &&
                    <CardContent sx={{ p: 1 }}>
                        {
                            typeof props.caption === "string" ?
                                <Typography align='center' variant="body1" sx={{ textTransform: "none" }}>
                                    {props.caption}
                                </Typography>
                                :
                                props.caption
                        }
                    </CardContent>
                }
            </CardActionArea>
        </Card>
    );
}
