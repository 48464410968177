import { SxProps } from '@mui/system';
import { Typography, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import StarsIcon from '@mui/icons-material/Stars';
import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import { DefaultSetting } from '../defaultSetting';
import { RecordWithTtl } from 'dns';

interface DottedList {
    data: Array<string | React.ReactNode>;
    variant?: React.ReactNode | "number";
}

export function DottedList(props: DottedList) {
    return (<>
        {props.data.map((item, idx) => (
            typeof item === "string" ?
                (
                    <Typography variant="h6" align={"left"} color={DefaultSetting.subContentColor} sx={{ pb: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={0.5}>
                                {!props.variant ? <FiberManualRecordIcon fontSize="small" />
                                    :
                                    typeof props.variant === "string" ? <Typography variant='h6'>{idx + 1}.</Typography>
                                        :
                                        props.variant
                                }
                            </Grid>
                            <Grid item xs>
                                <Typography variant='h6'>{item}</Typography>
                            </Grid>
                        </Grid>
                    </Typography>
                )
                :
                item
        ))}
    </>)
}