import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Grid, Grow, IconButton, Collapse, Backdrop } from '@mui/material';
import { MenuButtons } from './MenuButtons';
import { menuData, subjectMenuData } from '../../data/MenuData';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectComponentStore } from '../../system/PageStore';
import { subjectMenuClick, subjectMenuClose } from '../../system/Slicer/HomePageSlicer';
import { useNavigate } from 'react-router-dom';
import { setSubjectMenuTabIdx } from '../../system/Slicer/CommonSlicer';

function TopMenu() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const componentStates = useSelector(selectComponentStore);

	const handleOpenSubMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		dispatch(subjectMenuClick(event));
	}
	const handleCloseSubMenu = () => {
		dispatch(setSubjectMenuTabIdx(0));
		dispatch(subjectMenuClose());
	};

	return <Box sx={{ flexGrow: 1, height: "64px" }}>
		<AppBar position="fixed">
			<Toolbar>
				<Grid
					container
					direction="row"
					justifyContent="space-evenly"
					alignItems="center"
				>
					<MenuButtons data={menuData} openSubMenu={handleOpenSubMenu} closeSubMenu={handleCloseSubMenu} />
					<Backdrop
						sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
						open={componentStates.HomePageComponentReducer.subjectMenuOpen}
						onClick={handleCloseSubMenu}
					>
						<Menu
							id="subject-menu"
							anchorEl={componentStates.HomePageComponentReducer.subjectMenuAnchorEl}
							open={componentStates.HomePageComponentReducer.subjectMenuOpen}
							onClose={handleCloseSubMenu}
							onMouseLeave={handleCloseSubMenu}
						>
							{
								subjectMenuData.map((data) => (
									<MenuItem sx={{ pl: 3, pr: 3, pb: 2 }} onClick={() => { handleCloseSubMenu(); navigate(data.path) }} >
										{data.display}
									</MenuItem>
								))
							}
						</Menu>
					</Backdrop>
				</Grid>
			</Toolbar>
			<Box sx={{height: "2px", width:"100vw", backgroundColor:"#0052bc"}}/>
		</AppBar>
	</Box>
}
export default TopMenu;