import { Avatar, Box, CardHeader, Divider, Grid, List, Paper, SxProps, Typography, ListItem, ListItemAvatar, ListItemText, Button } from '@mui/material';
import { grey } from "@mui/material/colors";
import { NewsData } from "../../data/NewsData";
import { ResponsiveStyleValue } from '@mui/system';
import { DefaultSetting, HomePageSectionOne } from '../defaultSetting';
import { SafetyDivider } from '@mui/icons-material';
import * as Lodash from "lodash";
import { yyyymmdd } from '../../system/Features/YYYYMMDD';


interface ListBoxProps {
    data: Array<NewsData>,
    borderRadius?: string,
    sx?: SxProps,
    height?: ResponsiveStyleValue<string | number>,
}

export function NewsBox(props: ListBoxProps) {

    return <Box sx={{ backgroundColor: DefaultSetting.mainBackgroundColor, height: props.height, ...props.sx }} borderRadius={DefaultSetting.borderRadius}>
        {props.data &&
            <Box sx={{ p: 1 }}>
                <Grid container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                >
                    <Typography variant="h6" component="div" sx={{ color: DefaultSetting.mainColor, textAlign: "left" }}>
                        &#128240; 最新資訊
                    </Typography>
                    <Button sx={{ p: 0 }}>
                        {
                            // <Typography sx={{ textAlign: "right" }} variant="caption">看更多</Typography> 
                        }
                    </Button>
                </Grid>
                <Divider />
                <List>
                    {Lodash.take(NewsData, 6).map((item: NewsData, idx: number) => (
                        <>
                            <ListItem onClick={() => item.onClick()} sx={{ cursor: "pointer", borderRadius: "10px", ...DefaultSetting.hoverZoomAni }}>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: DefaultSetting.mainColor }}>{item.icon}</Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={item.content} secondary={yyyymmdd(new Date(item.date))} />
                            </ListItem>
                            {
                                (idx <= 4) &&
                                <Divider variant="inset" component="li"/>
                            }
                        </>
                    ))}
                </List>
            </Box>

        }
    </Box>
}