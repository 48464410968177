import { Card, Grid, Typography, Button, CardActionArea, CardMedia } from '@mui/material';
import { ParagraphProps, ParagraphOnlyText } from './Paragraph';
import { MainCard } from '../../components/MainCard';

export function ParagraphWithImageDown
    (props: ParagraphProps) {
    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={11} sm={12}>
                <ParagraphOnlyText {...props} />
            </Grid>
            {
                props.haveImage!.images!.map((item, index) => (
                    <Grid item xs={6} sm>
                        <MainCard src={item.src} imgHeight={props.haveImage?.imageHeight} {...props.haveImage?.mainCardProps}/>
                    </Grid>
                ))
            }

        </Grid>
    );
}