import { MenuButtonData } from '../../system/Classes/MenuButtonData';

export const itMenuData: Array<MenuButtonData> = [
    {display:"課程目標", path:"/courseTarget/ItObjectives"},
    {display:"校本課程特色", path:"/courseTarget/ItBase"},
    {display:"年度重點項目", path:"/courseTarget/ItConcern"},
    {display:"教師專業發展", path:"/courseTarget/ItDevelopment"},
];






