import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { useDispatch, useSelector } from 'react-redux';
import { gsMenuData } from '../../../data/SubjectMenu/gsMenuData';
//import { DefaultSetting } from '../../../layoutPages/defaultSetting';

export function PageGsDevelopment() {

    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='常識科 | 教師專業發展' size='h3' subtitle={{ title: 'PROFESSIONAL DEVELOPMENT OF TEACHERS', size: 'h5' }} />
                <CourseTab data={gsMenuData}/>

                <MainCard src="http://www.pkps.edu.hk/img/teachplan/gs/development/top.jpg" noImgPopup={true} />

                <Paragraph title={<Typography variant="h4" align={"left"} sx={{ py: 2, color: "#F02602" }} >教師專業發展:</Typography>}
                    data={["自2021學年起，本校參與優質教育基金主題網絡先導計劃，發展高小機械人課程。"]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/gs/development/dev1.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/gs/development/dev2.jpg" }],
                        position: "down",
                        imageHeight: "400px",
                    }}
                /><br></br><br></br><br></br>

                <MainCard src="http://www.pkps.edu.hk/img/teachplan/gs/development/dev3.jpg" noImgPopup={true} />
                <MainCard src="http://www.pkps.edu.hk/09-10%20teachplan/final/images/056b.jpg" noImgPopup={true} />

            </Grid>

        </Grid>
    )
}
