import { Navigate } from "react-router-dom";
import { MenuButtonData, MenuButtonLinkType } from "../system/Classes/MenuButtonData";

export const menuData: Array<MenuButtonData> = [
    { display: "首頁", path: "home", type: MenuButtonLinkType.Navigate },
    { display: "學校簡介", path: "/intro/principal", type: MenuButtonLinkType.Navigate, },
    { display: "教學與發展", path: "test", type: MenuButtonLinkType.SubMenu },
    { display: "校外評估報告", path: "http://www.pkps.edu.hk/pdf/2022-2023/report.pdf", type: MenuButtonLinkType.WindowOpen },
    { display: "入學資訊", path: "/enrollment/features", type: MenuButtonLinkType.Navigate,  },
    { display: "校內圖書館", path: "http://eclass.pkps.edu.hk/home/elibplus/", type: MenuButtonLinkType.WindowOpen },
    /*{ display: "校內圖書館", path: "http://www.pkps.edu.hk/newhtml/lib.html", type: MenuButtonLinkType.WindowOpen },*/
];

export const subjectMenuData: Array<MenuButtonData> = [
    { display: "中文", path: "/courseTarget/ChiObjectives", type: MenuButtonLinkType.Navigate },
    { display: "英文", path: "/courseTarget/EngObjectives", type: MenuButtonLinkType.Navigate },
    { display: "數學", path: "/courseTarget/MathsObjectives", type: MenuButtonLinkType.Navigate },
    { display: "常識", path: "/courseTarget/GsObjectives", type: MenuButtonLinkType.Navigate },
    { display: "普通話", path: "/courseTarget/PthObjectives", type: MenuButtonLinkType.Navigate },
    { display: "音樂", path: "/courseTarget/MusicObjectives", type: MenuButtonLinkType.Navigate },
    { display: "體育", path: "/courseTarget/PeObjectives", type: MenuButtonLinkType.Navigate },
    { display: "生活及情意教育", path: "/courseTarget/UpIndex", type: MenuButtonLinkType.Navigate },
    { display: "視藝", path: "/courseTarget/VaObjectives", type: MenuButtonLinkType.Navigate },
    { display: "資訊科技", path: "/courseTarget/ItObjectives", type: MenuButtonLinkType.Navigate },
    { display: "學校評估政策", path: "/courseTarget/TxDef",type: MenuButtonLinkType.Navigate },
]