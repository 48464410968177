import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { musicMenuData } from '../../../data/SubjectMenu/musicMenuData';
import StarsIcon from '@mui/icons-material/Stars';

export function PageMusicObjectives() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = [
        "發展創造力和評賞音樂的能力，並透過音樂有效地溝通",
        "培養美感及對文化的認識",
        "發展音樂技能和建構音樂知識，並培養正確的價值觀與態度",
        "透過參與音樂活動享受及滿足",
        "培養對音樂的終身興趣,並提高對音樂的重視"
    ];


    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='音樂科 | 課程目標' size='h3' subtitle={{ title: 'CURRICULUM OBJECTIVES', size: 'h5' }} />
                <CourseTab data={musicMenuData}/>
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/music/objectives/obj_top.jpg" imgHeight='600px' noImgPopup={true} sx={{mb:2}}/>

                <Paragraph title="音樂教育可以幫助學生:"
                            data={[
                                <DottedList data={dottedListData} variant={<StarsIcon fontSize="small" />}  />
                            ]}
                            
                            
                        />

             
            </Grid>
        </Grid>
    )
}
