import { MenuButtonData } from '../../system/Classes/MenuButtonData';

export const gsMenuData: Array<MenuButtonData> = [
    {display:"課程目標", path:"/courseTarget/GsObjectives"},
    {display:"校本課程特色", path:"/courseTarget/GsBase"},
    {display:"年度重點項目", path:"/courseTarget/GsConcern"},
    {display:"教師專業發展", path:"/courseTarget/GsDevelopment"},
    {display:"學生成果展示", path:"/courseTarget/GsDisplay"},
    {display:"網上學習資源", path:"/courseTarget/GsResources"},
    
];