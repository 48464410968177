import { ImagePop } from '../layoutPages/containers/ImagePop';

import T1 from '../images/Template/T1.jpg';

export function TempTestPage(){
    return (
        <>
      
        </>
    );
}