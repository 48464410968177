import { MenuButtonData } from '../../system/Classes/MenuButtonData';

export const chiMenuData: Array<MenuButtonData> = [
    {display:"課程目標", path:"/courseTarget/ChiObjectives"},
    {display:"校本課程特色", path:"/courseTarget/ChiBase"},
    {display:"年度重點項目", path:"/courseTarget/ChiConcern"},
    {display:"網上學習資源", path:"/courseTarget/ChiResources"},
    {display:"教師專業發展", path:"/courseTarget/ChiDevelopment"},
    {display:"學生成果展示", path:"/courseTarget/ChiDisplay"},
    {display:"非華語支援計劃", path:"/courseTarget/ChiSupport"}
];
