import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import React from 'react';
import { peMenuData } from '../../../data/SubjectMenu/peMenuData';

export function PagePeDevelopment() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = ["2009 香港教育大學舉辦之校本經驗分享研討會", "2009 香港東亞運動會研討會", "2009 香港中文大學運動科學及體育教育課程", "2010 香港中文大學課外活動課程", "2013 飛盤工作坊", "2014 小型網球工作坊", "2015 閃避球工作坊", "2016 基礎活動學習社群", "校內硏究課"]


    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='體育科 | 教師專業發展' size='h3' subtitle={{ title: 'PROFESSIONAL DEVELOPMENT OF TEACHERS', size: 'h5' }} />
                <CourseTab data={peMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/pe/development/E1.jpg" noImgPopup={true} />

                <Paragraph
                    data={[
                        <DottedList data={dottedListData} />
                    ]
                    }
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/pe/development/E2.jpg" }, 
                        { src: "http://www.pkps.edu.hk/img/teachplan/pe/development/E3.jpg" }],
                        position: "down",
                    }}
                />
                <br></br><br></br>

            </Grid>
        </Grid>
    )
}
