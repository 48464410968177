import { Box, Card, CardMedia, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { useDispatch, useSelector } from 'react-redux';
import { homePageSliderMove } from '../../../system/Slicer/HomePageSlicer';
import { selectComponentStore } from '../../../system/PageStore';
import { ContentSlider } from '../../../layoutPages/containers/ContentSlider';
import { musicMenuData } from '../../../data/SubjectMenu/musicMenuData';
import StarsIcon from '@mui/icons-material/Stars';
import { MaterialList } from '../../../layoutPages/components/MaterialList';
import WebIcon from '@mui/icons-material/Web';
//import { DefaultSetting } from '../../../layoutPages/defaultSetting';

export function PageMusicDisplay() {

    const isPc = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch();
    const componentStates = useSelector(selectComponentStore);

    let contentData = [
        { url: 'http://www.pkps.edu.hk/img/teachplan/music/development/slide1.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/music/development/slide2.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/music/development/slide3.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/music/development/slide4.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/music/development/slide5.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/music/development/slide6.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/music/development/slide7.jpg' },

    ];

    const setSlider = (index: number, lastIndex?: number) => {
        let nextIndex = index;
        if (index == contentData.length) nextIndex = 0;
        if (index <= -1) nextIndex = contentData.length - 1;
        dispatch(homePageSliderMove(nextIndex))
        return {}
    }


    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='音樂科 | 學生成果展示' size='h3' subtitle={{ title: 'STUDENT DISPLAYS OF WORK', size: 'h5' }} />
                <CourseTab data={musicMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/music/display/display_top.jpg" imgHeight='650px' noImgPopup sx={{ mb: 2 }} />

                <Grid container direction="row" justifyContent="left" alignItems="top" spacing={1} sx={{ mt: 2 }}>


                    <Grid item xs={11} sm={12}>
                        <Typography variant="body1" sx={{ fontSize: 25, my: 2 }} > 音樂表演影片</Typography>
                    </Grid>

                    <Grid item xs={11} sm={6}>
                        <Card>
                            <CardMedia height={"300px"} component="iframe" src='https://www.youtube.com/embed/8XMkBYmc598' />
                        </Card>
                    </Grid>
                    <Grid item xs={11} sm={6}>
                        <Card>
                            <CardMedia height={"300px"} component="iframe" src='https://www.youtube.com/embed/nR9unSupD2k' />
                        </Card>
                    </Grid>

                    <Grid item xs={11} sm={6}>
                        <Card>
                            <CardMedia height={"300px"} component="iframe" src='https://www.youtube.com/embed/Ff9MlKRywCA' />
                        </Card>
                    </Grid>
                    <Grid item xs={11} sm={6}>
                        <Card>
                            <CardMedia height={"300px"} component="iframe" src='https://www.youtube.com/embed/eF6dPqcPlYM' />
                        </Card>
                    </Grid>

                    <Grid item xs={11} sm={6}>
                        <Card>
                            <CardMedia height={"300px"} component="iframe" src='https://www.youtube.com/embed/9fLv0lhEwwI' />
                        </Card>
                    </Grid>
                    


                </Grid>



                <Typography variant="body1" sx={{ fontSize: 25, my: 2 }} > 近年獲音樂獎項內容 </Typography>

                <Paragraph title="合唱團"
                    data={["第四屆查篤撐校際兒童粵曲大賽2018：初小梆黃團體組  冠軍",
                        "第五屆查篤撐校際兒童粵曲大賽2019：初小梆黃團體組  亞軍",
                        "第五屆查篤撐校際兒童粵曲大賽2019：高小梆黃團體組  季軍",
                        "第八屆查篤撐校際兒童粵曲大賽2022：初小梆黃團體組  冠軍"]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/music/display/display1.jpg" },],
                        position: "right",
                        imageHeight: "300px"
                    }}
                />

                <MaterialList sx={{ ml: -5 }} data={[{
                    name: "【比賽結果公佈】賽馬會查篤撐兒童粵劇推廣計劃－第八屆校際兒童粵曲大賽",
                    src: "https://www.chadukchang.org/single-post/%E3%80%90%E6%AF%94%E8%B3%BD%E7%B5%90%E6%9E%9C%E5%85%AC%E4%BD%88%E3%80%91%E8%B3%BD%E9%A6%AC%E6%9C%83%E6%9F%A5%E7%AF%A4%E6%92%90%E5%85%92%E7%AB%A5%E7%B2%B5%E5%8A%87%E6%8E%A8%E5%BB%A3%E8%A8%88%E5%8A%83%EF%BC%8D%E7%AC%AC%E5%85%AB%E5%B1%86%E6%A0%A1%E9%9A%9B%E5%85%92%E7%AB%A5%E7%B2%B5%E6%9B%B2%E5%A4%A7%E8%B3%BD",
                    icon: <WebIcon sx={{ color: "blue" }} />,
                }]} />

                <Paragraph title="粵劇班"
                    data={["第五屆查篤撐校際兒童粵曲大賽2019：高小小曲團體組  突出表現獎",
                        "第八屆查篤撐校際兒童粵曲大賽2022：高小小曲團體組  突出表現獎",
                    ]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/music/display/display2.jpg" },],
                        position: "right",
                    }}

                />

                <Paragraph title="敲擊樂隊"
                    data={["第四屆香港敲擊樂大賽（2018-2019）：環保創意敲擊樂組獲銀獎",]}

                />

                <Paragraph title="敲擊樂隊（小組）"
                    data={["樂器設計比賽（2018-2019）小學組 優異獎 ",
                        "樂器設計比賽（2018-2019）小學組優秀表演獎 冠軍",
                    ]}

                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/music/display/display3.jpg" },],
                        position: "right",
                        imageHeight: "380px"
                    }}

                />

                <Paragraph title="直笛隊"
                    data={["第70屆香港學校音樂節（2017-2018） 直笛小組  冠軍及季軍",
                        "聯校音樂大賽2018直笛小組：金獎"]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/music/display/display4.jpg" },],
                        position: "right",
                    }}

                />

                <Paragraph title="手鐘隊（高班）"
                    data={["香港國際手鈴奥林匹克（2018-2019）金獎",
                        "聯校音樂比賽2019手鈴及手鐘組：金獎"]}

                />

                <Paragraph title="手鐘隊（初班）"
                    data={["聯校音樂大賽2019手鈴及手鐘組：銀獎",]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/music/display/display5.jpg" },],
                        position: "right",
                    }}

                />
                <br></br><br></br>
                <Paragraph title="校外演出："
                    data={["合唱團、粵劇班於2019年12月於西九文化區戲曲中心大劇院，參與兒童粵劇《月亮姐姐睡何鄉》第一幕及第七幕表演  （資料來源：《查篤撐兒童粵劇協會》網頁）",]}

                />

<MainCards height='350px' data={[
                    {
                        caption: "培僑小學合唱團 第一幕演出：合唱《燕詩》",
                        src: "http://www.pkps.edu.hk/img/teachplan/music/display/1.png",
                        imgHeight: "400px"
                    },
                    {
                        caption: "培僑粵劇班（初級組）第一幕演出",
                        src: "http://www.pkps.edu.hk/img/teachplan/music/display/2.png",
                        imgHeight: "400px"
                    },
                    {
                        caption: "培僑粵劇班（高級組）第七幕演出",
                        src: "http://www.pkps.edu.hk/img/teachplan/music/display/3.png",
                        imgHeight: "400px"
                    },
                ]} />

                <Typography variant="body1" sx={{ fontSize: 25, my: 2 }} > 照片展示</Typography>

                <ContentSlider height="500px" sx={{ bottom: "-30px" }} data={contentData} event={setSlider} />
            </Grid>

        </Grid>
    )
}


