import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { musicMenuData } from '../../../data/SubjectMenu/musicMenuData';
import StarsIcon from '@mui/icons-material/Stars';

export function PageMusicConcern() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = [
        "優化國歌教學，讓同學提昇唱國歌的技巧，了解國歌的創作背景，並以莊重的態度和舉止歌唱國歌，培養尊重國歌的態度。",
        "持續發展「校本粵劇課程」，讓同學在音樂課內或課外學習粵劇，承傳中國文化。",
        "音樂繪本教學：讓同學透過閲讀或影片，認識中外音樂家和其音樂作品，學習音樂家堅毅的精神，認識音樂作品的情境，培養評賞音樂的能力。",
        "《跨學科課程》透過與中文科和常識科設計以中國文化為教學目標的主題，協作教學，讓學生認識中國藝術文化，培養國民身份認同。",
    ];


    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='音樂科 | 年度重點項目' size='h3' subtitle={{ title: 'CURRENT MAIN CONCERN', size: 'h5' }} />
                <CourseTab data={musicMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/music/concern/concern_top.jpg" noImgPopup={true} sx={{ mb: 2 }} />

                <Paragraph
                    data={[
                        <DottedList data={dottedListData} variant={<StarsIcon fontSize="small" />} />
                    ]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/music/concern/concern1.jpg" },],
                        position: "down",
                    }}
                />


            </Grid>
        </Grid>
    )
}
