import { Grid, SxProps, Grow } from '@mui/material';
import { MainCard, MainCardProps } from '../components/MainCard';
import G1 from "../../images/Slider/G1.png";
import { Box } from '@mui/system';


export const MainCardData: Array<MainCardProps> = [];

interface MainCardsProps {
    height?: string;
    imgSx?: SxProps;
    sx?: SxProps;
    data: Array<MainCardProps>;
    justifyContent?: "flex-start" | "space-between" | "space-evenly";
    alignItems?: "flex-start" | "stretch";
    gridItemSm?: number;
    gridItemSx?: number;
    spacing?: number;
}

export function MainCards(props: MainCardsProps) {
    return (
        <Grid
            container
            direction="row"
            justifyContent={props.justifyContent ?? "space-between"}
            alignItems={props.alignItems ?? "flex-start"}
            spacing={props.spacing ?? 1}
            sx={{ paddingTop: "20px", pb: 3, ...props.sx }}>
            {
                props.data.map((item: MainCardProps, idx) => {
                    var growAniTimeout = 1000 * idx;
                    if (growAniTimeout >= 5000) growAniTimeout = 5000;
                    return (
                        <Grow in timeout={growAniTimeout}>
                            <Grid item xs={props.gridItemSx ?? 5} sm={props.gridItemSm ?? "auto"} alignContent={"stretch"}>
                                <MainCard imgHeight={props.height} {...item} imgSx={{ ...props.imgSx }} />
                            </Grid>
                        </Grow>
                    );
                })
            }
        </Grid>
    )
}