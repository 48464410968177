import { Box, Card, CardMedia, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { chiMenuData } from '../../../data/SubjectMenu/chiMenuData';

export function PageChiDisplay() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = [
        "發展及優化多元評估課程；",
        "發展校本跨課程閱讀；",
        "善用電子軟件，提供更多機會給學生分享學習過程、成果或反思，展現學生「新世代素養」。",
    ];

    let dottedListPoint2 = [
        "教育局建議的小學階段「古詩文建議篇章」融入教學；",
        "豐富「讀經誦典」課程的教學內容，加強對學生的文化熏陶。",
    ];


    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10}>
                <PageTitle title='中國語文 | 學生成果展示' size='h3' subtitle={{ title: 'STUDENT DISPLAYS OF WORK', size: 'h5' }} />
                <CourseTab data={chiMenuData} />

                <Card>
                    <CardMedia height="2000px" component="iframe" src='http://www.pkps.edu.hk/20years/subject/chi2.html' />
                </Card>

            </Grid>
        </Grid>
    )
}
