import { CircularProgress } from "@mui/material";
import { Box, SxProps } from "@mui/system";

interface LoadingBoxProps {
    sx?: SxProps;
    iconSx?: SxProps;
    icon?: React.ReactNode;
}

export function LoadingBox(props: LoadingBoxProps) {
    return (
        <Box sx={{ width: "100%", p: 2, m: 2, textAlign: "center", ...props.sx }}>
            {props.icon ?? <CircularProgress sx={{ ...props.iconSx }} />}
        </Box>
    )
}