import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { useDispatch, useSelector } from 'react-redux';
import { homePageSliderMove } from '../../../system/Slicer/HomePageSlicer';
import { selectComponentStore } from '../../../system/PageStore';
import { ContentSlider } from '../../../layoutPages/containers/ContentSlider';
import { DottedControl } from '../../../layoutPages/components/Slider/DottedControl';
import { chiMenuData } from '../../../data/SubjectMenu/chiMenuData';
//import { DefaultSetting } from '../../../layoutPages/defaultSetting';

export function PageChiSupport() {

    const isPc = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch();
    const componentStates = useSelector(selectComponentStore);

    let contentData = [
        { url: "http://www.pkps.edu.hk/img/teachplan/chi/support/top1.jpg" },
        { url: "http://www.pkps.edu.hk/img/teachplan/chi/support/top2.jpg" },
    ];

    const setSlider = (index: number, lastIndex?: number) => {
        let nextIndex = index;
        if (index == contentData.length) nextIndex = 0;
        if (index <= -1) nextIndex = contentData.length - 1;
        dispatch(homePageSliderMove(nextIndex))
        return {}
    }

    let dottedListData = [
        "提高讀寫聽說能力、思維能力、審美能力和自學能力",
        "培養語文學習的興趣、良好的語文學習態度和習慣",
        "培養審美情趣，陶冶性情",
        "培養品德，加強對社群的責任感",
        "體認中華文化，培養對國家、民族的感情"
    ];
    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='中國語文 | 非華語學生學習中文支援計劃' size='h3' subtitle={{ title: 'EDUCATION SUPPORT PROVIDED FOR NON-CHINESE SPEAKING (NCS) STUDENTS SCHOOL SUPPORT IN CHINESE LEARNING SCHEME', size: 'body1' }} />
                <CourseTab data={chiMenuData} nowIndex={6} />
                <ContentSlider height="500px" sx={{ bottom: "-30px" }} interval={1500} data={contentData}  event={setSlider} noImgPopUp />

                <Typography variant="h5" align={"left"} sx={{ py: 2}} >為支援非華語學生的中文學習,協助他們盡快適應本港的中文課程, 本校有以下措施:</Typography>

                <Paragraph title="1.安排協作教學"
                    data={["在每週兩節中文寫作課中,安排兩位中文老師進行協作教學,協助老師主要負責支援非華語學生。協助老師亦會因應學生的學習情況,增加入課室協助教學的次數,或以抽離形式對有關學生進行小組或個別輔導。"]} />

                <Paragraph title="2. 加強功課輔導堂支援"
                    data={["在功課輔導堂,安排教學助理以小組或個別形式支援非華語學生進行功課輔導。"]} />

                <Paragraph title="3.增設視像課堂"
                    data={["支援非華語學生的老師會因應學生的需要,透過視像課於上課以外的時間進行個別教學及功課輔導。"]} />

                <Paragraph title="4.提供全方位學習活動"
                    data={["本校每年舉辦「中華文化學習週」及「中華文化日」,讓非華語學生 在活動中學習中國文化,豐富非華語學生的中國文化學習經歷。"]} />

                <Paragraph title="5. 增聘老師"
                    data={["增聘教師對中文教材進行調適及剪裁,以更切合非華語學生的學習需要。"]} />

                <Paragraph title="6. 其他"
                    data={["A.定期舉辦家長日,與家長溝通學生的學習情況; ", "B.班主任、科任老師與家長建立有效的溝通渠道,發放學校資訊; ", " C.購買翻譯服務,為非華語家庭提供英文版通告。"]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/chi/support/sup1.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/chi/support/sup2.jpg" }],
                        position: "down",
                        imageHeight: "400px",
                    }} />

                <Typography variant="h5" align={"left"} sx={{ py: 2}} >With reference to the learning progress and needs of NCS students, our school adopts the following modes to enhance the support for the learning of Chinese of NCS students so as to help them to adapt to the Chinese curriculum in Hong Kong:</Typography>

                <Paragraph title="1.Co-teaching/In-class Support"
                    data={["During the two writing lessons every week, 2 Chinese teachers are arranged to conduct co-teaching. The in-class support teacher is mainly responsible for sup porting the NCS students. The number of in-class support is increased according to the learning situations of the NCS students. Pull-out learning may be adopted in a small group or in private."]} />

                <Paragraph title="2.Enhancement in the support of Homework Counselling"
                    data={["During Homework Counselling Classes, teaching assistants are assigned to support NCS students in a small group or in private."]} />

                <Paragraph title="3.The Additional Provision of Video Conferencing Class "
                    data={["According to the need of the students, the teachers of NCS students would offer online classes after normal school hours to conduct teaching or offer homework counselling."]} />

                <Paragraph title="4.The Provision of Life-Wide Learning"
                    data={["Chinese Culture Week and Chinese Culture Day are organized annually to help NCS students to learn Chinese Cultures and enrich their learning experience in Chinese cultures."]} />

                <Paragraph title="5. Appointing Additional Teachers"
                    data={["Additional teachers are appointed to readjust and tailor-make the Chinese curriculum so as to fit the Chinese learning needs of NCS students."]} />

                <Paragraph title="6. Others"
                    data={["A. Parents' Day is arranged regularly in order to enhance the communication between parents and school regarding students' learning progress;", 
                    "An effective communication channel is set up by class teachers and subject teachers to release the information of the school; ", 
                    " C. English notices are offered to NCS families by purchasing trans."]}
                    />
            </Grid>

        </Grid>
    )
}
