import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { musicMenuData } from '../../../data/SubjectMenu/musicMenuData';
import StarsIcon from '@mui/icons-material/Stars';

export function PageMusicBase() {

    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='音樂科 | 校本課程特色' size='h3' subtitle={{ title: 'SCHOOL-BASED CURRICULUM', size: 'h5' }} />
                <CourseTab data={musicMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/music/base/base_top.jpg" imgHeight='650px' noImgPopup={true} sx={{mb:2}}/>

                <Paragraph title="1.綜藝課程："
                    data={["四年級下學期,同學將參與綜藝課程,這課程綜合了音樂，視藝和體育三科。同學按意願分別編入音樂藝術組、形體與舞蹈組、藝術設計組或戲劇組。從音樂藝術課程中，同學透過聆聽、創作和演奏，達至以下學習目標:",
                        "培養評賞音樂的能力，", "培養創意及想像力，", "發展音樂技能與過程，", "認識音樂的情境。"]}
                        haveImage={{
                            images: [{ src: "http://www.pkps.edu.hk/img/teachplan/music/base/base0.jpg" },],
                            position: "right",
                        }}
                />
                <br></br><br></br>
                <Paragraph title="2.校本粵劇課程："
                    data={["同學在音樂課上學唱勵志的兒童粵曲，專業的粵劇導師會進入課堂上音樂粵劇課或舉行粵劇工作坊，粵劇班則在課外舉行，學員透過恆常的培訓，學習「唱、做、唸、打」的粵劇技巧，在學期末參與校內或校外的化妝演出。"]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/music/base/base1.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/music/objectives/obj1.jpg" },],
                        position: "down",
                    }}
                />
                <br></br><br></br>
                <Paragraph title="3.音樂活動:"
                    data={["本校開辦了音樂校隊和樂器班，分別是粵劇班、合唱團、直笛隊、手鐘隊、敲擊樂隊和小提琴班。透過訓練，發展同學音樂的技能，培養堅毅和團隊精神。"]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/music/base/base2.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/music/base/base3.jpg" },],
                        position: "down",
                    }}
                />
                <br></br><br></br>
                <Paragraph title="4.《心曲》校本歌集:"
                    data={["透過唱頌歌曲，傳達中國文化、德育及公民教育的訊息，達至「聲入心通」的果效。全體同學獲校方送贈「心曲歌集」，在音樂課教授，歌曲主題來自五個篇章:「家國情懷」、「人間有情」、「校園是我家」、「五個好習慣」、「珍惜及勵志」，從而培養同學正確的價值觀和態度，提昇國民意識。"]}

                />


            </Grid>

        </Grid>
    )
}
