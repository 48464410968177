import { PlaylistAddOutlined } from "@mui/icons-material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { stat } from "fs";
import { PageStore } from "../PageStore";

//Step 1.
export interface HomePageComponentStatus {
    isLogoFadedIn: boolean;
    isDrawerOpened: boolean;
    isHoverSlider: boolean;
    homePageSliderIndex: number;
    homePageEventGalleryIdx: number;
    homePagePopUpImg: boolean;
    //
    subjectMenuOpen: boolean;
    subjectMenuAnchorEl: Element | null;
    //
    buttonGroupRender: boolean;
    buttonGroupSecondRowIn: boolean;
    materListGroup: boolean;
    materListSecondColumIn: boolean;
    linkGroupRender: boolean;
}
//Step 2.
const initialState: HomePageComponentStatus = {
    isLogoFadedIn: false,
    isDrawerOpened: false,
    isHoverSlider: false,
    homePageSliderIndex: 0,
    homePageEventGalleryIdx: 0,
    homePagePopUpImg: true,
    //
    subjectMenuOpen: false,
    subjectMenuAnchorEl: null,
    //
    buttonGroupRender: false,
    buttonGroupSecondRowIn: false,
    materListGroup: false,
    materListSecondColumIn: false,
    linkGroupRender: false,
};


const HomePageComponentSlicer = createSlice({
    name: 'ComponentSlicer',
    initialState,
    reducers: {
        //Step 3.
        logoAppear: (state) => { state.isLogoFadedIn = true },
        switchMenuOpen: (state) => { state.isDrawerOpened = !state.isDrawerOpened },
        homePageSliderMove: (state, action: PayloadAction<number>) => {
            state.homePageSliderIndex = action.payload
        },
        homePageEventGallerySet: (state, action: PayloadAction<number>) => {
            state.homePageEventGalleryIdx = action.payload
        },
        closeHomePagePopUpImg: (state, action: PayloadAction<Boolean>) => {
            state.homePagePopUpImg = false;
        },
        switchSliderHover: (state) => { state.isHoverSlider = !state.isHoverSlider },
        //
        subjectMenuClick: (state, action) => {
            state.subjectMenuAnchorEl = action.payload.currentTarget;
            state.subjectMenuOpen = true;
        },
        subjectMenuClose: (state) => {
            state.subjectMenuAnchorEl = null;
            state.subjectMenuOpen = false;
        },
        //
        setButtonGroupRender: (state, action) => { state.buttonGroupRender = action.payload },
        setButtonGroupSecondRow: (state, action) => { state.buttonGroupSecondRowIn = action.payload },
        setLinkGroupRender: (state, action) => { state.linkGroupRender = action.payload },
        setMaterialGroupRender: (state, action) => { state.materListGroup = action.payload },
        setMaterialGroupSecondCol: (state, action) => { state.materListSecondColumIn = action.payload },
    }
});
//Step 4.
export const {
    logoAppear,
    switchMenuOpen,
    homePageSliderMove,
    homePageEventGallerySet,
    closeHomePagePopUpImg,
    switchSliderHover,
    //
    subjectMenuClick,
    subjectMenuClose,
    //
    setButtonGroupRender,
    setButtonGroupSecondRow,
    setLinkGroupRender,
    setMaterialGroupSecondCol,
    setMaterialGroupRender
} = HomePageComponentSlicer.actions;
//----------------------
export const HomePageComponentReducer = HomePageComponentSlicer.reducer; //No need to change