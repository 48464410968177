import { Box, Fade, IconButton, SxProps } from '@mui/material';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';

interface ArrowControlProps {
    sx?: SxProps;
    leftClick: React.MouseEventHandler;
    rightClick: React.MouseEventHandler;
}

export function ArrowControl(props: ArrowControlProps) {
    return <Fade in timeout={750}>
        <Box boxShadow={2}>
            <IconButton aria-label="LeftButton" sx={{ position: "absolute", top: "40%", left: "2%", zIndex: 1, color: "white", ...props.sx }} onClick={props.leftClick}>
                <ArrowDropDownCircleOutlinedIcon sx={{ transform: "rotate(90deg) scale(1.5)" }} fontSize="large" />
            </IconButton>
            <IconButton aria-label="RightButton" sx={{ position: "absolute", top: "40%", right: "2%", zIndex: 1, color: "white", ...props.sx }} onClick={props.rightClick}>
                <ArrowDropDownCircleOutlinedIcon sx={{ transform: "rotate(-90deg) scale(1.5)" }} fontSize="large" />
            </IconButton>
        </Box>
        </Fade>
}