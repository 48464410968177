import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { useDispatch, useSelector } from 'react-redux';
import { homePageSliderMove } from '../../../system/Slicer/HomePageSlicer';
import { selectComponentStore } from '../../../system/PageStore';
import { pthMenuData } from '../../../data/SubjectMenu/pthMenuData';

export function PagePthBase() {

    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='普通話 | 校本課程特色' size='h3' subtitle={{ title: 'SCHOOL-BASED CURRICULUM', size: 'h5' }} />
                <CourseTab data={pthMenuData}/>
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/pth/base/top.jpg" noImgPopup={true} imgHeight="450px" />

                <Paragraph title={<Typography variant="h5" align={"left"} sx={{ py: 2, color: "#8c0fa3" }} >班級設置</Typography>}
                    data={["本校一至六年級，每級有兩至三班為「普通話班」，其餘為「廣東話班」。其中，「普通話班」的中文課用普通話作為教學語言，並將普通話教學融入中文教學當中，不另設普通話課；「廣東話班」的中文課仍使用廣東話作為教學語言，每星期另設一至兩堂普通話課讓學生學習普通話。",]} 
                    />

                <Paragraph title={<Typography variant="h5" align={"left"} sx={{ py: 2, color: "#8c0fa3" }} >校本教材</Typography>}
                    data={["為培養一年級學生學習普通話的興趣，同時讓學生在學習普通話初期對普通話拼音有一個基本的認識，我校教師為一年級學生精心製作了校本教材──《兒歌伴你學拼音》。該教材用朗讀兒歌的方式，向學生系統地講授了普通話的所有聲母和韻母的發音及拼讀方式，讓學生在朗讀兒歌的過程中愉快地學習普通話。而我校二年級至六年級的「廣東話班」學生則使用《樂在普通話》作為普通話課的教材。",]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/pth/base/base1.jpg" }],
                        position: "right",
                        
                    }} />

                <Paragraph title={<Typography variant="h5" align={"left"} sx={{ py: 2, color: "#8c0fa3" }} >評估方法</Typography>}
                    data={["本校對學生普通話學習情況的評估方式，除紙筆測試、口語測試外，還包括對學習態度的評估。",]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/pth/base/base2.jpg" }],
                        position: "top",
                        imageHeight: "500px"
                    }}
                />

                <Paragraph title={<Typography variant="h5" align={"left"} sx={{ py: 2, color: "#8c0fa3" }} >語言環境的營造</Typography>}
                    data={["為幫助學生營造良好的普通話語境，所有用普通話教中文的教師及普通話科教師，均會以普通話與學生交談，並盡量使用普通話於早會發言。此外，我校也會定期舉辦各種校內的普通話活動，希望給學生創造更多聽、說普通話的機會。",]}
                    
                />

            </Grid>

        </Grid>
    )
}
