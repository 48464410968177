import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material"
import { ShortCut } from "../system/ShortCut"
import { EventGalleryData } from "../data/EventGalleryData";
import { useGetEventGalleryDataQuery } from "../system/Features/getEventGalleryData";
import { datePhraseForDay } from "../system/DatePhrase";
import { LoadingBox } from "../layoutPages/components/LoadingBox";
import { MainCards } from "../layoutPages/containers/MainCards";
import { DefaultSetting } from "../layoutPages/defaultSetting";
import { MyColorButton } from "../layoutPages/components/MyColorButton";
import { TitleWithDate } from "../layoutPages/containers/TitleWithDate";

export function PageOldEvents() {
    let sortedData: Array<EventGalleryData> = [];
    let eventData = useGetEventGalleryDataQuery("");
    if (eventData.isSuccess){
        eventData.data.forEach(i => sortedData.push(Object.assign({}, i)));
        sortedData.sort((a, b) => datePhraseForDay(b.date).getTime() - datePhraseForDay(a.date).getTime());
        if (sortedData.length > 12) sortedData = sortedData.slice(12);
    }

    return (
        <>
            {(!eventData.isSuccess || !sortedData) ?
                <LoadingBox />
                :
                <Box m={4}>
                    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={0}>
                        <Grid item xs={12} sm={12}>
                            <Grid container alignItems="center" direction="column" justifyContent="center" p={2}>
                                <Typography variant="h4" color={DefaultSetting.mainColor}>
                                    回憶相簿
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <MainCards
                                spacing={2}
                                imgSx={{ height: "225px" }}
                                gridItemSm={4}
                                gridItemSx={6}
                                data={
                                    sortedData!.map(item => {
                                        return ({
                                            ...item,
                                            noImgPopup: true,
                                            onClick: () => ShortCut.windowOpen(item.open),
                                            caption: (
                                                <TitleWithDate align="center" title={item.caption?.toString()!} date={item.date} />
                                            ),
                                        })
                                    })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" direction="column" justifyContent="center" p={2}>
                        <MyColorButton
                            inverted
                            mainColor={DefaultSetting.mainColor}
                            sx={{ padding: 4, paddingTop: 2, paddingBottom: 2 }}
                            onClick={() => { ShortCut.windowOpen("http://www.pkps.edu.hk/newhtml/news/2011beforephoto.html") }}>
                            看更多
                        </MyColorButton>
                    </Grid>
                </Box>
            }
        </>
    )
}