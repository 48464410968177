import { Container, Paper, Grid, Avatar, Button, Fade, Collapse, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { selectComponentStore } from '../../system/PageStore';
import { useSelector, useDispatch } from 'react-redux';
import { logoAppear } from '../../system/Slicer/HomePageSlicer';
import useWindowDimensions from '../../system/Features/WindowDimensions';
import { useNavigate } from 'react-router-dom';

export function TopBanner() {
    //  const { windowWidth, windowHeight } = useWindowDimensions();
    const isPc = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const componentStates = useSelector(selectComponentStore);

    const logoTimeout = setTimeout(() => {
        if(!componentStates.HomePageComponentReducer.isLogoFadedIn) dispatch(logoAppear());
        clearTimeout(logoTimeout);
    }, 500);


    return <Box sx={{ mb: -1 }}>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <Button sx={{ position: "absolute", top: isPc? "9vw" : "7vw", borderRadius: "10px", p: 1 }} onClick={() => navigate("/home")}>
                <Collapse in={componentStates.HomePageComponentReducer.isLogoFadedIn} timeout={1000}>
                    <Fade in={componentStates.HomePageComponentReducer.isLogoFadedIn} timeout={1000}>
                        <Box
                            component="img"
                            sx={{ width: isPc? '10vw' : '13vw'}}
                            alt="PKPS LOGO"
                            src="http://www.pkps.edu.hk/img/website/PKPS_logo.png"
                        />
                    </Fade>
                </Collapse>
            </Button>
        </Grid>
        <Box
            component="img"
            sx={{ width: 1, minHeight: "150px", objectFit:"cover" }}
            alt="BannerBG"
            src="http://www.pkps.edu.hk/img/website/homepageTop.jpg"
        />
    </Box>
}