import { Box, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../layoutPages/components/PageTitle';
import { HaveImage, Paragraph } from '../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../layoutPages/defaultSetting';

import T1 from '../images/Template/T1.jpg';
import T2 from '../images/Template/T2.jpg';
import T3 from '../images/Template/T3.jpg';
import T4 from '../images/Template/T4.jpg';
import T5 from '../images/Template/T5.jpg';


export function TemplateB() {
    const isPc = useMediaQuery('(min-width:600px)');

    return (
        <Box>
            <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
                <PageTitle title='Template B' size='h3' subtitle={{ title: "Subtitle", size: 'h5' }} />
                <Grid item xs={10} sm={10}>
                    <Box>
                        <Paragraph title='數學智多星'
                            data={["開展「數學智多星」計畫(小一至小三)，推動同學透過努力服務，完成指定的「服務任務」，幫助同學提升數學能力，建立學生學習數學的自信和成就感。",
                                "",
                                "幫助同學提升數學能力。"]
                            }
                            haveImage={{
                                images: [
                                    { src: T5 },
                                ],
                                position: "left"
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={10} sm={10}>
                    <Paragraph title='Item List' data={[
                        "完善數學科多元評估的設計、實施過程及評估準則。(小六新課程)",
                        "優化數學科小一數學繪本教學，拓展校本繪本教學策略。",
                        "選取合適的課題，透過課堂教學或課後延伸滲入中國數學歷史的知識，擴闊「家國情懷」之情。",
                        "生活處處是數學，數學源自生活，也應用於生活。培僑小學在低年級設計了生活課業，讓學生經歷不一樣的數學學習歷程。",
                        "生活課業包括一年級的親子立體模型製作，二年級的月曆與生活計劃和三年級的平面圖形設計。"
                    ]} />
                </Grid>
                <Grid item xs={10} sm={10}>
                    <Box>
                        <Paragraph title='數學智多星'
                            data={["開展「數學智多星」計畫(小一至小三)，推動同學透過努力服務，完成指定的「服務任務」，幫助同學提升數學能力，建立學生學習數學的自信和成就感。",
                                "",
                                "幫助同學提升數學能力。"]
                            }
                            haveImage={{
                                images: [
                                    { src: T2 },
                                ],
                                position: "right"
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}