import { PlaylistAddOutlined } from "@mui/icons-material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { stat } from "fs";
import { PageStore } from "../PageStore";
import { ImagePopProps } from "../../layoutPages/containers/ImagePop";

//Step 1.
export interface CommonComponentStatus {
    popUpImageProps?: ImagePopProps;
    popUpZoomIn: boolean;
    subjectMenuTabIndex: number;
    scrollTop: number;
}
//Step 2.
const initialState: CommonComponentStatus = {
    popUpImageProps: undefined,
    popUpZoomIn: false,
    subjectMenuTabIndex: 0,
    scrollTop: 0,
};

const CommonComponentSlicer = createSlice({
    name: 'ComponentSlicer',
    initialState,
    reducers: {
        //Step 3.
        changePopUpStates: (state, action: PayloadAction<ImagePopProps>) => {
            state.popUpZoomIn = true; 
            state.popUpImageProps = action.payload 
        },
        closePopUp: (state) => { 
            state.popUpZoomIn = false 
        },
        setSubjectMenuTabIdx: (state, action) => {
            state.subjectMenuTabIndex = action.payload;
        },
        setScrollTop: (state, action) => {
            state.scrollTop = action.payload
        },
    }
});
//Step 4.
export const {
    changePopUpStates,
    closePopUp,
    setSubjectMenuTabIdx,
    setScrollTop,
} = CommonComponentSlicer.actions;
//----------------------
export const CommonComponentReducer = CommonComponentSlicer.reducer; //No need to change