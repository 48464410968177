import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import React from 'react';
import { peMenuData } from '../../../data/SubjectMenu/peMenuData';

export function PagePeObjectives() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = ["透過各種運動技巧和知識的學習，提升學生體育科素養，建立積極活躍的生活方式，養成積極參與體育活動的生活習慣；", "讓學生認識運動的好處，鼓勵他們在餘暇時恆常地參與體育運動，使他們有健康生活習慣及持續改善身體質素；", "培養學生各種共通能力，鼓勵學生對事情抱績極態度；", "培養學生的體育精神及團體合作精神，鼓勵學生持守正面的價值觀，實踐良好的生活態度和行為；", "培養和增強學生解難和處變的能力，並能持續性發展，使他們能面對人生中的各種變化和挑戰。"]


    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='體育科 | 課程目標' size='h3' subtitle={{ title: 'CURRICULUM OBJECTIVES', size: 'h5' }} />
                <CourseTab data={peMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/pe/objectives/A1.jpg" noImgPopup={true} />

                <Paragraph
                    data={[
                        <DottedList data={dottedListData} />
                    ]
                    }
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/pe/objectives/A2.jpg" }, 
                        { src: "http://www.pkps.edu.hk/img/teachplan/pe/objectives/A3.jpg" }],
                        position: "down",
                    }}
                />
                <br></br><br></br>

            </Grid>
        </Grid>
    )
}
