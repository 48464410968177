import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../layoutPages/components/PageTitle';
import { HaveImage, Paragraph } from '../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../layoutPages/defaultSetting';
import { DottedList } from '../../layoutPages/containers/DottedList';
import { MainCard } from '../../layoutPages/components/MainCard';
import { CourseTab } from '../CourseTarget/menuTab';
import { enrollmentMenuData } from '../../data/enrollmentMenuData';

export function Pagefeatures() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = [
        "著重培養學生閱讀及養成天天閱讀的習慣，帶動全位學習。",
        "家長可為子女選擇以普通話學習中國語文。",
        "校本英語課程(Phonics, Sight Word, PLPR閱讀計)，聘請外籍教師輔導英語學習。",
        "教師專業、有愛心：100%獲教育文憑，95.5%獲大學學位，27%獲得或正修讀碩士學位。經教統局外評獲七項優異(最高等級)，包括：「策劃與行政」、「專業領導」、「學校文化」、「教職員管理」、「課程策劃與管理」、「與家長及外間聯繫」、「自我評估」",
        "以關懷文化推動訓輔結合，培育學生全面發展；開校至今，沒有學生被記缺點或過失。",
        "全語文策略，推動學生「自由寫作」，強調學生多寫多表達，",
        "資訊科技既獨立成科，亦著重與各科結合。學生懂得運用資訊科技作為終身學習的工具。",
        "千禧校舍，設備完善。"
    ];

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >

                <br></br>
                <CourseTab data={enrollmentMenuData} />

                <PageTitle title='本校特色 | 入學資訊' size='h3' sx={{ m: 5 }} />

                <Paragraph
                    instantAppear
                    data={[
                        <DottedList data={dottedListData} />
                    ]}
                />

            </Grid>
        </Grid>
    )
}
