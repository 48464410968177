import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { MainCard, MainCardProps } from '../layoutPages/components/MainCard';
import { PageTitle } from '../layoutPages/components/PageTitle';
import { DottedList } from '../layoutPages/containers/DottedList';
import { MainCards } from '../layoutPages/containers/MainCards';
import { Paragraph } from '../layoutPages/containers/Paragraphs/Paragraph';
import { DefaultSetting } from '../layoutPages/defaultSetting';
import WebIcon from '@mui/icons-material/Web';
import { MaterialList, MaterialWithLink } from '../layoutPages/components/MaterialList';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

export const EventCardData2223: Array<MainCardProps> = [
    {
        caption: "中華文化日",
        src: "https://lh3.googleusercontent.com/pw/AMWts8CsBWICFpvBkpYHQr_XYBzzbfWjTRFfhk-Pk51sWh7DT18GTEc9xBflN4Q6a-HJ866kgQVy8IGVnOGasqPXIRJILgQcZYxrAKWj0ANP4iO872GLt5LDNTY3_RpBhNdF5Bn9SjMs5jpvHFnqiUF5vGpq=w1372-h914-no?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/UuhV5BoCLmd3D7Bg6", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "戶外學習日",
        src: "https://lh3.googleusercontent.com/pw/AMWts8Akj0IcCutUonzL9HTxjZTr4juQ7uWx7k152AMzxr6DOy0i-_hRJH5PLJFCp2BpDIMV5V4eu5sNv3tSogDmhUHXUJY2nKKLcIfJSlMbIqM2vGBhr6-x0JrYNbqP3Gxat_ClUARcaP3cE2aoui2b1IOF=w1372-h914-no?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/74519xzVGp6RnktF9", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "正向挑戰日",
        src: "https://lh3.googleusercontent.com/pw/AMWts8DhqD_y6Vn9f5-r0Cwc3xZiC0KtJ6xNROtBm7ZJ-ANYK9uwf391iUn8U41D9mZQ0epO4Ykw0dHQZ-8TaL4gRyo_I82Pvtz4HFEp5FPn5mp5jWN8l0CxgZtKWDLxSXDysYNj8r3DbVL3AS-BPFpp8VqE=w1372-h914-no?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/3r46JgD5cMs27Qig7", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "靜觀製作心靈樽",
        src: "https://lh3.googleusercontent.com/pw/AMWts8CZbmdBw6HRFGojl7_-BqsFYsTgd_C0IZaPC6_dcazLg-vH02eJVmUZGOYJ0xsfZNH1_rw8l0cR4NfFAgF4Cfhngm_4Y4gP87O1fKkHMR5vIbMM95OYqtXSClYLoGaqnf7buAdV6D0Lc_F60aTatU4h=w1219-h914-no?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/5EkYW11Vqpib12re6", '_blank', 'noopener,noreferrer')
    },


];

export const EventCardData2122: Array<MainCardProps> = [
    {
        caption: "製作心靈樽",
        src: "http://www.pkps.edu.hk/img/website/volunteer/2122a1.jpg",
        onClick: () => window.open("https://photos.app.goo.gl/htxB5Cb3Zed1XEtD9", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "萬聖節",
        src: "https://lh3.googleusercontent.com/pw/AL9nZEVlJ1p2XFr4c7aKr2-Covy6EnuLaMCJ-PqNduPriv04qpMqaljrmhHzh-d4rLhEoDa9ZR2YnDZFG-Xc0MKNUqznh1AiUO4XS1aOHNOq6PtpTWBDn-Q_-ihC4b1pm29NWxJklB48lfNjsBFpVuMiXChR=w562-h375-no?authuser=0",
        onClick: () => window.open("https://photos.app.goo.gl/zYACYSRayjLoXPxZ9", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "聖誕節",
        src: "http://www.pkps.edu.hk/img/website/volunteer/2122a3.jpg",
        onClick: () => window.open("https://photos.app.goo.gl/zTfsMNSboJvqEyPz7", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "童軍領袖",
        src: "http://www.pkps.edu.hk/img/website/volunteer/2122a4.jpg",
        onClick: () => window.open("https://photos.app.goo.gl/ekXLTn2y6Kj8VDzS9", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "故事劇場",
        src: "http://www.pkps.edu.hk/img/website/volunteer/2122a5.jpg",
        onClick: () => window.open("https://photos.app.goo.gl/o2KFKJVQWCzqVBSNA", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "故事爸媽",
        src: "http://www.pkps.edu.hk/img/website/volunteer/2122a6.jpg",
        onClick: () => window.open("https://photos.app.goo.gl/pJAw5iEFmax5nEgbA", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "戶外學習日",
        src: "http://www.pkps.edu.hk/img/website/volunteer/2122a7.jpg",
        onClick: () => window.open("https://photos.app.goo.gl/osjVBGZ6rpPHvgui7", '_blank', 'noopener,noreferrer')
    },

];

export const EventCardData1920: Array<MainCardProps> = [
    {
        caption: "慶祝國慶活動",
        src: "http://www.pkps.edu.hk/photos/20191021/images/image/551A1481.JPG",
        onClick: () => window.open("http://www.pkps.edu.hk/photos/20191021/index.html", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "萬聖節派對",
        src: "http://www.pkps.edu.hk/photos/20191107/images/image/IMG_4946.JPG",
        onClick: () => window.open("http://www.pkps.edu.hk/photos/20191107/index.html", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "綠色校慶",
        src: "http://www.pkps.edu.hk/photos/20191125/images/fullsize/ILFW8646.JPG.JPG",
        onClick: () => window.open("http://www.pkps.edu.hk/photos/20191125/index.html", '_blank', 'noopener,noreferrer')
    },
    {
        caption: "慶祝聖誕",
        src: "http://www.pkps.edu.hk/photos/20200115/images/image/TNC_8069.JPG",
        onClick: () => window.open("http://www.pkps.edu.hk/photos/20200115/index.html", '_blank', 'noopener,noreferrer')
    },
]

export function PageVolunteer() {

    const isPc = useMediaQuery('(min-width:600px)');

    let notice: Array<MaterialWithLink> = [
        {
            name: "家長義工通告",
            src: "http://www.pkps.edu.hk/newhtml/news/Jan/pta2.pdf",
            icon: <PictureAsPdfIcon sx={{ color: "red" }} />
        },
        {
            name: "家長義工 - 何金玲女士心聲",
            src: "http://www.pkps.edu.hk/pdf/h.pdf",
            icon: <PictureAsPdfIcon sx={{ color: "red" }} />
        },
        {
            name: "家長義工 - 張健卿女士心聲",
            src: "http://www.pkps.edu.hk/pdf/c.pdf",
            icon: <PictureAsPdfIcon sx={{ color: "red" }} />
        },
        {
            name: "下載家長義工登記表",
            src: "http://www.pkps.edu.hk/pdf/20090928.pdf",
            icon: <PictureAsPdfIcon sx={{ color: "red" }} />
        },
        {
            name: "家長義工資料",
            src: "http://www.pkps.edu.hk/newhtml/pta/01.jpg",
            icon: <InsertPhotoIcon sx={{ color: "blue" }} />
        },
    ];

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2} sx={{ backgroundColor: DefaultSetting.mainBackgroundColor }} >
            <Grid item xs={11} sm={10} >
                <PageTitle title='培小家長義工' size='h3' />
                <MainCard src="https://lh3.googleusercontent.com/pw/AL9nZEXZKbRFa10799G-yZW5jObZrA8b6hf88OUEGLemWNvu2KxfVM67AmVptm7baY2cj9I-nQTUYbMlBVTSZp98Db7ghxnDjApriTqjnb2YmklVG9XjiFKh8XlZqtc48daOF_w5bjlLIaBPMGRX0QUreNt7=w1289-h491-no?authuser=0" sx={{ mt: 2 }} noImgPopup={true} />


                <PageTitle title='活動相簿' size='h3' sx={{ pt: 3 }} />

                <Paragraph title="2022-2023年度"
                    data={[
                            <MainCards height="250px" gridItemSm={3} data={EventCardData2223} />
                    ]}
                />

                <Paragraph title="2021-2022年度"
                    data={[
                            <MainCards height="250px" gridItemSm={3} data={EventCardData2122} />
                    ]}
                />

               <Paragraph title="2019-2020年度"
                    data={[
                            <MainCards height="250px" gridItemSm={3} data={EventCardData1920} />
                    ]}
                />

                <MaterialList data={[{
                    name: "查看以往的活動相片",
                    src: "http://www.pkps.edu.hk/newhtml/pta01.html",
                    icon: <WebIcon sx={{ color: "blue" }} />,
                }]} />

                <br></br><br></br>

                <PageTitle title='家長義工資訊' size='h3' sx={{ py: 3 }} />

                <MaterialList data={notice} />


            </Grid>
        </Grid>
    )
}
