import { Container, Divider, Grid, Typography, Box } from '@mui/material';
import { DefaultSetting } from '../defaultSetting';
export function Footer() {
    return (
        <Grid container direction="row" bottom={0} justifyContent="center" alignItems="top" spacing={2} pt={2}>
            <Grid item xs={11} sm={11}>
            <Divider sx={{ mb:2 }} />

                <Grid container direction="row" justifyContent="center" alignItems="top" spacing={3} >
                    <Grid item xs={10} sm={5} md={3} lg={2}>
                    <Typography sx={{ fontSize: "0.9em" }}>地址: 小西灣富怡道12號</Typography></Grid>
                    <Grid item xs={10} sm={5} md={3} lg={2}>
                    <Typography sx={{ fontSize: "0.9em" }}>電話:28977866</Typography></Grid>
                    <Grid item xs={10} sm={5} md={3} lg={2}>
                    <Typography sx={{ fontSize: "0.9em" }}>傳真: 28977883</Typography></Grid>
                    <Grid item xs={10} sm={5} md={3} lg={2}>
                    <Typography sx={{ fontSize: "0.9em" }}>電郵︰puikiu@pkps.edu.hk</Typography></Grid>
                </Grid>
                <Typography sx={{ p: 2, fontSize: "0.7em" }} align='center' color={DefaultSetting.subtitleColor}>
                    Copyright © 2023 Pui Kiu Primary School. All rights reserved.</Typography>
            </Grid>
        </Grid>
    );
}