import { MenuButtonData } from '../../system/Classes/MenuButtonData';

export const txMenuData: Array<MenuButtonData> = [
    {display:"評估的定義", path:"/courseTarget/TxDef"},
    {display:"評估摸式", path:"/courseTarget/TxMode"},
    {display:"推行策略", path:"/courseTarget/TxStrategy"},
    {display:"成績表使用說明", path:"/courseTarget/TxDirection"},
    {display:"各科評估比重及項目", path:"/courseTarget/TxItem"},
    {display:"評估參考準則", path:"/courseTarget/TxRef"},
    {display:"家課政策", path:"/courseTarget/TxHomework"},
];

