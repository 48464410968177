import { Box, Card, CardMedia, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { ContentSlider } from '../../../layoutPages/containers/ContentSlider';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { selectComponentStore } from '../../../system/PageStore';
import { CourseTab } from '../menuTab';
import StarsIcon from '@mui/icons-material/Stars';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { MaterialList } from '../../../layoutPages/components/MaterialList';
import { gsMenuData } from '../../../data/SubjectMenu/gsMenuData';

export function PageGsBase() {

    const isPc = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch();
    const componentStates = useSelector(selectComponentStore);

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='常識科 | 校本課程特色' size='h3' subtitle={{ title: 'SCHOOL-BASED CURRICULUM', size: 'h5' }} />
                <CourseTab data={gsMenuData}/>
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/gs/base/top.jpg" noImgPopup={true} />

                <Paragraph title={<Typography variant="h4" align={"left"} sx={{ py: 2, color: "#F02602" }} >校本課程特色:</Typography>}
                    data={["本校強調常識科多元化的學與教策略，以激發學生的學習動機；重視多向的學習評估和多元化的學習經歷，藉此發展學生的共通能力與價值觀。"]} />

                <Paragraph title="生活技能課"
                    data={["為協助學生建立健康的生活習慣及培養良好的自理態度，本校於低小設有生活技能課。"]} />



                <Grid container direction="row" justifyContent="left" alignItems="top" spacing={1} sx={{ mt: 2 }}>
                    <Grid item xs={6} sm={6}>
                        <Paragraph
                            data={["P1 - 正確的刷牙步驟"]}
                            sx={{ fontSize: 25 }}
                            haveImage={{
                                images: [{ src: "http://www.pkps.edu.hk/img/teachplan/gs/base/base1.jpg" },
                                { src: "http://www.pkps.edu.hk/img/teachplan/gs/base/base2.jpg" }],
                                position: "down",
                                imageHeight: "450px"
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Paragraph
                            data={["P2 - 正確的洗手步驟"]}
                            sx={{ fontSize: 25 }}
                            haveImage={{
                                images: [{ src: "http://www.pkps.edu.hk/img/teachplan/gs/base/base3.jpg" },
                                { src: "http://www.pkps.edu.hk/img/teachplan/gs/base/base4.jpg" }],
                                position: "down",
                                imageHeight: "450px"
                            }}
                        />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                        <Paragraph
                            data={["P3 - 摺疊衣服、綁鞋帶"]}
                            sx={{ fontSize: 25 }}
                            haveImage={{
                                images: [{ src: "http://www.pkps.edu.hk/img/teachplan/gs/base/base5.jpg" },
                                { src: "http://www.pkps.edu.hk/img/teachplan/gs/base/base6.jpg" }],
                                position: "down",
                                imageHeight: "450px"
                            }}
                        />
                    </Grid>

                </Grid>


                <Paragraph title="專題研習
"
                    data={["以專題研習作為進展性評估。教師按學生的已有知識、興趣和能力，有計劃地引導學生探索求知。透過多元的學習活動（例如：閱讀、訪問、科學探究、 個案研究、實地考察、產品創作、運用資訊科技進行資料蒐集），學生從不同渠道取得及處理多樣化的學習材料，並綜合和運用所學的知識和技能，讓學習更有效和更具意義。"]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/gs/base/base7.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/gs/base/base8.jpg" }],
                        position: "down",
                        imageHeight: "450px"
                    }}

                />

                <Grid container direction="row" justifyContent="left" alignItems="top" spacing={1} sx={{ mt: 2 }}>
                    <Grid item xs={6} sm={6}>
                        <Paragraph
                            data={["P1 - 我的家"]}
                            sx={{ fontSize: 25 }}
                            haveImage={{
                                images: [{ src: "http://www.pkps.edu.hk/img/teachplan/gs/base/base9a.jpg" },
                                { src: "http://www.pkps.edu.hk/img/teachplan/gs/base/base9b.jpg" }],
                                position: "down",
                                imageHeight: "450px"
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Paragraph
                            data={["P2 - 小記者遊社區"]}
                            sx={{ fontSize: 25 }}
                            haveImage={{
                                images: [{ src: "http://www.pkps.edu.hk/img/teachplan/gs/base/base9c.jpg" },
                                { src: "http://www.pkps.edu.hk/img/teachplan/gs/base/base9d.jpg" }],
                                position: "down",
                                imageHeight: "450px"
                            }}
                        />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                        <Paragraph
                            data={["P3 - 保溫神器"]}
                            sx={{ fontSize: 25 }}
                            haveImage={{
                                images: [{ src: "http://www.pkps.edu.hk/img/teachplan/gs/base/base9e.jpg" },
                                { src: "http://www.pkps.edu.hk/img/teachplan/gs/base/base9f.jpg" }],
                                position: "down",
                                imageHeight: "350px"
                            }}
                        />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                        <Paragraph
                            data={["P4 - 製作機械人"]}
                            sx={{ fontSize: 25 }}
                            haveImage={{
                                images: [{ src: "http://www.pkps.edu.hk/img/teachplan/gs/base/base9g.jpg" },
                                { src: "http://www.pkps.edu.hk/img/teachplan/gs/base/base9h.jpg" }],
                                position: "down",
                                imageHeight: "350px"
                            }}
                        />

                    </Grid>


                    <Grid item xs={11} sm={12}>
                        <Typography variant="body1" sx={{ fontSize: 25, my: 2 }} >P5 - 電子抗疫卡</Typography>
                    </Grid>

                    <Grid item xs sm={6}>
                        <Card>
                            <CardMedia height={"300px"} component="iframe" src='https://www.youtube.com/embed/2IL4xTbdhkg' />
                        </Card>
                    </Grid>
                    <Grid item xs sm={6}>
                        <Card>
                            <CardMedia height={"300px"} component="iframe" src='https://www.youtube.com/embed/WtS-Qa1gBZs' />
                        </Card>
                    </Grid>

                    <Grid item xs={11} sm={12}>
                        <Typography variant="body1" sx={{ fontSize: 25, my: 2 }} >P6 - 智慧城市</Typography>
                    </Grid>

                    <Grid item xs sm={6}>
                        <MaterialList data={[
                            {
                                name: "6C 01 陳翰林無人駕駛系統",
                                src: "http://www.pkps.edu.hk/img/teachplan/gs/base/pdf1.pdf",
                                icon: <PictureAsPdfIcon sx={{ color: "red" }} />
                            }
                        ]}/>
                    </Grid>
                    <Grid item xs sm={6}>
                    
                        <MaterialList data={[
                                {
                                    name: "6A 07關匡彤",
                                    src: "http://www.pkps.edu.hk/img/teachplan/gs/base/pdf2.pdf",
                                    icon: <PictureAsPdfIcon sx={{ color: "red" }} />
                                }
                            ]}/>
                    
                    </Grid>

                </Grid>



            </Grid>

        </Grid>
    )
}
