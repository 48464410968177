import styled from "@emotion/styled";
import { Button, ButtonProps } from "@mui/material";

interface mainColorButtonProps {
    mainColor: string;
    inverted?: boolean;
}

export const MyColorButton = styled(Button)<ButtonProps & mainColorButtonProps>(({ mainColor, inverted }) => (
    inverted ?
        {
            backgroundColor: mainColor,
            color: "white",
            borderColor: "white",
            '&:hover': {
                borderColor: mainColor,
                color: mainColor,
                backgroundColor: "white",
                transition: "color .3s ease-in-out, .3s ease-in-out;",
                transform: "scale(1.1)",
            }
        }
        :
        {
            backgroundColor: "white",
            color: mainColor,
            borderColor: mainColor,
            '&:hover': {
                backgroundColor: mainColor,
                borderColor: "white",
                color: "white",
                transition: "color .3s ease-in-out, .3s ease-in-out;",
                transform: "scale(1.05)",
            },
        }
));