import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import { DefaultSetting } from '../layoutPages/defaultSetting';
import { Box, Button, Card, CardMedia, Grid } from '@mui/material';
import { PageTitle } from '../layoutPages/components/PageTitle';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import { ShortCut } from '../system/ShortCut';

export function PageTvStation() {

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <br></br>
                <PageTitle title='培小電視台' size='h3' sx={{ m: 5, color: DefaultSetting.mainColor }} />
            </Grid>
            <Grid item xs={11} sm={10} sx={{ backgroundColor: "#f0f0f0", }}>
                <Timeline sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                    },
                }}>
                    {MakeTimeLimeItem("2023 - 9 - 19",  "MkRUA57wT3Q")}
                    {MakeTimeLimeItem("2023 - 9 - 3",  "PxGRQbIrUbc")}
                    {MakeTimeLimeItem("2023 - 6 - 16", "gSPlo1TOpns")}
                    {MakeTimeLimeItem("2023 - 7 - 22", "rciTeERWSgY")}
                    {MakeTimeLimeItem("2023 - 6 - 25", "AC0QKIuN5Go")}
                    {MakeTimeLimeItem("2023 - 4 - 3",  "tYj2a__tC8Q")}
                    {MakeTimeLimeItem("2023 - 3 - 14", "PRffztOrwZU")}
                    {MakeTimeLimeItem("2023 - 3 - 14", "87uem0pbz6E")}
                    {MakeTimeLimeItem("2023 - 2 - 9",  "4g6-2NL7ukQ")}
                    {MakeTimeLimeItem("2023 - 1 - 17", "oUmJe9bZcWU")}
                    {MakeTimeLimeItem("2022 - 12 - 25","kzNgKvRX3ak")}
                    {MakeTimeLimeItem("2022 - 9 - 1",  "X-lWv2QAzIA")}
                </Timeline>

                <Box sx={{ width: "100%", alignItems: "center", display: "flex", justifyContent: "center" }}>
                    <Button sx={{
                        m: 2
                    }}
                        variant='contained'
                        onClick={() => { ShortCut.windowOpen("http://www.pkps.edu.hk/TV/index.html") }}>看更多</Button>
                </Box>
            </Grid>
        </Grid>
    );
}


export function MakeTimeLimeItem(date: React.ReactNode | string, content: React.ReactNode | string) {
    return (
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector color="primary" />
            </TimelineSeparator>
            <TimelineContent color="text.secondary" sx={{ fontSize: "1.5em" }}>
                {
                    date
                }
                <Card sx={{ borderRadius: DefaultSetting.borderRadius, width: "90%", m: 3, ml: 6 }}>
                    <CardMedia sx={{ m: "-2px" }} height="415px" component="iframe" allowFullScreen src={'https://www.youtube.com/embed/' + content} />
                </Card>
            </TimelineContent>
        </TimelineItem>
    );
}