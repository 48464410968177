import { EventGalleryData } from './../../data/EventGalleryData';
//cspell:ignore reduxjs
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const getEventGalleryDataApi = createApi({
    reducerPath: "eventGalleryData",
    baseQuery: fetchBaseQuery(
        { baseUrl: 'http://www.pkps.edu.hk/data/eventGalleryData.json', mode: "cors",cache:"no-store" },
        
    ),
    endpoints: (builder) => ({
        getEventGalleryData: builder.query<Array<EventGalleryData>, ''>({
            query: (name) => name,
        }),
    }),
})

export const { useGetEventGalleryDataQuery } = getEventGalleryDataApi

//no-cors