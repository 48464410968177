import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { gsMenuData } from '../../../data/SubjectMenu/gsMenuData';


export function PageGsObjectives() {
    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = [
        "保持健康的個人發展,成為充滿自信、理性和富責任感的公民。",
        "認識自己在家庭和社會所擔當的角色及應履行的責任,並關注共同的福祉。",
        "培養對國民身份的認同感,並致力貢獻國家和世界。",
        "培養對自然及科技世界的興趣和好奇心,了解科學與科技發展對社會的影響。",
        "關心及愛護周遭的環境,實踐綠色生活。"
    ];


    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='常識科 | 課程目標' size='h3' subtitle={{ title: 'CURRICULUM OBJECTIVES', size: 'h5' }} />
                <CourseTab data={gsMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/gs/objectives/top.jpg" noImgPopup={true} />

                <Paragraph title="常識科課程旨在協助學生:" 
                    data={[
                        <DottedList data={dottedListData} />
                    ]}
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/gs/objectives/obj1.jpg" },
                        ],
                        position: "right",
                        imageHeight: "550px",
                    }}
                />


                <MainCards  data={[
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/gs/objectives/obj2.jpg",
                    },
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/gs/objectives/obj3.jpg",
                    },
                ]} />           </Grid>
        </Grid>
    )
}
