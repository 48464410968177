import { Box, Button, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../layoutPages/components/PageTitle';
import { HaveImage, Paragraph } from '../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../layoutPages/defaultSetting';
import { DottedList } from '../../layoutPages/containers/DottedList';
import { MainCard } from '../../layoutPages/components/MainCard';
import { CourseTab } from '../CourseTarget/menuTab';
import { MaterialList } from '../../layoutPages/components/MaterialList';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { introMenuData } from '../../data/intoMenuData';
import StarsIcon from '@mui/icons-material/Stars';

export function PageConcept() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListDataPoint1 = [
        "促進學生、教職員及家長成為自發的、自信的、持續的終身學習者。",
        "使學生、教職員及家長獲得成功，進而能貢獻社會及國家。",
        "使各成員能積極進取、善於溝通、懂得欣賞及關懷，從而能面對生命歷程上的各種挑戰。",
        "使各成員能夠互相學習、互相提升。"
    ];

    let dottedListDataPoint2 = ["人天生有學習的動力並且都能夠學習。", "每一個人都是獨特的並且具備同等的價值。", "學習是學習者與人、物接觸及互動，並內化建構的過程。", "無論是「成功」還是「失敗」的經驗都能轉化出正面的價值，最終能自我提升，增強自信。", "人與人之間不但透過言談，更透過行動互相影響；教師及家庭成員的言行，對學生的學習態度有極其重要的影響。", "讓學生知道教師及家長的期望，同時讓家長知道被愛及關懷是推動學生學習的重大力量。", "學生個人的努力、學校與家庭的溝通及協作是影響學生學習成敗的兩大因素。", "善用「回饋」，使人學得更好。批評與讚賞都是回饋的一種方式。分歧能豐富人們的觀點、擴闊視野。", "教育能促進個人的進步、社會及國家的繁榮富強，而關心社會及國家大事進而貢獻自己的力量是每一個公民應有的義務。", "廿一世紀的教育必須培養學生應變及解難的能力，讓他們有廣闊的視野。", "善於與人合作及具備團隊協作的能力是達致成功的重要階梯。", "人應該自愛及互相關懷。"]

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >

                <br></br>
                <CourseTab data={introMenuData} />

                <PageTitle title='辦學理念 | 學校簡介' size='h3' sx={{ m: 5 }} />

                <Paragraph title="我們的願景"
                    instantAppear
                    data={["我們致力使培僑小學成為一個能夠不斷反思、探索、更新及追求卓越的學習社群。這個社群以學生為主體，包括教職員及家長等學習者。我們亦致力使它成為一個學習者之間互相學習及互相關懷的地方。"]}
                /><br></br>

                <Paragraph title="我們的使命"
                    data={["為了達致上述的願景，我們的使命是提供各種有利的條件，藉以："]}
                />

                <DottedList data={dottedListDataPoint1} variant={<StarsIcon />} /><br></br>

                <Paragraph 
                    title="我們的信念"
                    data={["我們相信："]}
                />

                <DottedList data={dottedListDataPoint2} variant={<StarsIcon />} />



            </Grid>
        </Grid>
    )
}
