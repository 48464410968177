import { Box, Divider, Skeleton } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectComponentStore } from "../../../system/PageStore";
import { ShortCut } from "../../../system/ShortCut";
import { setLinkGroupRender } from "../../../system/Slicer/HomePageSlicer";
import { MainCardProps } from "../../components/MainCard";
import { DefaultSetting } from "../../defaultSetting";
import { MainCards } from "../MainCards";

export function HomePageUseFullLinkGroup() {
    const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const dispatch = useDispatch();
    const componentStates = useSelector(selectComponentStore);
    const navigate = useNavigate();
    const scrollHandler = (_: any) => {
        if (inputRef.current.getBoundingClientRect().top <= DefaultSetting.renderDistance) dispatch(setLinkGroupRender((true)))
    };
    useEffect(() => {
        window.addEventListener("scroll", scrollHandler, true);
        return () => {
            window.removeEventListener("scroll", scrollHandler, true);
        };
    }, []);

    let data: Array<MainCardProps> = [
        {
            key: "Support",
            src: "http://www.pkps.edu.hk/img/website/homePage/cover/support2223.jpg",
            onClick: () => ShortCut.windowOpen("http://www.pkps.edu.hk/pdf/2023-2024/support.pdf")
        },
        {
            key: "star",
            src: "http://www.pkps.edu.hk/img/website/homePage/cover/star.png",
            onClick: () => ShortCut.windowOpen("https://star.hkedcity.net/zh-hant")
        },
        {
            key: "midStory",
            src: "http://www.pkps.edu.hk/img/website/homePage/cover/midStoryCover.png",
            onClick: () => ShortCut.windowOpen("http://www.pkps.edu.hk/newhtml/login/story/story.htm")
        },
        {
            key: "edcity",
            src: "http://www.pkps.edu.hk/img/website/homePage/cover/edcity.png",
            onClick: () => ShortCut.windowOpen("https://www.edcity.hk/home/")
        },
        {
            key: "pcFamily",
            src: "http://www.pkps.edu.hk/img/website/homePage/cover/pcfamily.webp",
            onClick: () => ShortCut.windowOpen("https://hk.drpcfamily.com.hk/login/")
        }
        ,
        {
            key: "boardGame",
            src: "http://www.pkps.edu.hk/img/website/homePage/cover/bg.jpg",
            onClick: () => ShortCut.windowOpen("https://drive.google.com/drive/folders/1_MSCVAgP4Q1MXl7hiiaM-_RD9VfT_Yd2?usp=sharing")
        }
    ]

    return (
        <Box ref={inputRef} className="HomePageLinkGroup">
             {
                !componentStates.HomePageComponentReducer.linkGroupRender &&
                <Skeleton height={"200px"} />
            }
            {
                componentStates.HomePageComponentReducer.linkGroupRender &&
                <Box>
                    <Divider>連結</Divider>
                    <MainCards height="85px" justifyContent="space-evenly" alignItems="stretch" imgSx={{ maxWidth: "43vw", minWidth: "180px" }} data={data} />
                </Box>
            }
        </Box>
    )
}