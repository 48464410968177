import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { MainCard, MainCardProps } from '../layoutPages/components/MainCard';
import { PageTitle } from '../layoutPages/components/PageTitle';
import { DottedList } from '../layoutPages/containers/DottedList';
import { MainCards } from '../layoutPages/containers/MainCards';
import { Paragraph } from '../layoutPages/containers/Paragraphs/Paragraph';
import { DefaultSetting } from '../layoutPages/defaultSetting';
import WebIcon from '@mui/icons-material/Web';
import { MaterialList, MaterialWithLink } from '../layoutPages/components/MaterialList';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';


export function PageSupportPlan() {

    const isPc = useMediaQuery('(min-width:600px)');

    let notice: Array<MaterialWithLink> = [
        {
            name: "2022-2023校本課後學習及支援計劃_活動計劃表",
            src: "http://www.pkps.edu.hk/pdf/2023-2024/support2223.pdf",
            icon: <PictureAsPdfIcon sx={{ color: "red" }} />
        },
        {
            name: "2023-2024校本課後學習及支援計劃-活動計劃表",
            src: "http://www.pkps.edu.hk/pdf/2023-2024/support2324.pdf",
            icon: <PictureAsPdfIcon sx={{ color: "red" }} />
        },
        {
            name: "2022-2023 校本課後學習及支援計劃_活動報告表",
            src: "http://www.pkps.edu.hk/pdf/2023-2024/supReport2223.pdf",
            icon: <PictureAsPdfIcon sx={{ color: "red" }} />
        },
    ];

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2} sx={{ backgroundColor: DefaultSetting.mainBackgroundColor }} >
            <Grid item xs={11} sm={10} >

                <PageTitle title='校本課後學習及支援計劃' size='h3' sx={{ m: 5 }} />

                <MaterialList data={notice} />


            </Grid>
        </Grid>
    )
}
