import { MenuButtonData } from '../../system/Classes/MenuButtonData';

export const peMenuData: Array<MenuButtonData> = [
    {display:"課程目標", path:"/courseTarget/PeObjectives"},
    {display:"校本課程特色", path:"/courseTarget/PeBase"},
    {display:"年度重點項目", path:"/courseTarget/PeConcern"},
    {display:"學生成果展示", path:"/courseTarget/PeDisplay"},
    {display:"教師專業發展", path:"/courseTarget/PeDevelopment"},
];






