import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '../../layoutPages/components/PageTitle';
import { HaveImage, Paragraph } from '../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../layoutPages/defaultSetting';
import { MenuButtonData } from '../../system/Classes/MenuButtonData';
import { setSubjectMenuTabIdx } from '../../system/Slicer/CommonSlicer';
import { selectComponentStore } from '../../system/PageStore';



interface CourseTabProps {
    data?: Array<MenuButtonData>;
    nowIndex?: number; //Elroy, fix this hard code!! //Good job Elroy!
}

export function CourseTab(props: CourseTabProps) {
    const isPc = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const componentStates = useSelector(selectComponentStore);
    const dispatch = useDispatch();

    return (
        <Tabs
            value={props.nowIndex ?? componentStates.CommonComponentReducer.subjectMenuTabIndex}
            //onChange={() => { }}
            variant={isPc ? "fullWidth" : "scrollable"}
            scrollButtons="auto"
            sx={{ bgcolor: TabDefaultSetting.bgColor, borderRadius: "5px", mb: 1 }}
        >
            {props.data && props.data.map((item, index) => (
                <Tab
                    key={index}
                    sx={{ p: 1, fontSize: TabDefaultSetting.tabTextSize }}
                    label={item.display}
                    onClick={() => { dispatch(setSubjectMenuTabIdx(index)); navigate(item.path); }}
                />
            ))}
        </Tabs>
    );
}

