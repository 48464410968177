import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import StarsIcon from '@mui/icons-material/Stars';
import { mathMenuData } from '../../../data/SubjectMenu/mathMenuData';

export function PageMathsBase() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = [
        "透過生活情境營造數學學習需要。",
        "設計環環相扣的學習活動，引導學生進行探索及建構概念。",
        "透過不同的學習任務，讓學生應用所學。",
        "利用生生互動，培養學生的數學說理能力。",
        "透過多元化的表達方式，幫助學生整理及重組學習重點。",
        "學習任務具評估功能，以評估促進學習。",

    ];

    let dottedListData2 = [
        "合作學習:著重小組合作活動﹑積極互賴﹑人人盡責﹑讓不同的能力的學生都有機會參與及接受適當的挑戰﹑互學共享學習成果﹑照顧學生多樣性。",
        "提升速算能力及綜合運用的能力(數感﹑圖形空間感)，重視估算與驗算，猜想與驗證。",
        "豐富學習經歷: 透過低小數學遊蹤活動及高小RUMMIKUB魔力棋的比賽，提升對生活數學的洞察力﹑排列組合及創新規劃的能力。",

    ];

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <PageTitle title='數學科 | 校本課程特色' size='h3' subtitle={{ title: 'SCHOOL-BASED CURRICULUM', size: 'h5' }} />
            <Grid item xs={11} sm={10}>
                <CourseTab data={mathMenuData}/>
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/maths/base/top.jpg" noImgPopup={true} />

            </Grid>

            <Grid item xs={11} sm={10} sx={{ p: 2 }}>
                <Paragraph sx={{ lineHeight: 2.5 }}
                    data={[
                        "數學與生活息息相關，懂得數學能夠幫助我們應付這個知識急劇增長的資訊年代的需要。數學能幫助學生擴闊視野、增進知識、以及奠定學習其他學科的基礎。數學是一種富創意的思維活動，透過數學活動，可發展學生的想像力、積極性及靈活性。此外，透過學習數學能發展學生欣賞自然美感、邏輯思考和作出正確判斷能力。我們期望學生在學校獲得的數學經驗，能令他們變成一個具有精確數學感的公民，並能藉此對社會的繁榮作出貢獻。素養是學生知識、技能、態度、價值觀等多方面的綜合表現；素養教育有利裝備學生面對及適應多變而不可測的未來。"
                    ]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/base/base1.jpg" }
                        ],
                        imageHeight: "600px",
                        position: "right",
                    }}
                />

                <Typography variant="h4" align={"left"} sx={{ py: 2, color: "#F02602" }} >校本課程特色:</Typography>

                <Paragraph title="倡導校本數學素養" titleSize='h5'
                    data={[
                        "我們認為一個具數學素養的人，不單只要具備數學學科知識、基本能力，更重要的是能將數學知識及能力融會貫通，綜合應用於生活，解決生活問題。因此，我們把數學素養的內涵整合為「三面九項」的素養表現(如下圖所示)，既概括了學生在知識、技能及態度上的九項表現，更強調它們與生活情境有緊密連結與互動關係。"
                    ]}
                    
                />

            </Grid>

            <Grid item xs={8} sm={6} sx={{ p: 2 }}><MainCard src="http://www.pkps.edu.hk/img/teachplan/maths/base/base_chart.jpg"></MainCard></Grid>


            <Grid item xs={11} sm={10} sx={{ p: 2 }}>
             <Typography variant="body1" align={"left"} sx={{ mb:2}} >教師在教學上如何導引學生學習素養、發展素養？教師又如何有效評估學生不同程度的素養表現？為此，我們進一步提出「六項素養導向教學原則」，讓教師參照六個教學原則來設計具數學素養的課堂，從而培養學生將三面九項的數學素養展現出來。</Typography>
            

            <Paragraph title="「六項素養導向教學原則」如下:" titleSize='h5'
                    data={[
                        <DottedList data={dottedListData} variant="number" />
                    ]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/base/base2.jpg" }
                        ],
                        imageHeight: "600px",
                        position: "down",
                    }}
                />

                <Typography sx={{ mt:3 }} ><DottedList data={dottedListData2} variant={<StarsIcon fontSize="small"/>} /></Typography>
                

                <Typography variant="h4" align={"left"} sx={{ py: 2, color: "#F02602" }} >我們的數學課:</Typography>

                <Paragraph sx={{ }}
                    data={[
                        "我們更致力培養學生具備「素養」特質，創造生活化的學習情境，提升學習動機(Motivational)，引發高思維(Thought Provoking)及動手做數學(Hands On)，讓他們能把數學的知識﹑技能﹑態度﹑價值觀等融會貫通，綜合應用，解決日常遇到的問題。"
                    ]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/base/base3.jpg" }
                        ],
                        // imageHeight: "600px",
                        position: "down",
                    }}
                />
            </Grid>

        </Grid>
    );
}