import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { MainCard, MainCardProps } from '../../../layoutPages/components/MainCard';
import { ContentSlider } from '../../../layoutPages/containers/ContentSlider';
import { selectComponentStore } from "../../../system/PageStore";
import { useDispatch, useSelector } from 'react-redux';
import { engMenuData } from '../../../data/SubjectMenu/engMenuData';
import { setSubjectEngP3DisplayGalleryIdx, setSubjectEngP4DisplayGalleryIdx, setSubjectEngP5DisplayGalleryIdx, setSubjectEngP6DisplayGalleryIdx } from '../../../system/Slicer/EngSubjectEngDisplayGallerySlicer';
import { CardGallery } from '../../../layoutPages/containers/CardGallery';
import { DottedControl } from '../../../layoutPages/components/Slider/DottedControl';
import * as Lodash from 'lodash';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { ShortCut } from '../../../system/ShortCut';

export function PageEngDisplay() {
    const isPc = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch();
    const componentStates = useSelector(selectComponentStore);

    let contentDataP3: Array<MainCardProps> = [];
    for (let i = 1; i <= 22; i++) {
        contentDataP3.push({ src: "http://www.pkps.edu.hk/09-10%20teachplan/final/images/EN/04/p3/" + i + ".jpg" });
    }
    let contentDataP4: Array<MainCardProps> = [];
    for (let i = 1; i <= 37; i++) {
        contentDataP4.push({ src: "http://www.pkps.edu.hk/09-10%20teachplan/final/images/EN/04/p4/" + i + ".jpg" });
    }
    contentDataP4.push({ src: "http://www.pkps.edu.hk/img/website/other/Solid_white.svg" });//the last page
    let contentDataP5: Array<MainCardProps> = [];
    for (let i = 1; i <= 23; i++) {
        contentDataP5.push({ src: "http://www.pkps.edu.hk/09-10%20teachplan/final/images/EN/04/p5/" + i + ".jpg" });
    }
    contentDataP5.push({ src: "http://www.pkps.edu.hk/img/website/other/Solid_white.svg" });//the last page
    let contentDataP6: Array<MainCardProps> = [];
    for (let i = 1; i <= 35; i++) {
        contentDataP6.push({ src: "http://www.pkps.edu.hk/09-10%20teachplan/final/images/EN/04/p6/" + i + ".jpg" });
    }
    contentDataP6.push({ src: "http://www.pkps.edu.hk/img/website/other/Solid_white.svg" });//the last page

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <PageTitle title='Student Displays of Work' size='h3' />
            <Grid item xs={11} sm={10}>
                <CourseTab data={engMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/eng/display/top.jpg" noImgPopup={true} />
            </Grid>

            <EngWorkDisplay
                key="p3"
                title="P.3 Student Work"
                data={contentDataP3}
                index={componentStates.EngSubjectEngDisplayGalleryReducer.subjectEngP3DisplayGalleryIdx}
                setIndex={setSubjectEngP3DisplayGalleryIdx}
            />

            <EngWorkDisplay
                key="p4"
                title="P.4 Student Work"
                data={contentDataP4}
                index={componentStates.EngSubjectEngDisplayGalleryReducer.subjectEngP4DisplayGalleryIdx}
                setIndex={setSubjectEngP4DisplayGalleryIdx}
            />

            <EngWorkDisplay
                key="p5"
                title="P.5 Student Work"
                data={contentDataP5}
                index={componentStates.EngSubjectEngDisplayGalleryReducer.subjectEngP5DisplayGalleryIdx}
                setIndex={setSubjectEngP5DisplayGalleryIdx}
            />

            <EngWorkDisplay
                key="p6"
                title="P.6 Student Work"
                data={contentDataP6}
                index={componentStates.EngSubjectEngDisplayGalleryReducer.subjectEngP6DisplayGalleryIdx}
                setIndex={setSubjectEngP6DisplayGalleryIdx}
            />
        </Grid>
    )
}

interface engWorkDisplayProps {
    key: string;
    title: string;
    data: Array<MainCardProps>;
    index: number;
    setIndex: ActionCreatorWithPayload<number, string>;
}
export function EngWorkDisplay(props: engWorkDisplayProps) {
    const isPc = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch();
    const componentStates = useSelector(selectComponentStore);

    return (<Grid item xs={11} sm={8} sx={{ p: 2 }}>
        <Divider sx={{ p: 2 }}>
            <Typography variant="h6" align='center' >
                {props.title}
            </Typography>
        </Divider>
        <CardGallery
            key={props.key}
            index={props.index}
            setIndex={props.setIndex}
            autoPlaySetting={{
                interval: 1500,
                onChange: () => {
                    dispatch(props.setIndex(ShortCut.nextIndex(props.index + 1, Lodash.chunk(props.data, 2).length - 1)));
                }
            }}
            cards={props.data.map((item, idx) => (
                <MainCard
                    key={props.key + "" + idx}
                    src={item.src}
                    onClick={item.onClick}
                    sx={{ m: 0.5 }}
                    noImgPopup
                />
            ))} cardPerRow={2}
        />

        {/*
        <DottedControl
            data={Lodash.chunk(props.data, 2)}
            nowIndex={props.index}
            onclick={(index: number) => dispatch(props.setIndex(index))}
        />
        */}
    </Grid>);
}
