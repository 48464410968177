import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import React from 'react';
import { itMenuData } from '../../../data/SubjectMenu/itMenuData';

export function PageItObjectives() {

    const isPc = useMediaQuery('(min-width:600px)');

    let dottedListData = ["讓學生多運用資訊科技搜集和處理資訊，提升專題研習能力。", "持續培養學生正確的資訊科技素養。"]


    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='資訊科技 | 課程目標' size='h3' subtitle={{ title: 'CURRICULUM OBJECTIVES', size: 'h5' }} />
                <CourseTab data={itMenuData} />
                <MainCard src="http://www.pkps.edu.hk/img/teachplan/it/obj_top.jpg" noImgPopup={true} />

                <Paragraph
                    data={[ <DottedList data={dottedListData} /> ]}
                />

            </Grid>
        </Grid>
    )
}
