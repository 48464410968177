import { MaterialList, MaterialWithLink } from '../layoutPages/components/MaterialList';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Box } from '@mui/material';

const data: Array<MaterialWithLink> = [{
    name: "Test PDF1",
    src: "http://www.pkps.edu.hk/newhtml/login/nl/images/pdf/a.pdf",
    icon: <PictureAsPdfIcon sx={{ color: "red" }} />,
},
{
    name: "Test PDF2",
    src: "http://www.pkps.edu.hk/newhtml/login/nl/images/pdf/a.pdf",
    icon: <AudioFileIcon sx={{ color: "orange" }} />,
},
{
    name: "Test PDF3",
    src: "http://www.pkps.edu.hk/newhtml/login/nl/images/pdf/a.pdf",
    icon: <VideoFileIcon sx={{ color: "blue" }} />,
},
{
    name: "Test PDF4",
    src: "http://www.pkps.edu.hk/newhtml/login/nl/images/pdf/a.pdf",
    icon: <TextSnippetIcon sx={{ color: "black" }} />,
},
];

export function MaterialListTemplate() {
    return (
        <Box>
            <MaterialList data={data} />
        </Box>
    );
}