import { MenuButtonData } from '../../system/Classes/MenuButtonData';

export const engMenuData: Array<MenuButtonData> = [
    {display:"Curriculum Objectives", path:"/courseTarget/EngObjectives"},
    {display:"School-Based Curriculum", path:"/courseTarget/EngBase"},
    {display:"Current Main Concern", path:"/courseTarget/EngConcern"},
    {display:"Student Displays of Work", path:"/courseTarget/EngDisplay"},
    {display:"Professional Development of Teachers", path:"/courseTarget/EngDevelop"},
    {display:"Online Learning Resources", path:"/courseTarget/EngResources"}
];

