import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { useDispatch, useSelector } from 'react-redux';
import { homePageSliderMove } from '../../../system/Slicer/HomePageSlicer';
import { selectComponentStore } from '../../../system/PageStore';
import { ContentSlider } from '../../../layoutPages/containers/ContentSlider';
import { DottedControl } from '../../../layoutPages/components/Slider/DottedControl';
import { ArrowControl } from '../../../layoutPages/components/Slider/ArrowControl';
import { chiMenuData } from '../../../data/SubjectMenu/chiMenuData';
//import { DefaultSetting } from '../../../layoutPages/defaultSetting';

export function PageChiDevelopment() {

    const isPc = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch();
    const componentStates = useSelector(selectComponentStore);


    let contentData = [
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideA1.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideA2.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideA3.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideA4.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideA5.jpg' },
    ];

    const setSlider = (index: number, lastIndex?: number) => {
        let nextIndex = index;
        if (index == contentData.length) nextIndex = 0;
        if (index <= -1) nextIndex = contentData.length - 1;
        dispatch(homePageSliderMove(nextIndex))
        return {}
    }

    let contentData2 = [
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideB1.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideB2.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideB3.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideB4.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideB5.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideB6.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideB7.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideB8.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideB9.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideB10.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideB11.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideB12.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideB13.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideB14.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideB15.jpg' },
        { url: 'http://www.pkps.edu.hk/img/teachplan/chi/development/slideB16.jpg' },

    ];

    const setSlider2 = (index: number, lastIndex?: number) => {
        let nextIndex = index;
        if (index == contentData2.length) nextIndex = 0;
        if (index <= -1) nextIndex = contentData2.length - 1;
        dispatch(homePageSliderMove(nextIndex))
        return {}
    }



    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <Grid item xs={11} sm={10} >
                <PageTitle title='中國語文 | 教師專業發展' size='h3' subtitle={{ title: 'PROFESSIONAL DEVELOPMENT OF TEACHERS', size: 'h5' }} />
                <CourseTab data={chiMenuData} />

                <MainCard src="http://www.pkps.edu.hk/img/teachplan/chi/development/top.jpg" imgHeight='550px' noImgPopup={true} />

                <Typography variant="h4" align={"left"} sx={{ py: 2 }} >我們以學習社群為理念，經營、管理教師隊伍，促進教師們的專業發展。</Typography>

                <Paragraph title={<Typography variant="h5" align={"left"} sx={{ pb: 1 }} >（一）理論學習建共識</Typography>}
                    data={[
                        "中文科教師通過暑期專業閱讀、參加專家講座、工作坊等途徑，從理論層面汲取養份，並通過同事們互相分享學習心得，探討如何結合校本課程的已有經驗，達成發展方向的共識。	"
                    ]
                    }
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/chi/development/develop1.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/chi/development/develop2.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/chi/development/develop3.jpg" }],
                        position: "down",
                        imageHeight: "300px",
                    }}
                />

                <Paragraph title={<Typography variant="h5" align={"left"} sx={{ mt: 3, pb: 1 }} >（二）觀摩學習提能力</Typography>}
                    data={[
                        "我們是一支靠不斷學習成長起來的教師隊伍，一直以來，我們一方面組織校內教師共同備課，另一方面，也誠邀香港本地或內地的翹楚到校內互相交流、互相觀課。我們積極拓展與外界的交流和聯繫，如參加「內地教師交流計劃」，與內地教師交流學習；協助教育局舉辦「教師培訓工作坊」，連續三年與深圳、新疆等友好學校進行「同題異教」；參加優質教育基金「發展學生語文自學能力計劃」，並於2015-2019年獲教育局邀請，每年支援三間學校，分享我們自主學習的教研成果，交流經驗。我們亦鼓勵教師積極走出校園，去各地學習，提升教學能力。"
                    ]}

                />

                <ContentSlider height="450px" sx={{ bottom: "-30px" }} data={contentData} event={setSlider} />


                {/* <DottedControl
                        
                        indexData={contentData.map((_, index) => { return index })}
                        nowIndex={componentStates.HomePageComponentReducer.homePageSliderIndex}
                        onclick={setSlider}
                        
                    /> */}

                <Paragraph title={<Typography variant="h5" align={"left"} sx={{ pb: 1 }} >（三）會議分享共學習</Typography>}
                    data={[
                        "教師間交流教學方法、分享教學成果是推動教師專業發展的平台，是落實共同理念的途徑，也是管理課程延伸落實的方法。因此，在科組會議中，我們既安排各級級長帶領教師分享教研成果，又請教師分享考績課成果，讓教師間互相評鑒和學習，推動個人與科組的全面發展。"
                    ]
                    }
                    haveImage={{
                        images: [{ src: "http://www.pkps.edu.hk/img/teachplan/chi/development/develop4.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/chi/development/develop5.jpg" },
                        { src: "http://www.pkps.edu.hk/img/teachplan/chi/development/develop6.jpg" }],
                        position: "down",
                        imageHeight: "300px",
                    }}
                />

                <Paragraph title={<Typography variant="h5" align={"left"} sx={{ mt: 3, pb: 1 }} >（四）展示課堂促發展</Typography>}
                    data={[
                        "教學展示是我們的傳統，同儕觀課是我們的文化。我們通過各級研究課、考績課，推動教研。我們還有嘉賓觀課、開放日展示課、家長觀課日等，一方面展示了教研成果，一方面為教師提供了研磨課堂的機會。在平日教學與公開課堂相結合下，大大提升了教師教學能力。"
                    ]}

                />

                <ContentSlider height="450px" sx={{ bottom: "-30px" }} data={contentData} event={setSlider2} />


            </Grid>

        </Grid>
    )
}
