import { Box, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { PageTitle } from '../../../layoutPages/components/PageTitle';
import { CourseTab } from '../menuTab';
import { HaveImage, Paragraph } from '../../../layoutPages/containers/Paragraphs/Paragraph';
import { TabDefaultSetting } from '../../../layoutPages/defaultSetting';
import { DottedList } from '../../../layoutPages/containers/DottedList';
import { MainCard } from '../../../layoutPages/components/MainCard';
import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import StarsIcon from '@mui/icons-material/Stars';
import WebIcon from '@mui/icons-material/Web';
import { mathMenuData } from '../../../data/SubjectMenu/mathMenuData';
import { MainCards } from '../../../layoutPages/containers/MainCards';
import { MaterialList, MaterialWithLink } from '../../../layoutPages/components/MaterialList';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Height } from '@mui/icons-material';

export function PageMathsDisplay() {

    const isPc = useMediaQuery('(min-width:600px)');

    const data: Array<MaterialWithLink> = [
        { name: "22-23年度校外數學比賽得獎名單", src: "http://www.pkps.edu.hk/09-10%20teachplan/final/images/MS/pdf/2022-2023b.pdf", icon: <PictureAsPdfIcon sx={{ color: "red" }} /> },
        { name: "21-22年度校外數學比賽得獎名單", src: "http://www.pkps.edu.hk/09-10%20teachplan/final/images/MS/pdf/2021-2022b.pdf", icon: <PictureAsPdfIcon sx={{ color: "red" }} /> },
        { name: "20-21年度校外數學比賽得獎名單", src: "http://www.pkps.edu.hk/09-10%20teachplan/final/images/MS/pdf/2020-2021b.pdf", icon: <PictureAsPdfIcon sx={{ color: "red" }} /> },
        { name: "19-20年度校外數學比賽得獎名單", src: "http://www.pkps.edu.hk/09-10%20teachplan/final/images/MS/pdf/2019-2020b.pdf", icon: <PictureAsPdfIcon sx={{ color: "red" }} /> },
        
    ]

    let dottedListData = [
        "生活課業，讓學生經歷不一樣的數學學習歷程，藉此提升學生學習數學的興趣與參與性，培養學生的協作、創作與溝通等能力，擴闊思考。",
        "為引發學生對課堂以外知識的好奇心，熱愛數學並享受自學的樂趣，四至六年級設置多元課業―課後延伸。",
        "數學科學習歷程檔案: 是教學及學習的結合，以情境化、脈絡化的學習模式，為學生提供不同的學習經歷，推動學生積極地進行自主學習和自我反思。",
        "(四)	數學悅讀計畫 : 閱讀是重要的自學策略，亦是作為終身學習的一項必需具備的能力，而透過閱讀，學生能從中認識世界，學習課本以外的知識。校本的數學「悅」讀計劃(小二至小五)，提升學生的閱讀能力的同時，亦培養他們的數學素養。",
    ];

    let dottedListData2 = [
        "世界數學測試2018「數學科」及「解難分析科」，小四至小六同學參與其中，均獲「優異獎」。",
        "2016-2017 年度全港小學數學比賽(東區)「數學解難」本校獲冠軍殊榮。2017-2018東區數學比賽「數學智多星」同學獲殿軍。",
        "多年來積極參與「華夏杯」及「香港奧林匹克數學比賽」，同學們屢獲獎項，共享成果。",
    ];

    return (
        <Grid container direction="row" justifyContent="center" alignItems="top" spacing={2}>
            <PageTitle title='數學科 | 學生成果展示' size='h3' subtitle={{ title: 'STUDENT DISPLAYS OF WORK', size: 'h5' }} />
            <Grid item xs={11} sm={10}>
                <CourseTab data={mathMenuData} />
                <MainCard src="https://lh3.googleusercontent.com/pw/AL9nZEVYyUur0A26DVgmKJ7M-U-rLJ5NojzGOQHU3KJtIQbUcW4ZJb-ihr8QQX9eUnb2YztLnGDasTj926dSs1JYsVaL4mpFMgN3_7X7kZpNBnXVHtmHC5UGs0vKJC-bGvpRVxMpBA-F01RZ0LL4vxW09hSn=w1920-h792-no?authuser=0" noImgPopup={true} />
            </Grid>

            <Grid item xs={11} sm={10} sx={{ p: 2 }}>
                <MainCards data={[
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/maths/display/icon01.jpg",
                        onClick: () => window.open("https://www.youtube.com/watch?v=PRffztOrwZU", '_blank', 'noopener,noreferrer'),
                        sx: {}
                    },
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/maths/display/icon02.jpg",
                        onClick: () => window.open("https://www.youtube.com/watch?v=TLADAEeRj1U", '_blank', 'noopener,noreferrer'),
                        sx: {}
                    },
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/maths/display/icon03.jpg",
                        onClick: () => window.open("https://www.youtube.com/watch?v=aliZIvoTojI", '_blank', 'noopener,noreferrer'),
                        sx: {}
                    },
                    {
                        src: "http://www.pkps.edu.hk/img/teachplan/maths/display/icon04.jpg",
                        onClick: () => window.open("https://www.youtube.com/watch?v=k4m5ovFYeSg", '_blank', 'noopener,noreferrer'),
                        sx: { }
                    }
                    
                ]} 
                height="100px"
                />
                    
                <Paragraph title="校本特色課業設計分享:"
                    data={[<MaterialList data={[{
                        name: "學生成果展示",
                        src: "http://www.pkps.edu.hk/20years/subject/maths.html",
                        icon: <WebIcon sx={{ color: "blue" }} />,
                    },]} />, <DottedList data={dottedListData} variant="number" />,
                    ]}
                />

                <Paragraph title="追求卓越，積極參與校外數學比賽"
                    data={[
                        <MaterialList data={data} />]}
                />

                <Paragraph
                    data={[<DottedList data={["2023年 本校同學積極參與華夏杯全國數學奧林匹克邀請賽(華南賽區)，以下為獲獎同學們，成績優異。)"]}  />,]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/img/teachplan/maths/display/1.jpg"}
                        ],
                        position: "left",
                        imageHeight:"550px"

                    }}
                />

                <Paragraph
                    data={[<DottedList data={["2022年榮獲第九屆聯區RUMMIKUB小學邀請賽 (西貢、觀塘、黃大仙 及港島東區)"]}  />,]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/09-10%20teachplan/final/images/MS/MS04-14.jpg" }
                        ],
                        position: "right",

                    }}
                />

                <Paragraph
                    data={[<DottedList data={["2021-2022年度全港小學數學比賽(東區)優異獎"]}  />,]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/09-10%20teachplan/final/images/MS/MS04-15.jpg" }
                        ],
                        position: "left",

                    }}
                />

                <Paragraph
                    data={[<DottedList data={["2019年榮獲「第十五屆香港校際盃珠心算比賽」高級組團體賽亞軍，低級組團體賽冠軍殊榮。"]} />,]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/09-10%20teachplan/final/images/MS/MS04-04.jpg" },
                            { src: "http://www.pkps.edu.hk/09-10%20teachplan/final/images/MS/MS04-05.jpg" }
                        ],
                        position: "down",

                    }}
                />

                <Paragraph
                    data={[<DottedList data={["2019年榮獲「第十五屆香港校際盃珠心算比賽」高級組團體賽亞軍，低級組團體賽冠軍殊榮。"]} />,]}
                    haveImage={{
                        images: [
                            { src: "http://www.pkps.edu.hk/09-10%20teachplan/final/images/MS/MS04-08.jpg" },
                        ],
                        position: "right",

                    }}
                />

            <DottedList data={["世界數學測試2018「數學科」及「解難分析科」，小四至小六同學參與其中，均獲「優異獎」。",
        "2016-2017 年度全港小學數學比賽(東區)「數學解難」本校獲冠軍殊榮。2017-2018東區數學比賽「數學智多星」同學獲殿軍。",
        "多年來積極參與「華夏杯」及「香港奧林匹克數學比賽」，同學們屢獲獎項，共享成果。"]} variant={<StarsIcon fontSize='small' />} />

                <MainCard src="http://www.pkps.edu.hk/09-10%20teachplan/final/images/MS/MS04-16.jpg" noImgPopup={true} />

            </Grid>




        </Grid>
    )
}
